import React, { useEffect, useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import './payfordamage.css'
import ApiService from '../../Api/ApiController';
export default function PayForDamage() {
  const [mybookings,setmybookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const navigate = useNavigate();
  const GetBookings = async (page, option) => {
    try {
      const response = await ApiService.GetBookings({
        status: "ongoing",
        page,
      });
      if (response.data.status === "success") {
        setmybookings(response.data.data);
      } else if (response.data.status === "error") {
        console.log(response)
        setmybookings([]);

      }
    } catch (error) {
      console.error("An error occurred while fetching bookings:", error);
    }
  };

  useEffect(() => {
    GetBookings(currentPage);
  }, [currentPage]);
  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handledetails = (orderId) =>{
    navigate(`/damagedetails/${orderId}`)
  }
  return (
    <div>
      <section>
        <div className='payfordamage-list'>
        <div className="booking-list">
        {mybookings.map((booking, index) => (
            <div key={index} className="ongoing-container" onClick={()=>handledetails(booking.order_id)}>
              <div className="ongoing-description">
                <div className="ongoing-description1">Description:</div>
                <div
                  className="ongoing-description2"
                  dangerouslySetInnerHTML={{ __html: booking.description }}
                ></div>
              </div>
              <div className="ongoing-order">
                <div className="ongoing-order1">Order ID:</div>
                <div className="ongoing-order2">{booking.order_id}</div>
              </div>
              <div className="ongoing-arriving">
                <div className="ongoing-arriving1">Arriving</div>
                <div className="ongoing-arriving2">{booking.arriving_date}</div>
              </div>
              <div className="ongoing-total">
                <div className="ongoing-total1">Total Price</div>
                <div className="ongoing-total2">
                  ₹{booking.final_item_price}
                </div>
              </div>
            </div>
          ))}
      </div>
        </div>
        <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Previous
        </button>
        <span>
          {currentPage} of {totalPages}
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Next
        </button>
      </div>
      </section>
    </div>
  )
}
