import React, { useEffect, useState } from "react";
import flower from "../homepage/flower1.jpeg";
import "./extendorder.css";
import ApiService from "../../Api/ApiController";
import { useNavigate, useParams } from "react-router-dom";
import brobo from "../../header/brobo.png";
import useRazorpay from "react-razorpay";
export default function ExtendOrder() {
  const [orderdetails, setOrderdetails] = useState([]);
  const [showextendOrderDetails, setShowextendOrderDetails] = useState(false);
  const [orderDate, setOrderDate] = useState(null);
  const [extendedDays, setExtendedDays] = useState(0);
  const [ordersetting, setOrderSetting] = useState([]);
  const [razorpaykey, setrazorpaykey] = useState([]);
  const [Razorpay] = useRazorpay();
  const orderId = useParams();
  const navigate =  useNavigate()
  useEffect(() => {
    ApiService.GetBookingdetail({ booking_id: orderId })
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data.data)
          setOrderdetails(response.data.data);
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    ApiService.paymentgatewaykey({
      payment_gateway_name: "razor_pay",
    })
      .then((response) => {
        if (response.data.status === "success") {
          
          setrazorpaykey(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const totalOrderAmount =
    orderdetails &&
    orderdetails.item_details &&
    orderdetails.item_details.reduce((acc, item) => {
      // Check if the category ID is 1
      if (item.category_id === 1) {
        return acc + item.item_price;
      } else {
        // For items with category ID other than 1, return the accumulator unchanged
        return acc;
      }
    }, 0); // Initialize the accumulator to 0

  const extendedprice = extendedDays * totalOrderAmount;

  const OrderSetting = async () => {
    await ApiService.getOrderSetting()
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data.data);
          setOrderSetting(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    OrderSetting();
  }, []);
  const gstPercent = parseFloat(ordersetting.gst_percent || 0);
  const gstAmount = (extendedprice * gstPercent) / 100;
  const GrandTotal = extendedprice + gstAmount;
  const handleDateChange = (event) => {
    const selectedDate = new Date(event.target.value);
    const endDate = new Date(orderdetails.end_date);
     
    setOrderDate(selectedDate); // Update orderDate with the selected date
    const differenceInTime = selectedDate.getTime() - endDate.getTime();
    const differenceInDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
    setExtendedDays(differenceInDays);
    if (selectedDate > endDate) {
      setShowextendOrderDetails(true);
    } else {
      setShowextendOrderDetails(false);
    }
  };
  // =================================================================================
  const PayNow = () => {
    const options = {
      key: razorpaykey.key,
      amount: GrandTotal * 100,
      currency: "INR",
      name: "Brobo",
      description: "Brobo Transaction",
      image: { brobo },

      handler: async function (response) {
      
        await ExtendOrderPlace(response.razorpay_payment_id);
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#3399cc",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      console.log(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();
  };
  // =================================================================================
 
  const ExtendOrderPlace = async (paymentId) => {
    const formattedEndDate = orderDate.toLocaleDateString("en-CA");
   
    await ApiService.extendOrder({
      end_date: formattedEndDate,
      booking_id: orderId,
      extended_order_transaction_id: paymentId,
      amount: GrandTotal,
      gst_amount:gstAmount
    })
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data.data);
          navigate('/mybookings');
        } else {
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <section>
        {orderdetails &&
          orderdetails.item_details &&
          orderdetails.item_details.map((orderdetail, index) => {
            // Check if the category ID is 1
            if (orderdetail.category_id === 1) {
              return (
                <div className="extendorder" key={index}>
                  <div className="extendorder-1">
                    <img src={orderdetail.item_image} alt="" />
                    <div className="extendorder-2">
                      <h5>{orderdetail.item_name}</h5>
                      <p>Rs.{orderdetail.item_price}</p>
                    </div>
                  </div>
                  <div className="extendorder-3">
                    <h6>Quantity</h6>
                    <h6>{orderdetail.quantity}</h6>
                  </div>
                </div>
              );
            } else {
              // Return null for items with category ID other than 1
              return null;
            }
          })}
        <section>
          <div className="extend-date">
            <label htmlFor="extenddate">
              End Date
              <input
                type="date"
                value={
                  orderDate ? orderDate.toISOString().split("T")[0] : orderdetails.end_date
              }
              
                min={orderdetails.end_date}
                onChange={handleDateChange}
              />
        

            </label>
          </div>
        </section>

        {showextendOrderDetails && (
          <section className="extend-order-details-main">
            <div className="extend-order-details">
              <div className="extend-order-details-1">
                <h4>Extend Order Details</h4>
                <hr />
              </div>
              <div className="extend-order-details-2">
                <p> Extend days</p>
                <p>{extendedDays} days</p>
              </div>
              <div className="extend-order-details-3">
                <p>Items Amount</p>
                <p>{extendedprice}</p>
              </div>
              <div className="extend-order-details-4">
                <p> GST</p>
                <p>{gstAmount}</p>
              </div>
              <hr />
              <div className="extend-order-details-5">
                <p>Total Amount</p>
                <p>₹{GrandTotal}</p>
              </div>
            </div>
          </section>
        )}
        <div className="extend-order-booknow">
          <button onClick={PayNow}>Book now</button>
        </div>
      </section>
    </div>
  );
}
