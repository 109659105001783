import React, { useEffect, useState } from 'react';
import './resetpassword.css'
import { useTimeSlot } from '../../addtocart/TimeSlotContext';
import ApiService from '../../../Api/ApiController';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
// import 'sweetalert2';
export default function ResetPassword() {
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newpassword,setmewpassword] = useState('');
    const [confirmpassword,setconfirmpassword] = useState('');
    const [error,seterror] = useState();
    const {userId,setUserId} = useTimeSlot()
    const [confirmpasswordVisible, setconfirmPasswordVisible] = useState(false);
    const navigate =  useNavigate()
    // localStorage.setItem('userId', userId);
    const togglePasswordVisibility = () => {
      setPasswordVisible(!passwordVisible);
    };
    const toggleconfirmPasswordVisibility = () => {
      setconfirmPasswordVisible(!confirmpasswordVisible);
    };
  
    useEffect(() => {
        const storedUserId = localStorage.getItem('userId');
        if (storedUserId) {
            setUserId(storedUserId);
        }
    }, []);
    useEffect(() => {
        localStorage.setItem('userId', userId);
    }, [userId]);
    const ResetPassword = async (e)=>{
             e.preventDefault()
        if (newpassword !== confirmpassword) {
          Swal.fire({
            icon: 'error',
            title: 'Error!',
            text: 'Both passwords should be the same',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 1500
        });
            return;
        }
        ApiService.ResetPassword({
            customer_id: userId,
            new_password:newpassword,
            confirm_password:confirmpassword,
        }).then((response)=>{
            if(response.data.status === 'success'){
                console.log(response.data)
                Swal.fire({
                  icon: 'success',
                  title: 'Success!',
                  text: response.data.message,
                  toast: true,
                  position: 'top-end',
                  showConfirmButton: false,
                  timer: 1500
              });
               navigate('/login')
            }else{
              Swal.fire({
                icon: 'error',
                title: 'Error!',
                text: response.data.message,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 1500
            });
            }
           
        }).catch((error)=>{
            console.log(error)
            Swal.fire({
              icon: 'error',
              title: 'Error!',
              text: error.response.data.message,
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 1500
          });
        });
    }
   
  return (
    <div>
      <div>
        <section>
          <div className='forget-password-main'>
            <form className="form-forget">
              
              <div className="inputForm-forget">
                <svg height="20" viewBox="-64 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0"></path>
                  <path d="M304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0"></path>
                </svg>
                <input type={passwordVisible ? 'text' : 'password'} value={newpassword} onChange={(e)=> setmewpassword(e.target.value)}  className="input-forget" placeholder="New Password" />
                <svg viewBox="0 0 576 512" height="20" xmlns="http://www.w3.org/2000/svg" onClick={togglePasswordVisibility}>
  {passwordVisible ? (
    <path d="M288 480c-80.8 0-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6c46.8-43.5 111.5-80.6 192.6-80.6s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480zm0-368c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm0 0"></path>
  ) : (
    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
  )}
</svg>

              </div>
              <div className="inputForm-forget">
                <svg height="20" viewBox="-64 0 512 512" width="20" xmlns="http://www.w3.org/2000/svg">
                  <path d="M336 512h-288c-26.453125 0-48-21.523438-48-48v-224c0-26.476562 21.546875-48 48-48h288c26.453125 0 48 21.523438 48 48v224c0 26.476562-21.546875 48-48 48zm-288-288c-8.8125 0-16 7.167969-16 16v224c0 8.832031 7.1875 16 16 16h288c8.8125 0 16-7.167969 16-16v-224c0-8.832031-7.1875-16-16-16zm0 0"></path>
                  <path d="M304 224c-8.832031 0-16-7.167969-16-16v-80c0-52.929688-43.070312-96-96-96s-96 43.070312-96 96v80c0 8.832031-7.167969 16-16 16s-16-7.167969-16-16v-80c0-70.59375 57.40625-128 128-128s128 57.40625 128 128v80c0 8.832031-7.167969 16-16 16zm0 0"></path>
                </svg>
                <input type={confirmpasswordVisible ? 'text' : 'password'} value={confirmpassword } onChange={(e)=>setconfirmpassword(e.target.value)} className="input-forget"  placeholder="Confirm Password" />
                <svg viewBox="0 0 576 512" height="20" xmlns="http://www.w3.org/2000/svg" onClick={toggleconfirmPasswordVisibility}>
  {confirmpasswordVisible ? (
    <path d="M288 480c-80.8 0-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6c46.8-43.5 111.5-80.6 192.6-80.6s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480zm0-368c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm0 0"></path>
  ) : (
    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
  )}
</svg>

              </div>
                {error ? <span style={{color:'red'}}>{error}</span> : ''}
              
              <button className="button-submit-forget" onClick={ResetPassword} >Save</button>
           
            </form>
          </div>
        </section>
      </div>
    </div>
  );
}
