import React, { useEffect, useState } from "react";
import "./deliveryaddress.css";
import ApiService from "../../Api/ApiController";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";

export default function DeliveryAddress() {
  const [housename, setHouseName] = useState("");
  const [floornumber, setFloorNumber] = useState("");
  const [landmark, setLandmark] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [areaname, setAreaName] = useState("");
  const [addresstype, setAddressType] = useState("");

  const addressId =  useParams()
  const [editMode, setEditMode] = useState(null);
  const [editedAddress, setEditedAddress] = useState({});

  console.log("Address ID:", addressId.addressid);
  const handleSaveClick = (e) => {
    e.preventDefault()

    ApiService.manageAddress({
      
      house_name: housename,
      floor_number: floornumber,
      landmark: landmark,
      zip_code: zipcode,
      area_name: areaname,
      address_type: addresstype,
      address_id: addressId.addressid
    }).then((response) => {
      if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Address Updated",
          text: response.data.message,
          toast:true,
          position: 'top-end',
          timer: 1500,
          showConfirmButton:false
        })
        
      } else {
        Swal.fire({
          icon: 'error',
          title: "Update Failed",
          text: response.data.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
        
        });
  
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleCancelClick = () => {
    setEditMode(null);
    setEditedAddress({});
  };





  const fetchAddresses = () => {
    ApiService.getaddressdetails()
      .then((response) => {
        if(response.data.status === 'success'){
            const addressList = response.data.data
            const addressToEdit = addressList.find((address) => address.address_id === parseInt(addressId.addressid));
        
        if (addressToEdit) {
          // Update state with fetched address data
          setHouseName(addressToEdit.house_name);
          setFloorNumber(addressToEdit.floor_number);
          setLandmark(addressToEdit.landmark);
          setZipCode(addressToEdit.zip_code);
          setAreaName(addressToEdit.area_name);
          setAddressType(addressToEdit.address_type);
        } else {
          console.error(`Address with ID ${addressId.addressid} not found.`);
        }
          
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAddresses();
  }, []);
  const handleEditClick = (address) => {
    setEditMode(address);
    setEditedAddress(address);
  

    setHouseName(address.house_name);
    setFloorNumber(address.floor_number);
    setLandmark(address.landmark);
    setZipCode(address.zip_code);
    setAreaName(address.area_name);
    setAddressType(address.address_type);
  //   const formElement = document.querySelector('.delivery-address-form');
  // formElement.scrollIntoView({ behavior: 'smooth' });
  window.scrollTo({
    top: 0,
    behavior: "auto"
  });
  };
  
  return (
    <div>
      <div className="delivery-address-container">
        <h1>Manage Address</h1>
        <form className="delivery-address-form" >
          <div className="delivery-address-dropdown">
            <select
              name="address-type"
              id="address-type"
              value={addresstype}
              onChange={(e) => setAddressType(e.target.value)}
            >
              <option value="" disabled>Select Address Type</option>
              <option value="Home">Home</option>
              <option value="Work">Work</option>
              <option value="Other">Others</option>
            </select>
          </div>

          <div className="delivery-address-field">
            <label htmlFor="houseApartment">House/Apartment</label>
            <input
              value={housename}
              onChange={(e) => setHouseName(e.target.value)}
              type="text"
              id="houseApartment"
            />
          </div>
          <div className="delivery-address-field">
            <label htmlFor="floor">Floor</label>
            <input
              value={floornumber}
              onChange={(e) => setFloorNumber(e.target.value)}
              type="number"
              id="floor"
            />
          </div>
          <div className="delivery-address-field">
            <label htmlFor="landmark">Landmark</label>
            <input
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              type="text"
              id="landmark"
            />
          </div>
          <div className="delivery-address-field">
            <label htmlFor="area">Area</label>
            <input
              value={areaname}
              onChange={(e) => setAreaName(e.target.value)}
              type="text"
              id="area"
            />
          </div>

          <div className="delivery-address-field">
            <label htmlFor="pinCode">Pin Code</label>
            <input
              value={zipcode}
              onChange={(e) => setZipCode(e.target.value)}
              type="number"
              id="pinCode"
            />
          </div>
          <div className="delivery-address-button">
            
           <button onClick={(e)=>handleSaveClick(e)} type="submit">Update</button> 
            
          </div>
        </form>
      </div>

    </div>
  );
}
