import React, { useEffect, useState } from 'react';
import './ReferralCode.css'; // You can create a separate CSS file for styling
import ApiService from '../../Api/ApiController';
import Swal from 'sweetalert2';


export default function ReferralCode() {
  const [referralCode, setReferralCode] = useState(''); // State to store the referral code
  const token = sessionStorage.getItem('authtoken')


  useEffect(() => {
    if (!token) {

      Swal.fire({
        icon: 'warning',
        text: 'Please login First',
        html: `You need to Login`,
        showCancelButton: false,
        confirmButtonText: 'Login',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {

          window.location.href = '#/login';
        } else if (result.dismiss === Swal.DismissReason.cancel) {

        }
      });

      // Close the SweetAlert dialog after 5 seconds (5000 milliseconds)
      setTimeout(() => {
        Swal.close();

      }, 5000);

    }
  })
  const Reffer = () => {
    ApiService.getprofiledetails().then((response) => {
      if (response.data.status === 'success') {
        setReferralCode(response.data.data.referral_code)
      }
    }).catch((err) => {
      console.log(err)
    })
  }

  useEffect(() => {
    if (token) {
      Reffer()
    }
  }, [])
  const shareReferralCode = async () => {

    const data = {
      title: 'Hello Brobo',
      text: `Welcome to BROBO! Discover exclusive benefits by joining us.\n\nUse my referral code: ${referralCode ? referralCode : "*****"} \n\n\nDownload the app now: https://play.google.com/store/apps/details?id=com.brobo.user&referralCode=${referralCode ? referralCode : "*****"}`,
    }
    if (navigator.share) {
      try {
        await navigator.share(data).then(() => {
          console.log(data)
        });

      } catch (error) {
        console.error('Error sharing referral code:', error);
        alert('Failed to share referral code.');
      }
    } else {
      // Fallback for browsers that do not support Web Share API
      alert('Your browser does not support sharing.');
    }
  };

  return (
    <div className="referral-code-container">
      <h1>Refer Now</h1>
      <div className="referral-code">
        <p>Your Referral Code:</p>
        <div className="refer-1code">{referralCode}</div>
        {/* <button className='refer-2code' onClick={generateReferralCode}>Generate New Code</button> */}
        <button className='refer-2code' onClick={shareReferralCode}> <i class='bx bx-share-alt'></i>Share Code</button>
      </div>
    </div>
  );
}
