import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import truck from "./truck.png";
import users from "./users.png";
import gift from "./gift.png";
import credit from "./credit-card.png";
import referimage from './refer-image.png'
import "./newhome.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { EffectFade, Autoplay } from "swiper/modules";

import ApiService from "../../Api/ApiController";
import unparallel from "./unparalleledquality.png";
import fastshipping from "./fastshipping.png";
import trustreliability from "./trustandreliability.png";
import broboblack from "./Image.png";
import copybutton from "./Iconcoupon.png";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
export default function NewHome() {
  const [fastmovings, setfastmovings] = useState([]);
  const [showAllProducts, setShowAllProducts] = useState(false);
  const [showAllTeam, setShowAllTeam] = useState(false);
  const [couponcode, setcouponcode] = useState([]);
  const [popular,setpopular] = useState([]);
  const [banners, setbanner] = useState([]);
  const navigate = useNavigate([]);
  const location = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to top on route change
  }, [location.pathname]); // Execute only when location.pathname changes

  const toggleProducts = () => {
    setShowAllProducts(!showAllProducts);

    window.scrollTo({
      top: window.scrollY + window.innerHeight,
      behavior: "smooth"
    });
  };
  const toggleTeam = () => {
    setShowAllTeam(!showAllTeam);
    window.scrollTo({
      top: window.scrollY + window.innerHeight,
      behavior: "smooth"
    });
  };

  useEffect(()=>{
    ApiService.getAllCoupons()
    .then((response) => {
      setcouponcode(response.data.data);
    })
    .catch((error) => {
      console.error("Error fetching coupons:", error);
      // Handle the error
    });
    ApiService.getallBanners()
    .then((response) => {
      setbanner(response.data.data);
    
    })
    .catch((error) => {
      console.error(error);
    });
    ApiService.getfastmovingitems()
    .then((response) => {
      setfastmovings(response.data.data);
    })
    .catch((error) => {
     
    });
        ApiService.getallpopularservices().then((response)=>{
      setpopular(response.data.data);
      
}).catch((error)=>{})
  },[])


  const handleCopyCoupon = (code) => {
    // Copy the coupon code to the clipboard
    navigator.clipboard
      .writeText(code)
      .then(() => {
        alert("Coupon code copied to clipboard: " + code);
      })
      .catch((error) => {
        console.error("Failed to copy coupon code: ", error);
      });
  };

  const handlesubcategoryselection = (categoryId, subcategoryId) => {
   
    navigate(`/productlist/${categoryId}/${subcategoryId}`);
  };
  const handledetails = (productId) =>{
    ("efgwe")
    navigate(`/productdetail/${productId}`)
  }
  return (
    <div>
      <section>
        <section className="newhome-section">
          <div className="newhome-section-sub">
            <div className="new-text-newhome">
              <div className="new-text-newhome-1">
                <h6>Welcome to BROBO</h6>
              </div>
              <div className="new-text-newhome-2">
                <h1>
                Elevate Your Lifestyle with Our Rented Items and Products!
                </h1>
              </div>
              <div className="new-text-newhome-3">
                <p>
                  Transforming Occasions into Unforgettable Memories: Your
                  Premier Destination for Elegant Tent Hire and Exquisite Floral
                  Sales.
                </p>
              </div>
            </div>
            <div className="new-slider-newhome" >
              <Swiper 
                spaceBetween={12}
                centeredSlides={true}
                loop={true}
                autoplay={{
                  delay: 1500,
                  disableOnInteraction: false,
                }}
                effect={"fade"}
                modules={[EffectFade, Autoplay]}
                className="mySwiper"
              >
                 {banners.map((banner, index) =>
                (
                    <SwiperSlide  key={index}>
                      <img onClick={()=>handledetails(banner.product_id)}  src={banner.image} alt="" />
                    </SwiperSlide>
                  ) 
                )}
          
              </Swiper>
            </div>
          </div>
        </section>
      </section>
      <section>
        <div className="newhome-section-1">
          <div id="newhome-section-truck">
            <div className="newhome-section-truck-img">
              <img src={truck} alt="" />
            </div>
            <div className="newhome-section-truck-text">
              <h3>On Time Delivery</h3>
              {/* <p>Order Over Rs10,000</p> */}
            </div>
          </div>
          <div id="newhome-section-truck">
            <div className="newhome-section-truck-img">
              <img src={credit} alt="" />
            </div>
            <div className="newhome-section-truck-text">
              <h3>Quick Payment</h3>
              {/* <p>100% Secure</p> */}
            </div>
          </div>
          <div id="newhome-section-truck">
            <div className="newhome-section-truck-img">
              <img src={gift} alt="" />
            </div>
            <div className="newhome-section-truck-text">
              <h3>Refer & Earn</h3>
              {/* <p>Share BROBO App</p> */}
            </div>
          </div>
          <div id="newhome-section-truck">
            <div className="newhome-section-truck-img">
              <img src={users} alt="" />
            </div>
            <div className="newhome-section-truck-text">
              <h3> Support</h3>
              {/* <p>Ready for You</p> */}
            </div>
          </div>
        </div>
      </section>
      <hr />

       <section>
            <div>
              {couponcode.slice(0,2).map((coupon, index) => (
                <div
                  className="coupon-container"
                  style={{
                    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.5)), url(${coupon.background_image})`,
                  }}
                  key={index}
                >
                  <div className="coupon-title">
                    <div>
                      <h1>{coupon.title}</h1>
                      <p>Use BROBO coupon code to get discount</p>
                    </div>
                    <img
                      onClick={() => handleCopyCoupon(coupon.code)}
                      src={copybutton}
                      alt=""
                    />
                  </div>
                  <div className="coupon-content">
                    <div className="coupon-code">
                      Coupon Code:-
                      <span className="coupon-code-value">{coupon.code}</span>

                    </div>
                    <div className="coupon-validity">
                      <p>
                        & Get Up to
                    
                      </p>
                      <h2>
                        {coupon.discount}{" "}
                        {coupon.discount_type === "percent" ? "%" : "₹"} OFF
                      </h2>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>
      {/* ===================================================== */}
      <section>
        <div className="newhome-popular-service">
          <div className="newhome-popular-service-1">
            <h4>Products</h4>
          </div>
          <div className="newhome-popular-service-2">
            <h1>Elevate Your Events with Exquisite Tents and Florals</h1>
            <p>
              Crafted with Precision, Delivered with Care, Elevate Your
              Lifestyle with Our Exceptional Range of Products, Offering
              Unparalleled Quality, Value, and Style!
            </p>
          </div>
          <div className="newhome-popular-service-3">
            <div className="newhome-popular-service-4">
              <p onClick={toggleProducts}>All Products</p>
            </div>
          </div>
        </div>
        {/* ================================Fast Moving item============================================== */}
        <div className="popular-services">
          <div></div>
          <div className="grid">
            {/* Display only the first 4 products initially */}
            {popular.slice(0, 4).map((popular, index) => (
              <div
                className={`item ${showAllProducts ? "show" : ""}`}
                key={index}
              >
                <img onClick={()=>handlesubcategoryselection(popular.parent_id,popular.id)} className="image" src={popular.image} alt="" />
                <p className="text">{popular.name}</p>
              </div>
            ))}
            {/* Display the rest of the products if showAllProducts is true */}
            {showAllProducts &&
              popular.slice(4).map((popular, index) => (
                <div className="item show" key={index}>
                  <img onClick={()=>handlesubcategoryselection(popular.parent_id,popular.id)} className="image" src={popular.image} alt="" />
                  <p className="text">{popular.name}</p>
                </div>
              ))}
          </div>
        </div>
        {/* ================================Fast Moving item============================================== */}
      </section>

      {/* ]=========================================================0 */}
        <NavLink to="/referearn">
      <div className="newhome-coupon-code-1">
           <img src={referimage} alt="" />
          </div>
        </NavLink>
    
          {/* ===================================================================== */}
         
      {/* ====================================================================== */}
      <section>
      <div className="newhome-popular-service">
          <div className="newhome-popular-service-1">
            <h4>Fast Moving Item</h4>
          </div>
          <div className="newhome-popular-service-2">
            <h1>Fast Moving Products</h1>
            <p>
            Grab Them Before They're Gone: Shop Our Fast-Moving Products for Unbeatable Quality and Value!"
            </p>
          </div>
          <div   className="newhome-fast-moving-3">
            <div onClick={toggleTeam}  className="newhome-fast-moving-4">
              <p >Fast Moving Products</p>
            </div>
          </div>
        </div>
            {/* ================================Fast Moving item============================================== */}
            <div className="popular-services">
          <div></div>
          <div className="grid">
            {/* Display only the first 4 products initially */}
            {fastmovings.slice(0, 4).map((fastmoving, index) => (
              <div
                className={`item ${showAllTeam ? "show" : ""}`}
                key={index}
              >
                <img onClick={()=>handledetails(fastmoving.id)} className="image" src={fastmoving.image} alt="" />
                <p className="text">{fastmoving.name}</p>
              </div>
            ))}
            {/* Display the rest of the products if showAllProducts is true */}
            {showAllTeam &&
              fastmovings.slice(4).map((fastmoving, index) => (
                <div className="item show" key={index}>
                  <img onClick={()=>handledetails(fastmoving.id)} className="image" src={fastmoving.image} alt="" />
                  <p className="text">{fastmoving.name}</p>
                </div>
              ))}
          </div>
        </div>
        {/* ================================Fast Moving item============================================== */}
      </section>
      {/* ======================================================================= */}
      <section>
        <div className="newhome-whyus-section">
          <div className="newhome-whyus">
            <h5>Why Us</h5>
          </div>
          <div className="our-commitment-to-quality">
            <h3>Our Commitment to Quality</h3>
            <div className="unparalel-quality">
              <img src={unparallel} alt="" />
              <div className="unparalel-quality-1">
                <h5>Unparalleled Quality</h5>
                <p>
                  We take pride in ourselves on offering only the finest products,
                  carefully curated to exceed your expectations.
                </p>
              </div>
            </div>
            <div className="fast-shipping">
              <img src={fastshipping} alt="" />
              <div className="fast-shipping-1">
                <h5>Fast Shipping</h5>
                <p>
                  With our efficient shipping process, you can expect your order
                  to arrive quickly and reliably.
                </p>
              </div>
            </div>
            <div className="trust-and-reliability">
              <img src={trustreliability} alt="" />
              <div className="trust-and-reliability-1">
                <h5>Trust and Reliability</h5>
                <p>
                Count on us to deliver on our promises, every time. With years of experience and a proven track record, we're a name you can trust.
                </p>
              </div>
            </div>
          </div>
          <div className="brobo-black-img">
            <img src={broboblack} alt="" />
          </div>
        </div>
      </section>
      {/* ====================================================================== */}
    </div>
  );
}
