import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import './firststep.css';
import ApiService from '../../../Api/ApiController';
import ReactInputMask from 'react-input-mask';
import { useTimeSlot } from '../../addtocart/TimeSlotContext';
import Swal from 'sweetalert2';

export default function FirstStep() {
  const [mobileNumber, setMobileNumber] = useState('');
  const { setUserId } = useTimeSlot();
  const navigate = useNavigate();

  const handleNext = useCallback(async () => {
    try {
      const response = await ApiService.forgetpassword({ mobile_number: mobileNumber });
      
      if (response.data.status === 'success') {
        const customerId = response.data.data.id;
        setUserId(customerId);

        navigate('/secondstep', { state: { mobileNumber, customerId } });
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
          timerProgressBar:true
        });
      }
    } catch (error) {
      console.error('Error:', error);
    }
  }, [mobileNumber, navigate, setUserId]);

  return (
    <section>
      <div className="container-forget">
        <h2 className="title-forget">Enter Mobile Number</h2>
        <div className="input-container-forget">
          <ReactInputMask
            mask="+\9\19999999999"
            placeholder="Enter mobile number"
            value={mobileNumber}
            onChange={(e) => setMobileNumber(e.target.value)}
            className="form-input2"
          />
        </div>
        <div className="forget-mobile-btn">
          <button onClick={handleNext} className="next-btn-forget">
            Next
          </button>
        </div>
      </div>
    </section>
  );
}
