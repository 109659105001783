// import React, { useState } from "react";
// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import "./login.css"; // Import CSS file for styling
// import ApiService from "../../../Api/ApiController";
// import Swal from "sweetalert2";
// import brobo from '../../../header/brobo.png'
// import InputMask from "react-input-mask";
// import { NavLink, useNavigate } from "react-router-dom";
// export default function Login() {
//   const [value, setValue] = useState();
//   const [mobilenumber,setmobilenumber] = useState('')
//   const [password,setPassword] = useState('');
//   const [Error, setError] = useState("");
//   const [passwordVisible, setPasswordVisible] = useState(false);
//   const navigate = useNavigate();
//   const togglePasswordVisibility = () => {
//     setPasswordVisible(!passwordVisible);
//   };
  
// const handleLogin = async (e) => {
//   e.preventDefault();
  
//   try {
//     // Call the login API
//     const response = await ApiService.login({
//       mobile_number: mobilenumber,
//       password,
//       role_id: 2,
//     });



//     if (response.data.status === "success") {
//       const authtoken = response.data.data.auth_token;
//       const userid = response.data.data.id
//       sessionStorage.setItem('authtoken', authtoken);
//       localStorage.setItem('userId',userid) // Fix the typo here
//       navigate('/')
//       setmobilenumber("");
//       setPassword("");
//       setError(null);
      
//       Swal.fire({
//         icon: "success",
//         title: "Login Successful",
//         text: response.data.message,
//       });
//       window.location.reload()
//     } else {
//       setError(response.data.message);
//     }
//   } catch (error) {
//     console.error("Login failed:", error);
//     // Handle the error here
//     Swal.fire({
//       icon: "error",
//       title: "Login Failed",
//       text: "An error occurred while logging in. Please try again later.",
//     });
//   }
// };


//   return (
//     <div>
//       <div className="login-container">

//       <section className="login-section">
//         <div className="login-content">
//         <div className="login-image">
//         <img src={brobo} alt="" />
//         </div>
//           <div className="login-header">
//             <h1>Login!</h1>
//             <small>Welcome back, You've been missed!</small>
//           </div>
//           <form className="login-form" action="">
//             <div className="form-group">
//               <label htmlFor="mobile_number" className="form-label">
//                 Mobile Number:
//               </label>
//                  <InputMask
//       mask="+\9\19999999999"
//       placeholder="Enter mobile number"
//       value={mobilenumber}
//       onChange={(e) => setmobilenumber(e.target.value)}
//       className="form-input1"
//     />
//             </div>
//             <div className="form-group">
//             <label htmlFor="mobile_number" className="form-label">
//                 Password:
//               </label>
//     <div className="input-with-icon">
//         <input 
//             type={passwordVisible ? 'text' : 'password'} 
//             value={password} 
//             onChange={(e) => setPassword(e.target.value)} 
//             className="form-input1" 
//             placeholder="Enter Password" 
//             required 
//         />
//                     <svg viewBox="0 0 576 512" height="20" xmlns="http://www.w3.org/2000/svg" className="eye-icon" onClick={togglePasswordVisibility}>
//   {passwordVisible ? (
//     <path d="M288 480c-80.8 0-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6c46.8-43.5 111.5-80.6 192.6-80.6s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480zm0-368c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm0 0"></path>
//   ) : (
//     <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
//   )}
// </svg>
//     </div>
// </div>

//             <NavLink to="/firststep"><small>Forget password?</small></NavLink>
//             {Error && <div className="error-message">{Error}</div>}
//             <button onClick={handleLogin} className="">Submit</button>
//             <div>
//                 <p>Don't have an account? <NavLink to="/signup">Sign up</NavLink></p>
//             </div>
//           </form>
//         </div>
//       </section>
//     </div>
//                 </div>
//   );
// }



import React, { useState, useCallback } from "react";
import "react-phone-number-input/style.css";
import InputMask from "react-input-mask";
import "./login.css";
import ApiService from "../../../Api/ApiController";
import Swal from "sweetalert2";
import brobo from '../../../header/brobo.png';
import { NavLink, useNavigate } from "react-router-dom";

export default function Login() {
  const [formState, setFormState] = useState({
    mobilenumber: '',
    password: '',
    isLoading: false,
    error: ''
  });
  const [passwordVisible, setPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleLogin = useCallback(async (e) => {
    e.preventDefault();
    setFormState(prevState => ({ ...prevState, isLoading: true, error: '' }));

    try {
      const { mobilenumber, password } = formState;
      const response = await ApiService.login({
        mobile_number: mobilenumber,
        password,
        role_id: 2,
      });

      if (response.data.status === "success") {
        const { auth_token, id } = response.data.data;
        sessionStorage.setItem('authtoken', auth_token);
        localStorage.setItem('userId', id);
        
        Swal.fire({
          icon: "success",
          title: "Login Successful",
          text: response.data.message,
        });

        navigate('/');
        window.location.reload();
      } else {
        setFormState(prevState => ({ ...prevState, error: response.data.message }));
      }
    } catch (error) {
      console.error("Login failed:", error);
      Swal.fire({
        icon: "error",
        title: "Login Failed",
        text: "An error occurred while logging in. Please try again later.",
      });
    } finally {
      setFormState(prevState => ({ ...prevState, isLoading: false }));
    }
  }, [formState, navigate]);

  const { mobilenumber, password, isLoading, error } = formState;

  return (
    <div className="login-container">
      <section className="login-section">
        <div className="login-content">
          <div className="login-image">
            <img src={brobo} alt="" />
          </div>
          <div className="login-header">
            <h1>Login!</h1>
            <small>Welcome back, You've been missed!</small>
          </div>
          <form className="login-form" onSubmit={handleLogin}>
            <div className="form-group">
              <label htmlFor="mobilenumber" className="form-label">
                Mobile Number:
              </label>
              <InputMask
                mask="+\9\19999999999"
                placeholder="Enter mobile number"
                name="mobilenumber"
                value={mobilenumber}
                onChange={handleInputChange}
                className="form-input1"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password" className="form-label">
                Password:
              </label>
              <div className="input-with-icon">
                <input
                  type={passwordVisible ? 'text' : 'password'}
                  name="password"
                  value={password}
                  onChange={handleInputChange}
                  className="form-input1"
                  placeholder="Enter Password"
                  required
                />
                <svg viewBox="0 0 576 512" height="20" xmlns="http://www.w3.org/2000/svg" className="eye-icon" onClick={togglePasswordVisibility}>
                  {passwordVisible ? (
                    <path d="M288 480c-80.8 0-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6c46.8-43.5 111.5-80.6 192.6-80.6s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480zm0-368c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm0 0"></path>
                  ) : (
                    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
                  )}
                </svg>
              </div>
            </div>

            <NavLink to="/firststep"><small>Forget password?</small></NavLink>
            {error && <div className="error-message">{error}</div>}
            <button type="submit" className="submit-btn submit_loader" disabled={isLoading}>
              {isLoading ? (
                <div className="button_loader"></div>
              ) : (
                'Submit'
              )}
            </button>
            <div>
              <p>Don't have an account? <NavLink to="/signup">Sign up</NavLink></p>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
