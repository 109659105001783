import React from 'react'
import './loader.css';
export default function Loader() {
  return (
    <div className="loader-container">
      <div className="wrapper1">
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="circle"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
    <div className="shadow"></div>
</div>
    </div>
  )
}
