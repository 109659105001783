import React, { useEffect, useState } from 'react'
import brobo from '../../header/brobo.png'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cart from './Iconcart.png'
import bell from './Iconbelll.png'
import drawer from './Icondrwawer.png'
import location1 from './location.png'
import './header.css'
import ApiService from '../../Api/ApiController';
import Swal from 'sweetalert2';
import search from './search.png'
import { useTimeSlot } from '../../pages/addtocart/TimeSlotContext';
import userpng from './userpng.png';
import bookingpng from './bookingpng.png';
import wishlistpng from './wishlistpng.png';
import deliveraddresspng from './deliveryaddresspng.png';
import eclipsepng from './eclipsepng.png';
import settingpng from './settingpng.png';
import quepng from './quepng.png';
import referpng from './refferalpng.png';
import faqpng from './faqpng.png';
import aboutbrobopng from './aboutbrobopng.png'

export default function Header() {
  const [currentAddress, setCurrentAddress] = useState("");
  const [subcategories, setSubcategories] = useState([]);
  const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
  const [visible, setVisible] = useState(true);
  const [noticount,setnoticount] = useState([]);
  const location = useLocation();
  // const [showInput, setShowInput] = useState(false);
  const [showLocationButton, setShowLocationButton] = useState(false);
    const [showLocationModal, setShowLocationModal] = useState(false);
   const {setpinlocation} =  useTimeSlot()
  const [error, setError] = useState(null);
  const [ordersettings,setOrderSetting] = useState([])
  const token =  sessionStorage.getItem('authtoken')
 const [cartcount,setCartCount] = useState([])
 const [Profile,setprofile] = useState([])
 const [latitude,setlatitude] = useState('');
 const [longitude,setlongitude] = useState('');
 const [mapikey,setmapikey] =  useState([])
 const [autocomplete, setAutocomplete] = useState(null);
 const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate()
  useEffect(()=>{
    ApiService.getSettingData({
      key:'map_api_key'
    }).then((response)=>{
      if(response.data.status === 'success'){
      // ();
      setmapikey(response.data.data.value)
    }else{
     
    }
  }).catch((err)=>{
  
  })
  
},[]);

 useEffect(()=>{
   setpinlocation(currentAddress)
 },[currentAddress,setpinlocation])
  useEffect(() => {
      
   
    const loadGoogleMaps = () => {
      if (!window.google) {
          const script = document.createElement('script');
          script.type = 'text/javascript';
          script.src = `https://maps.googleapis.com/maps/api/js?key=${mapikey}&libraries=places`;
          script.onload = () => {
              ('Google Maps script loaded');
              initAutocomplete();
          };
          script.onerror = (error) => {
              console.error('Error loading Google Maps script:', error);
          };
          document.head.appendChild(script);
      } else {
          initAutocomplete();
      }
  };
  

    const initAutocomplete = () => {
        const google = window.google;
        const newAutocomplete = new google.maps.places.Autocomplete(document.getElementById('search-input'));

        newAutocomplete.addListener('place_changed', () => {
            const place = newAutocomplete.getPlace();
            if (!place.geometry) {
                console.error("No details available for input: '" + place.name + "'");
                return;
            }
            const latitude = place.geometry.location.lat();
            setlatitude(latitude)
            const longitude = place.geometry.location.lng();
            setlongitude(longitude)
            const formattedAddress = place.formatted_address;
            setCurrentAddress(formattedAddress)
            // setCurrentAddress(prevAddress => formattedAddress); 
            
            const locationData = {currentAddress,latitude,longitude};
            sessionStorage.setItem('location', JSON.stringify(locationData));
        });

        setAutocomplete(newAutocomplete);
    };

    loadGoogleMaps();
}, [mapikey]);
  useEffect(() => {
    const getLocation = () => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            getAddress(position.coords.latitude, position.coords.longitude);
          },
          (error) => {
            setError(error.message);
          }
        );
      } else {
        setError("Geolocation is not supported by this browser.");
      }
    };

    getLocation();
  }, []);

  useEffect(()=>{
    if(token){

      ApiService.getprofiledetails().then((response)=>{
      if(response.data.status === 'success'){
        setprofile(response.data.data)
      }else{
        
      }
    })
  }
},[token])
  
  // const handleLocationClick = () => {
  //   setShowInput(true); // Show the input field when the location icon is clicked
  // };


  const handleInputBlur = () => {
    setShowInput(false);
  };
  const loginSignUp =()=>{
    navigate('/login');
  }

  const notify = ()=>{
    navigate('/notification')
  }
  const handleCategorySelection = async (category, categoryId) => {
    try {
      const response = await ApiService.getsubCategories(categoryId);
      setSubcategories(response.data.data);
      // (response)
    } catch (error) {
      console.error("Error fetching subcategories:", error);
    }
  };
  const handlesubcategoryselection = (categoryId, subcategoryId) => {

    navigate(`/productlist/${categoryId}/${subcategoryId}`);
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const visible = prevScrollPos > currentScrollPos;

      setPrevScrollPos(currentScrollPos);
      setVisible(visible);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [prevScrollPos]);

  useEffect(()=>{
    if(token){

      ApiService.notificationcount().then((response)=>{
        if(response.data.status === 'success'){
          // (response.data)
          setnoticount(response.data.data)
        }
      }).catch((err)=>{
      })
    }
  },[token])

  useEffect(()=>{
    if(token){

      ApiService.getOrderSetting().then((response)=>{
        if(response.data.status === 'success'){
          setOrderSetting(response.data.data)
        }else{
          
        }
      })
    }
  },[token])


 
    // const getLocation = () => {
    //   if (navigator.geolocation) {
    //     navigator.geolocation.getCurrentPosition(
    //       (position) => {
    //         getAddress(position.coords.latitude, position.coords.longitude);
    //       },
    //       (error) => {
    //         console.error(error.message);
    //       }
    //     );
    //   } else {
    //     console.error("Geolocation is not supported by this browser.");
    //   }
    // };

    const getAddress = (latitude, longitude) => {
      const geocoder = new window.google.maps.Geocoder();
      const latLng = { lat: latitude, lng: longitude };
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            setCurrentAddress(results[0].formatted_address);
            setlatitude(latitude)
            setlongitude(longitude)
            
          } else {
            console.error("No results found");
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    };

    const handleAddressClick = () => {
      
      setShowLocationButton(true);
    };

    const handleSearchButtonClick = () => {
      setShowLocationModal(true);
    };

    
    const cartCount = ()=>{
      if(token){
      ApiService.getCartDetails()
        .then((response) => {
          if (response.data.status === "success") {
           console.log(response.data.data.length)
          setCartCount(response.data.data.length)
          
         
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
    }

    useEffect(()=>{
      cartCount()
    },[])

  
    const handleWhatsAppRedirect = () => {
      const whatsappNumber = ordersettings.whatsapp_number;
    
      // Use the WhatsApp URL scheme with the phone number
      window.location.href = `https://wa.me/${whatsappNumber}`;
      // window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;

    };
    // ${visible ? 'visible' : 'hidden'}

    const MyProfile = ()=>{
      navigate('/myprofile')
    }
    const Locationcheck = async () => {
      try {
        const Response = await ApiService.LocationCheck({
          location_name: currentAddress,
          latitude: latitude,
          longitude: longitude
        });
  
        if (Response.data.status === 'success') {
          
          const locationData = {currentAddress,latitude,longitude};
          sessionStorage.setItem('location', JSON.stringify(locationData));
          
          navigate('/cart');
        } else {
          const locationData = {currentAddress,latitude,longitude};
          sessionStorage.setItem('location', JSON.stringify(locationData));
          Swal.fire({
            title: "Oops...",
            html: `
              <div style="font-family: Arial, sans-serif;">
                <h2>Welcome to <span style="color: #ff6600;">BROBO!</span></h2>
                <p>We’re expanding to bring premium event rentals to more areas.</p>
                <ul style="list-style-type: none; padding-left: 0;">
                  <li><span style="font-weight: bold;">📍 Active in Bangalore</span></li>
                  <li><span style="font-weight: bold;">🔄 Keep Updated:</span> Select ‘Change Location’ to know when we reach you.</li>
                  <li><span style="font-weight: bold;">👀 Explore our app</span> for a preview of our services.</li>
                </ul>
                <p>We value your interest and are working to reach you soon!</p>
              </div>
            `,
            showCloseButton: true,
            showConfirmButton: false,
            showCancelButton:true,
            cancelButtonText:"Change Location",
            customClass: {
              popup: 'my-custom-popup-class',
              closeButton: 'my-custom-close-button-class'
            },
            onBeforeOpen: (modalElement) => {
              modalElement.querySelector('#change-location-btn').addEventListener('click', () => {
                Swal.close(); // Close the SweetAlert dialog when the button is clicked
              });
            }
          });
          
        }
      } catch (error) {
        console.error(error);
      }
    };
  

    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [showSearchSheet, setShowSearchSheet] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
 
    // Function to handle search
    const handleSearch = (page) => {
       ApiService.GetAllProducts({
        search_key:searchTerm,
        page
       }).then((response)=>{
        // (response.data)
              if(response.data.status === 'success'){
                
                setSearchResults(response.data.data)
              }
       }).catch((err)=>{
       
       })
    };
  
    // Function to handle search input change
    const handleInputChange = (event) => {
      setSearchTerm(event.target.value);
      // Trigger search when input changes
      handleSearch(currentPage);
    };
  
    // Function to close search sheet
    const handleCloseSheet = () => {
      setShowSearchSheet(false);
    };
  
    const handledetails = (productId) => {
      navigate(`/productdetail/${productId}`);
      setShowSearchSheet(false)
    };

    const getLocalStorageCartCount = () => {
      const storedCartItems = localStorage.getItem('cartitems');
      const itemCount = storedCartItems ? Object.keys(JSON.parse(storedCartItems)).length : 0;
     
      return itemCount;
    };
    const outsideClick = () => {

      setShowSearchSheet(false);
    };
    const [showInput, setShowInput] = useState(false);
  const handleLocationClick = () => {
    setShowInput(true); // Show the input box when the image is clicked
  }
 
useEffect(() => {
  setSidebarOpen(false);
}, [location.pathname]);
const toggleSidebar = () => {
  setSidebarOpen(!sidebarOpen);
};
const [showDropdown, setShowDropdown] = useState(false);

const handleDropdownClick = () => {
  setShowDropdown(false); // Hide the dropdown on click
};
const [showDropdowns, setShowDropdowns] = useState(false);

const handleDropdownClicks = () => {
  setShowDropdowns(false); // Hide the dropdown on click
};
  return (
    <div>
      <section className={`new-header-section-1 `}>
        <header className='new-header-1'>
            <div className='new-header-2'>
              <div className='new-header-2-brobo'>
                <NavLink to='/'>
         <img src={brobo} alt="brobo" />
                </NavLink>
              </div>
              <div 
              className='new-header-2-drawer'
         
              >
             
               <div
                className={`offcanvas offcanvas-end side-nav-bar ${sidebarOpen ? 'show' : ''}`}
                data-bs-scroll="true"
                tabIndex="-1"
                id="offcanvasWithBothOptions"
                
                aria-labelledby="offcanvasWithBothOptionsLabel"
              >
                <div className="offcanvas-header">
                  <h5
                    className="offcanvas-title"
                    id="offcanvasWithBothOptionsLabel"
                  >
                    Hello,{Profile.name}
                  </h5>
                  <button
                    type="button"
                    className="btn-close"
                    onClick={toggleSidebar}
                    data-bs-dismiss="offcanvas"
                    aria-label="Close"
                  ></button>
                </div>
                <div className="offcanvas-body ">
                  <div className="side-nav-navlinks">
                    <NavLink to="/myprofile" className="navlink-side-text"><img src={userpng} alt="" />My Profile</NavLink>
                    <NavLink to="/mybookings" className="navlink-side-text"><img src={bookingpng} alt="" />My Bookings</NavLink>
                    <NavLink to='/deliveryaddress'className="navlink-side-text"><img src={deliveraddresspng} alt="" />Manage address</NavLink>
                    <NavLink to='/wishlist'className="navlink-side-text"><img src={wishlistpng} alt="" />Wishlist</NavLink>
                    <NavLink to="/payfordamage"className="navlink-side-text"><img src={eclipsepng} alt="" />Pay for damage</NavLink>
                    <NavLink to="/settings"className="navlink-side-text"><img src={settingpng} alt="" />Settings</NavLink>
                    {/* <NavLink to="/resetpassword">Password</NavLink> */}
                    <div onClick={handleWhatsAppRedirect}className="navlink-side-text"><img src={quepng} alt="" />Help</div>
                    <NavLink to='/FAQS'className="navlink-side-text"><img src={faqpng} alt="" />FAQ</NavLink>
                    {/* <div>Share App</div> */}
                    <NavLink to="/referearn"className="navlink-side-text"><img src={referpng} alt="" />Refer Now</NavLink>
                    <NavLink to="/aboutus"className="navlink-side-text"><img src={aboutbrobopng} alt="" />About Brobo</NavLink>
                    {/* <div>Logout</div> */}
     
                  </div>
                </div>
              </div>
              </div>
              <div className='new-header-2-location'>
  <form onSubmit={handleSearchButtonClick}>
    <div className="location-input-container">
      <img src={location1} alt="location" onClick={handleLocationClick} />
      <input
        type="text"
        id="search-input"
        className={` input-header-loc  ${showInput ? 'show' : ''}`}
        value={currentAddress}
        onChange={(e) => setCurrentAddress(e.target.value)}
        onClick={handleAddressClick}
        placeholder="Enter your location"
        autoFocus 
      />
     
    </div>
  </form>
 
</div>

            </div>
             <nav className='new-header-3'>
                <div className='new-header-navlink-1'>
                    <NavLink to="/">Home</NavLink>
                    <NavLink to="/aboutus">About Us</NavLink>
                    <div className="dropdown" >
      <NavLink
        href="#"
        onMouseOver={() => {
          handleCategorySelection('Tent', 1);
          setShowDropdown(true); // Show dropdown on mouse over
        }}
      >
        Tent
      </NavLink>
      {showDropdown && (
        <div className="dropdown-content">
          {subcategories
            .filter((subcategory) => subcategory.parent_id === 1) // Filter subcategories for "Tent"
            .map((subcategory, index) => (
              <button
                onClick={() => {
                  handlesubcategoryselection(subcategory.parent_id, subcategory.id);
                  handleDropdownClick(); // Hide dropdown on button click
                }}
                key={index}
              >
                {subcategory.name}
              </button>
            ))}
        </div>
      )}
    </div>
              {/* -------------------------------------------- */}
              
              <div className="dropdown" >
      <NavLink
        href="#"
        onMouseOver={() => {
          handleCategorySelection('Florals', 2);
          setShowDropdowns(true); // Show dropdown on mouse over
        }}
      >
        Florals
      </NavLink>
      {showDropdowns && (
        <div className="dropdown-content">
          {subcategories
            .filter((subcategory) => subcategory.parent_id === 2) // Filter subcategories for "Florals"
            .map((subcategory, index) => (
              <button
                onClick={() => {
                  handlesubcategoryselection(subcategory.parent_id, subcategory.id);
                  handleDropdownClicks(); // Hide dropdown on button click
                }}
                key={index}
              >
                {subcategory.name}
              </button>
            ))}
        </div>
      )}
    </div>

              {/* -------------------------------------------- */}
                    <NavLink to="/#" onClick={handleWhatsAppRedirect}>Help</NavLink>
                </div>
                <div className='new-header-navlink-2'>
                  {token ? 
                  <h6 onClick={MyProfile}>Hello,{Profile.name}</h6>
                  :
                    <button onClick={loginSignUp}>Log in / Sign up<i className='bx bx-arrow-back bx-rotate-180' ></i></button>
                  }


                  <div className="notification-badge-container">
  <img onClick={notify} src={bell} alt="Notification Bell" className="bell-icon" />
  {token ?
<span className="notification-badge">{noticount.notification_count}</span>
:
""
  }
</div>

   
 <div>
    
      <img onClick={() => setShowSearchSheet(true)}  src={search} alt="Notification Bell" className="bell-icon" />
      {/* Search sheet */}
      {showSearchSheet && (
        <>

        <div onClick={outsideClick} className="search-sheet-container-1"></div>


        <div  className="search-sheet-container">
          <div className="search-sheet">
            <div className="search-sheet-header">
            <div className="search-input-group">
    <input
      type="text"
      value={searchTerm}
      onChange={handleInputChange}
      placeholder="Search..."
      autoFocus
      />
    <button onClick={() => handledetails(searchResults[0].id)}><i className='bx bx-search'></i></button>
  </div>
              <buttonx className='btn-close' onClick={handleCloseSheet}></buttonx>
            </div>
            <ul>
              {searchResults.map((result, index) => (
                <li onClick={()=> handledetails(result.id)} key={index}>
                <div className='search-resuly-list'>
                  <span><img src={result.image} alt="" /></span>
                  {result.name}
                </div>
                </li>
              ))}
            </ul>
           
          </div>
        
  </div>

        </>
      )}
    </div>

                  <NavLink onClick={Locationcheck} >
            <div className="notification-badge-container">
                  <img src={cart} alt="" />
                  {token ?

(
  <span className="notification-badge">{cartcount}</span>
)
                    : (
                      <span className="notification-badge">
                       
                       {getLocalStorageCartCount()}
                      </span>
                    )}
</div> 
                
                  </NavLink>
                
                 <img
          className="side-nav-user-icon"
          type="button"
          onClick={toggleSidebar}
          src={drawer}
          alt="drawer"
          // data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasWithBothOptions"
          aria-controls="offcanvasWithBothOptions"
        />

                </div>
             </nav>
        </header>
      </section>
    </div>
  )
}


