import React, { useEffect, useState } from "react";
import "./footer.css"; // Import external CSS file
import { NavLink } from "react-router-dom";
import brobo from '../header/brobo.png'
import ApiService from "../Api/ApiController";
const Footer = () => {
  const [products,setproduct] = useState([])
  const [ordersettings,setOrderSetting] = useState([])
  const token = sessionStorage.getItem('authtoken')
  const [aboutus,setaboutus]= useState([])
    const apiKey = 'about_us';
    useEffect(()=>{
      ApiService.getSettingData(apiKey).then((response)=>{
         if(response.data.status === 'success'){
           setaboutus(response.data.data)
         }
      }).catch((error)=>{
            console.log(error)
      })
 },[])
    const limitwords = (text,limit)=>{
      if(!text) {return "Hello From Brobo"}
      const words = text.split('');
      if(words.length > limit){
        return words.slice(0,limit).join('') + '...';
      }else{
        return text
      }
    }
  useEffect(()=>{
    if(token){

      ApiService.getfastmovingitems().then((response)=>{
        if(response.data.status === 'success'){
          setproduct(response.data.data)
        }
      })
    }
  },[])
  const handleProductLinkClick = () => {
    window.scrollTo(0, 0);
  };
  useEffect(()=>{
    if(token){

      ApiService.getOrderSetting().then((response)=>{
        if(response.data.status === 'success'){
          setOrderSetting(response.data.data)
        }else{
          console.log(response.data)
        }
      })
    }
  },[])
  const handleWhatsAppRedirect = () => {
    const whatsappNumber = ordersettings.whatsapp_number;
  
    // Use the WhatsApp URL scheme with the phone number
    window.location.href = `https://wa.me/${whatsappNumber}`;
    // window.location.href = `https://api.whatsapp.com/send?phone=${whatsappNumber}`;

  };


  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-column-main">
       
          <div className="footer-column2">
            <h2>Information</h2>
            
            <NavLink to="/terms-condition">Terms and Condition</NavLink>
            <NavLink to="/privacy-policy">Privacy Policy</NavLink>
            <NavLink to="/returnpolicy">Return Policy</NavLink>
            <NavLink to="/replacementpolicy">Replacement Policy</NavLink>
            <NavLink to="/cancellationpolicy">Cancellation Policy</NavLink>
            <NavLink to="/shippingpolicy">Shipping Policy</NavLink>
          </div>
          <div className="footer-column3">
            <h2>Company</h2>
            <NavLink to="/aboutus">About Us</NavLink>
            <NavLink to="/contactus">Contact Us</NavLink>
            {/* <NavLink to="/twitter">Twitter</NavLink>
            <NavLink to="/facebook">Facebook</NavLink>
            <NavLink to="/instagram">Instagram</NavLink> */}
          </div>
        </div>
        <div className="footer-subscribe-section">
          <section className="footer-subscribe-section-main">
            <div className="footer-subscribe-section-head">
              <h2>About Brobo</h2>
            </div>
            <div className="footer-subscribe-section-1">
         
              <div className="footer-subscribe-section-para">
                <h6>Currently serving in Bangluru only! coming soon in Mumbai, Delhi, Hyderabad and Kolkata. </h6>
                <small>
                <p dangerouslySetInnerHTML={{ __html:limitwords(aboutus.value,172) }} />
                </small>
              </div>
            </div>
          </section>
        </div>
      </div>
          <section className="footer-below-section">
            <div className="footer-below-section-1">
          <hr  />
            </div>
            <div className="footer-below-section-sub">
                <div className="footer-below-section-sub-img">
                  <img src={brobo} alt="" />
                </div>
                <div className="footer-below-icon-main">
                  <NavLink to="https://www.linkedin.com/company/brobo-in/" className="footer-below-icon"><i className='bx bxl-linkedin'></i></NavLink>
                  <NavLink to="https://www.facebook.com/Tentwala24hrs/" className="footer-below-icon"><i className='bx bxl-facebook'></i></NavLink>
                  <NavLink to="https://www.youtube.com/@Broboyouchoose" className="footer-below-icon"><i className='bx bxl-youtube'></i></NavLink>
                  <NavLink to="https://www.instagram.com/brobo_you_choose_we_deliver/" className="footer-below-icon"><i className='bx bxl-instagram' ></i></NavLink>
                </div>
            </div>
          </section>
      <div className="footer-copyright">
        <p>&copy; 2023 Brobo. all rights reserved</p>
      </div>
    </footer>
  );
};

export default Footer;
