import React, { useEffect, useState } from 'react';
import './faqs.css'; // You can create a separate CSS file for styling
import ApiService from '../../Api/ApiController';

export default function FAQS() {
    const [faqs,setfaqs] = useState([])

   const FAQs = () =>{
    ApiService.FAQs().then((response)=>{
                if(response.data.status === 'success'){
                    setfaqs(response.data.data)
                }else{
                    console.log(response)
                }
    }).catch((err)=>{
        console.log(err)
    })
   }
         useEffect(()=>{
            FAQs()
         },[])
  const [activeIndex, setActiveIndex] = useState(null);

  const toggleAccordion = (index) => {
    if (activeIndex === index) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  return (
    <div className="faqs-container">
      <h1>Frequently Asked Questions</h1>
      <div className="faqs">
        {faqs.map((faq, index) => (
          <div key={index} className="faq">
            <div className={`faq-header ${activeIndex === index ? 'faqactive' : ''}`} onClick={() => toggleAccordion(index)}>
              <h3>{faq.question}</h3>
              <span className="faqicon">{activeIndex === index ? '-' : '+'}</span>
            </div>
            {activeIndex === index && (
              <div className="faq-body">
                <p  dangerouslySetInnerHTML={{__html :  faq.answer}}   ></p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
