import React, { useEffect, useState } from 'react';
import ApiService from '../Api/ApiController';

export default function LocationCheck() {
    const [currentAddress, setCurrentAddress] = useState("");
    const [autocomplete, setAutocomplete] = useState(null);
    const [latitude,setlatitude] = useState('');
    const [longitude,setlongitude] = useState('');
    const [mapikey,setmapikey] =  useState([])

    useEffect(() => {
      
      ApiService.getBuissnessSetting({
        key:'map_api_key'
      }).then((response)=>{
        if(response.data.status === 'success'){
          // console.log();
          setmapikey(response.data.data.value)
        }else{
          console.log(response)
        }
      }).catch((err)=>{
        console.log(err)
      })
      
      const loadGoogleMaps = () => {
        if (!window.google) {
            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.src = `https://maps.googleapis.com/maps/api/js?key=${mapikey}&libraries=places`;
            script.onload = () => {
                console.log('Google Maps script loaded');
                initAutocomplete();
            };
            script.onerror = (error) => {
                console.error('Error loading Google Maps script:', error);
            };
            document.head.appendChild(script);
        } else {
            initAutocomplete();
        }
    };
    
  
      const initAutocomplete = () => {
          const google = window.google;
          const newAutocomplete = new google.maps.places.Autocomplete(document.getElementById('search-input'));
  
          newAutocomplete.addListener('place_changed', () => {
              const place = newAutocomplete.getPlace();
              if (!place.geometry) {
                  console.error("No details available for input: '" + place.name + "'");
                  return;
              }
              const latitude = place.geometry.location.lat();
              setlatitude(latitude)
              const longitude = place.geometry.location.lng();
              setlongitude(longitude)
              const formattedAddress = place.formatted_address;
              console.log("d")
             console.log(formattedAddress)
          });
  
          setAutocomplete(newAutocomplete);
      };
  
      loadGoogleMaps();
  }, []);
  
    

    const Locationcheck = async (e)=>{
      console.log({
        location_name:currentAddress,
        latitude:latitude,
        longitude:longitude
      })
      e.preventDefault();
      try{
       ApiService.LocationCheck({
        location_name:currentAddress,
        latitude:latitude,
        longitude:longitude
       }).then((Response)=>{
        if(Response.data.status === 'success'){
            console.log(Response.data)
        }else{
            console.log(Response.data)
        }
       })
      }catch(error){
        console.error(error);
      }
    }

    const fetchCurrentLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const latitude = position.coords.latitude;
                    const longitude = position.coords.longitude;
                    setlatitude(latitude)
                    setlongitude(longitude)
                    // Now you have the latitude and longitude coordinates
                    getAddress(latitude, longitude); // Optional: Use the coordinates to fetch the address
                },
                (error) => {
                    console.error(error.message);
                }
            );
        } else {
            console.error("Geolocation is not supported by this browser.");
        }
    };
    
    const getAddress = (latitude, longitude) => {
        const geocoder = new window.google.maps.Geocoder();
        const latLng = { lat: latitude, lng: longitude };
        geocoder.geocode({ location: latLng }, (results, status) => {
            if (status === "OK") {
                if (results[0]) {
                    setCurrentAddress(results[0].formatted_address);
                } else {
                    console.error("No results found");
                }
            } else {
                console.error("Geocoder failed due to: " + status);
            }
        });
    };

    return (
        <div>
            <div className="form-group address-fetch">
                <label htmlFor="address" className="form-label">Address:</label>
                <input
                    type="text"
                    id="search-input"
                    value={currentAddress}
                    onChange={(e) => setCurrentAddress(e.target.value)}
                    name="address"
                    className="form-input"
                    placeholder="Enter address"
                />
                <button
                    type="button"
                    className="btn btn-outline-dark"
                    onClick={Locationcheck}
                >
                    <i className='bx bx-search'></i> Search
                </button>
            </div>
            <button
                type="button"
                className="btn btn-outline-dark"
                onClick={fetchCurrentLocation}
            >
                Fetch Current Location
            </button>
        </div>
    );
}





// const LocationSelectorModal = ({ onMapSelection }) => {
//   const [selectedLocation, setSelectedLocation] = useState("");

//   useEffect(() => {
//       const google = window.google;
//       const mapOptions = {
//           center: { lat: 0, lng: 0 }, // Default center
//       zoom: 8, // Default zoom level
//     };
//     const mapElement = document.getElementById("map");
//     const map = new google.maps.Map(mapElement, mapOptions);

//     const searchBox = new google.maps.places.SearchBox(
//       document.getElementById("search-input")
//     );
//     map.controls[google.maps.ControlPosition.TOP_LEFT].push(
//         document.getElementById("search-input-container")
//       );
  
//       map.addListener("bounds_changed", () => {
//           searchBox.setBounds(map.getBounds());
//     });

//     searchBox.addListener("places_changed", () => {
//       const places = searchBox.getPlaces();

//       if (places.length === 0) {
//         return;
//       }

//       const place = places[0];

//       if (!place.geometry) {
//         console.error("Returned place contains no geometry");
//         return;
//       }

//       const formattedAddress = place.formatted_address;
//       setSelectedLocation(formattedAddress);
//     });
//   }, []);

//   const handleConfirmLocation = () => {
//     onMapSelection(selectedLocation);
//   };

//   return (
//     <div className="modal1">
//       <div className="modal-content1">
//         <div id="search-input-container">
//           <input
//             id="search-input"
//             type="text"
//             placeholder="Search for location"
//             className="form-input"
//           />
//           <button className="btn btn-outline-dark " id="search-button">
//             Search
//           </button>
//         </div>
//         <div id="map" style={{ width: "100%", height: "300px" }}></div>
//         <button
//           className="btn btn-outline-dark"
//           onClick={handleConfirmLocation}
//         >
//           Confirm Location
//         </button>
//       </div>
//     </div>
//   );
// };
