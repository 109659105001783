import React, { useEffect, useState } from "react";
import ApiService from "../../Api/ApiController";
import './termscondition.css'
import Loader from "../../loader/Loader";
export default function TermsAndCondition() {
  const [termcondition, settermcondition] = useState([]);
  const apiKey = "terms_and_conditions";

  useEffect(() => {
    ApiService.getSettingData(apiKey)
      .then((response) => {
        console.log("Setting data:", response.data.data);
        settermcondition(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching setting data:", error);
      });
  }, []);
  if (termcondition.length === 0) {
    return <div style={{backgroundColor:"white",width:'100vw',height:'100vh'}}><Loader/></div>;
}
  return (
    <div>
      <div>
        <section>
          <div className="terms-condition">
            <div className="terms-condition-heading">
              <h1>Terms And Condition</h1>
            </div>
            <div className="terms-condition-paragraph">
              <p dangerouslySetInnerHTML={{ __html: termcondition.value }} />
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
