import React, { useEffect, useState } from "react";
import InputMask from "react-input-mask";
import "./personaldetails.css";
import ApiService from "../../../Api/ApiController";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
export default function PersonalDetails() {
  const [name, setname] = useState("");
  const [email, setemail] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [gender, setgender] = useState("");
  const [homecity,sethomecity] = useState('')
  const navigate = useNavigate();


   const handleupdate = async (e)=>{
     e.preventDefault();
 
      try{
       await  ApiService.updateprofile({
        name:name,
        email:email,
        mobile_number:mobilenumber,
        gender: gender,
        home_city:homecity
       }).then((response)=>{
         if(response.data.status === "success"){
          Swal.fire({
            icon:'success',
            title:'Profile',
            text:response.data.message,
            timer:3000
          })
          // navigate('/');
         }else{
          Swal.fire({
            icon:'error',
            text:response.data.message
          })
         }      

       }).catch((error)=>{
        console.log(error)
       })
      }catch(err){
        console.log(err)
      }
   }

  useEffect(() => {
    try{

      ApiService.getprofiledetails()
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data)
          setemail(response.data.data.email ?? "");
          setname(response.data.data.name ?? "");
          setmobilenumber(response.data.data.mobile_number ?? "");
          setgender(response.data.data.gender ?? "");
          sethomecity(response.data.data.home_city ?? "");
        }
      })
      .catch((error) => {
        console.log(error);
      });
    }catch(error){
      console.log(error)
    }
  }, []);
  return (
    <div>
      <section className="personal-details">
        <div className="personal-details-container">
          <h2>Profile Edit</h2>
          <div>
            <form onSubmit={handleupdate}>
              <div className="form-group">
                {/* <label htmlFor="fullName">Full Name</label> */}
                <input
                  value={name}
                  onChange={(e) => setname(e.target.value)}
                  type="text"
                  id="fullName"
                  placeholder="Enter Your Name"
                  className="form-input"
                />
              </div>
              <div className="form-group">
                {/* <label htmlFor="phoneNumber">Phone Number</label> */}
                <input
                  value={mobilenumber}
                  onChange={(e) => setmobilenumber(e.target.value)}
                  type="text"
                  accept="0-9"
                  id="fullName"
                  placeholder="Enter Phone Number"
                  className="form-input"
                />
                {/* <InputMask
                     mask="+\9\19999999999"
                  placeholder="Enter phone number"
                  value={mobilenumber}
                  onChange={(e) => setmobilenumber(e.target.value)}
                  className="form-input"
                  id="phoneNumber"
                  style={{ backgroundColor: "#ccc" }}
                /> */}
              </div>
              <div className="form-group">
                {/* <label htmlFor="email">Email</label> */}
                <input
                  value={email}
                  onChange={(e) => setemail(e.target.value)}
                  type="email"
                  id="email"
                  placeholder="Enter Email"
                  className="form-input"
                />
              </div>
              <div className="form-group">
                {/* <label htmlFor="email">Email</label> */}
                <input
                  value={homecity}
                  onChange={(e) => sethomecity(e.target.value)}
                  type="text"
                  id="email"
                  placeholder="Enter Home City"
                  className="form-input"
                />
              </div>
              <div className="form-group">
                {/* <label htmlFor="gender">Gender</label> */}
                <select
                  id="gender"
                  value={gender}
                  onChange={(e) => setgender(e.target.value)}
                  className="form-input"
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group text-center ">
                <button type="submit" className="submit-btn">
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}
