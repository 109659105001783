import React, { useEffect, useState } from 'react'
import './damagedetails.css'
import ApiService from '../../Api/ApiController';
import { useNavigate, useParams } from 'react-router-dom';
export default function DamageDetails() {

  const [orderdetail,setorderdetails] = useState([]);
  const orderId = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    ApiService.GetBookingdetail({
      booking_id: orderId,
    })
      .then((response) => {
        if (response.data.status === "success") {
          setorderdetails(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };
  const handledetails = (orderId) =>{
    navigate(`/damagepay/${orderId}`)
  }
  return (
    <div>
    <div>
      <section className='order-details'>
          <div>
          <h4>Order Details</h4>

          </div>

          {/* ================================================= */}
          <div>
          <div className="booking-list">
     <div className="ongoing-container">
       <div className="ongoing-description">
         <div className="ongoing-description1">Description:</div>
         <div className="ongoing-description2"
           dangerouslySetInnerHTML={{
            __html: orderdetail.description,
          }}
         >
       
         </div>
       </div>
       <div className="ongoing-order">
         <div className="ongoing-order1">Order ID:</div>
         <div className="ongoing-order2">{orderdetail.order_id}</div>
       </div>
       <div className="ongoing-arriving">
         <div className="ongoing-arriving1">Arriving</div>
         <div className="ongoing-arriving2">{orderdetail.arriving_date}</div>
       </div>
       <div className="ongoing-total">
         <div className="ongoing-total1">Total Price</div>
         <div className="ongoing-total2">{orderdetail.total_order_price}</div>
       </div>
     </div>
   </div>
          </div>
    {/* ================================================= */}
    <section>
    <div className="booking-list">
     <div className="ongoing-container">
       <div className="ongoing-shippingaddress">
         <div className="ongoing-shippingaddress1">Shipping Address:</div>
         <div className="ongoing-shippingaddress2"
          dangerouslySetInnerHTML={{
            __html: orderdetail.shipping_address,
          }}
         >
        
         </div>
       </div>
       <div className="ongoing-estimateddelivery">
         <div className="ongoing-estimateddelivery1">Estimated Delivery:</div>
         <div className="ongoing-estimateddelivery2">{formatDate(orderdetail.start_date)}</div>
       </div>
       <div className="ongoing-enddate">
         <div className="ongoing-enddate1">End Date:</div>
         <div className="ongoing-enddate2">{formatDate(orderdetail.end_date)}</div>
       </div>
       <div className="ongoing-timeslot">
         <div className="ongoing-timeslot1">Time Slot</div>
         <div className="ongoing-timeslot2">{orderdetail.time_duration}</div>
       </div>
     </div>
   </div>
    </section>
    {/* ================================================= */}
    <section>
    <div className="booking-list">
     <div className="ongoing-container">
       <div className="ongoing-items">
         <div className="ongoing-items1">Items:</div>
         <div className="ongoing-items2">
         {parseFloat(orderdetail.final_item_price)}
         </div>
       </div>
       <div className="ongoing-order">
         <div className="ongoing-order1">Delivery Charge:</div>
         <div className="ongoing-order2">{orderdetail.delivery_charge}</div>
       </div>
       <div className="ongoing-arriving">
         <div className="ongoing-arriving1">Remaining Amount:</div>
         <div className="ongoing-arriving2">{orderdetail.pending_amount}</div>
       </div>
     
     </div>
   </div>
    </section>
    {/* ================================================= */}

    <section>
      <div className='order-details-extend'>
          <button onClick={()=>handledetails(orderdetail.order_id)} className='damage-order-btn'>Pay for damage</button>
      </div>
    </section>
      </section>
    </div>
  </div>
  )
}
