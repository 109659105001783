import React, { createContext, useState, useContext } from 'react';

// Create a context
const TimeSlotContext = createContext();

// Create a provider component
export const TimeSlotProvider = ({ children }) => {
  const [selectedTimeSlot, setSelectedTimeSlot] = useState('');
  const [sdate,setsdate] = useState('');
  const [edate,setedate] = useState('');
  const [userId,setUserId] = useState('');
  const[days,setdays] = useState('');
  const [pinlocation,setpinlocation] =  useState('')
  
  return (
    <TimeSlotContext.Provider value={{ selectedTimeSlot, setSelectedTimeSlot,sdate,setsdate,edate,setedate,userId,setUserId,days,setdays,pinlocation,setpinlocation }}>
      {children}
    </TimeSlotContext.Provider>
  );
};

// Custom hook to use the time slot context
export const useTimeSlot = () => useContext(TimeSlotContext);
