import React, { useEffect, useState } from "react";
import "./signup.css"; // Import your CSS file for styling
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import ApiService from "../../../Api/ApiController";
import Swal from "sweetalert2";
import { NavLink, useNavigate } from "react-router-dom";
import brobo from '../../../header/brobo.png'
import InputMask from "react-input-mask";
export default function SignUp() {
  const [currentAddress, setCurrentAddress] = useState("");
  //   const [showLocationButton, setShowLocationButton] = useState(false);
  //   const [showLocationModal, setShowLocationModal] = useState(false);
  const [isLoading, setisLoading] = useState(false);
  const [name, setname] = useState("");
  const [mobilenumber, setmobilenumber] = useState("");
  const [password, setPassword] = useState("");
  const [referredCode, setReferredCode] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [Error, setError] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const navigate = useNavigate()
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmpasswordVisible, setconfirmPasswordVisible] = useState(false);
  const [showotpinput,setshowotpinput] =  useState(false);
  const [OTP,SetOTP] = useState('');
  const [countDown,setcountDown] =  useState(30);
  const [Isresending,setIsResending] =  useState(false);
  const [homecity,sethomecity] =  useState('')
  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };
  const toggleconfirmPasswordVisibility = () => {
    setconfirmPasswordVisible(!confirmpasswordVisible);
  };

  const handleSignUp = async (event) => {
    event.preventDefault();
    setisLoading(true)
    try {
        if (!isChecked) {
            setError("Please agree to the terms and conditions.");
            return;
          }
      // Call the sign up API
      if (password !== confirmPassword) {
        setError("Passwords do not match");
        return;
      }
      const response = await ApiService.signup({
        name,
        mobile_number: mobilenumber,
        password,
        address: currentAddress,
        referred_code: referredCode,
        fcm_token:'er',
        otp:OTP,
        home_city:homecity
      });


      if (response.data.status === "success") {
        setname("");
        setmobilenumber("");
        setPassword("");
        setReferredCode("");
        setCurrentAddress("");
        setError(null);
        
        Swal.fire({
            icon: "success",
            title: "Sign Up Successful",
            text: response.data.message,
          });
          navigate('/login')
      } else {
        setError(response.data.message); 
      }
    } catch (error) {
      console.error("Sign up failed:", error);
      setError(error.response.data.message);
    }finally{
      setisLoading(false)
    }
  };

  const VerifySignupOTp = ()=>{
    ApiService.SendSignupOtp({
      mobile_number:mobilenumber
    }).then((response)=>{
                if(response.data.status === 'success'){
                  Swal.fire({
                    icon: 'success',
                 
                    text: response.data.message,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  handleShowOTPinput()
                  setIsResending(true)
                }else{
                  Swal.fire({
                    icon: 'error',
                  
                    text: response.data.message,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500
                  });
                }
    }).catch((err)=>{
    console.log(err)
    })
  }

  const ResendOtp =()=>{
    ApiService.SendSignupOtp({
      mobile_number:mobilenumber
    }).then((response)=>{
                if(response.data.status === 'success'){
                  Swal.fire({
                    icon: 'success',
                 
                    text: response.data.message,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 2000
                  });
                  
                  setIsResending(true)
                }else{
                  Swal.fire({
                    icon: 'error',
                  
                    text: response.data.message,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 1500
                  });
                }
    }).catch((err)=>{
    console.log(err)
    })
  }


  const handleShowOTPinput = ()=>{
    
    setshowotpinput(!showotpinput)
  }
  
  useEffect(() => {
    let interval;
    if (Isresending && countDown > 0) {
      interval = setInterval(() => {
        setcountDown((prevCount) => prevCount - 1);
      }, 1000);
    }
    
    // Cleanup function
    return () => clearInterval(interval);
  }, [Isresending, countDown]);
  
  // useEffect to handle countdown completion and resetting flags
  useEffect(() => {
    if (countDown === 0) {
      
      setIsResending(false);
      setcountDown(30); 
    }
  }, [countDown]);
  
 
  return (
    <div>
      <div className="signup-container">

      <section className="signup-section">
        <div className="signup-form-container">
          <div className="signup-image">
        <img src={brobo} alt="" />
          </div>
          <form className="signup-form" onSubmit={handleSignUp}>
            <div className="form-group">
              <label htmlFor="name" className="form-label">
                Name:
              </label>
              <input
                type="text"
                id="name"
                name="name"
                value={name}
                onChange={(e) => setname(e.target.value)}
                placeholder="Enter Your Name"
                className="form-input"
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="mobile_number" className="form-label">
                Mobile Number:
              </label>
              {/* <input type="tel" id="mobile_number" name="mobile_number" placeholder="Enter Mobile Number" className="form-input" required /> */}
              <div className="mobilenumberforotpnew">
              <InputMask
      mask="+\9\19999999999"
      placeholder="Enter mobile number"
      value={mobilenumber}
      onChange={(e) => setmobilenumber(e.target.value)}
      className="form-input3"
      />
    <button  onClick={VerifySignupOTp}>Send OTP</button>
      </div>
      {showotpinput === true &&
      
      <div className="form-group">
      <label htmlFor="mobile_number" className="form-label">
        OTP:
        </label>
        <input
  type="text"
  value={OTP}
  onChange={(e) => {
    const input = e.target.value;
    // Allow only numbers (0-9) and prevent entering any other characters
    const onlyNumbers = input.replace(/[^0-9]/g, '');
    // Limit the input to four characters
    const truncatedInput = onlyNumbers.slice(0, 4);
    SetOTP(truncatedInput);
  }}
  inputMode="numeric"
  maxLength={4}
  placeholder="Enter 4 Digit OTP"
  className="form-input"
/>
{countDown < 30 && (
  <p>Resend OTP in {countDown}</p>
)}

{countDown === 30 && !Isresending && (
  <div className="resend-otp-2" onClick={ResendOtp}>Resend OTP</div>
)}
      </div>
      }
            </div>
            <div className="form-group">
            <label htmlFor="mobile_number" className="form-label">
                Password:
              </label>
    <div className="input-with-icon">
        <input 
            type={passwordVisible ? 'text' : 'password'} 
            value={password} 
            onChange={(e) => setPassword(e.target.value)} 
            className="form-input" 
            placeholder="New Password" 
            required 
        />
                      <svg viewBox="0 0 576 512" height="20" xmlns="http://www.w3.org/2000/svg" className="eye-icon" onClick={togglePasswordVisibility}>
  {passwordVisible ? (
    <path d="M288 480c-80.8 0-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6c46.8-43.5 111.5-80.6 192.6-80.6s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480zm0-368c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm0 0"></path>
  ) : (
    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
  )}
</svg>
    </div>
</div>
            {/* <div className="form-group">
              <label htmlFor="confirm_password" className="form-label">
                Confirm Password:
              </label>
              <input
                type="password"
                id="confirm_password"
                name="confirm_password"
                className="form-input"
                placeholder="Confirm Password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
                />
            </div> */}
             <div className="form-group">
            <label htmlFor="mobile_number" className="form-label">
               Confirm Password:
              </label>
    <div className="input-with-icon">
        <input 
            type={confirmpasswordVisible ? 'text' : 'password'} 
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            className="form-input" 
            placeholder="Confirm Password" 
            required 
        />
                       <svg viewBox="0 0 576 512" height="20" xmlns="http://www.w3.org/2000/svg" className="eye-icon" onClick={toggleconfirmPasswordVisibility}>
  {confirmpasswordVisible ? (
    <path d="M288 480c-80.8 0-145.5-36.8-192.6-80.6C48.6 356 17.3 304 2.5 268.3c-3.3-7.9-3.3-16.7 0-24.6C17.3 208 48.6 156 95.4 112.6c46.8-43.5 111.5-80.6 192.6-80.6s145.5 36.8 192.6 80.6c46.8 43.5 78.1 95.4 93 131.1c3.3 7.9 3.3 16.7 0 24.6c-14.9 35.7-46.2 87.7-93 131.1C433.5 443.2 368.8 480 288 480zm0-368c-8.8 0-16 7.2-16 16v80c0 8.8 7.2 16 16 16s16-7.2 16-16v-80c0-8.8-7.2-16-16-16zm0 0"></path>
  ) : (
    <path d="M288 32c-80.8 0-145.5 36.8-192.6 80.6C48.6 156 17.3 208 2.5 243.7c-3.3 7.9-3.3 16.7 0 24.6C17.3 304 48.6 356 95.4 399.4C142.5 443.2 207.2 480 288 480s145.5-36.8 192.6-80.6c46.8-43.5 78.1-95.4 93-131.1c3.3-7.9 3.3-16.7 0-24.6c-14.9-35.7-46.2-87.7-93-131.1C433.5 68.8 368.8 32 288 32zM144 256a144 144 0 1 1 288 0 144 144 0 1 1 -288 0zm144-64c0 35.3-28.7 64-64 64c-7.1 0-13.9-1.2-20.3-3.3c-5.5-1.8-11.9 1.6-11.7 7.4c.3 6.9 1.3 13.8 3.2 20.7c13.7 51.2 66.4 81.6 117.6 67.9s81.6-66.4 67.9-117.6c-11.1-41.5-47.8-69.4-88.6-71.1c-5.8-.2-9.2 6.1-7.4 11.7c2.1 6.4 3.3 13.2 3.3 20.3z"></path>
  )}
</svg>
    </div>
</div>
            {/* <div className="form-group address-fetch">
                            <label htmlFor="address" className="form-label">Address:</label>
                            <input type="text" id="address" value={currentAddress} name="address" className="form-input" readOnly onClick={handleAddressClick} />
                            {showLocationButton && (
                              <div className="location-buttons">
                              <button
                              type="button"
                              className="btn btn-outline-dark "
                              onClick={getLocation}
                              >
                              <i className='bx bx-current-location'></i> Current Location
                              </button>
                              <button onClick={closelocationmodel} type="button" className="btn btn-close "></button>
                              <button
                                        type="button"
                                        className="btn btn-outline-dark"
                                        onClick={handleSearchButtonClick}
                                        >
                                        <i className='bx bx-search'></i> Search
                                        </button>
                                        </div>
                                        )}
                                        {showLocationModal && <LocationSelectorModal onMapSelection={handleMapSelection} />}
                        </div> */}
            <div className="form-group">
              <label htmlFor="referred_code" className="form-label">
                Home City:
              </label>
              <input
                type="text"
                id="referred_code"
                name="referred_code"
                className="form-input"
                value={homecity}
                onChange={(e=> sethomecity(e.target.value))}
                placeholder="Enter City"
                />
            </div>
            <div className="form-group">
              <label htmlFor="referred_code" className="form-label">
                Referred Code:
              </label>
              <input
                type="text"
                id="referred_code"
                name="referred_code"
                className="form-input"
                value={referredCode}
                onChange={(e=> setReferredCode(e.target.value))}
                placeholder="Enter Referal Code(Optional)"
                />
            </div>
            <div className="form-group">
              <label>
                <input
                  type="checkbox"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                  />
                <span>I agree to the <NavLink to='/terms-condition'>terms and conditions</NavLink> and <NavLink to="/privacy-policy">privacy policy</NavLink> of Brobo</span>
              </label>
            </div>
            {Error && <div className="error-message">{Error}</div>}
            <button type="submit" className="submit-btn submit_loader" disabled={isLoading}>
            {isLoading ? (
                <div className="button_loader"></div>
              ) : (
                'Submit'
              )}
              {/* Sign Up */}
            </button>
          </form>
          Already Have An Account? <NavLink to="/login">Login</NavLink>
        </div>
      </section>
  </div>
    </div>
  );
}

// const LocationSelectorModal = ({ onMapSelection }) => {
//   const [selectedLocation, setSelectedLocation] = useState("");

//   useEffect(() => {
//       const google = window.google;
//       const mapOptions = {
//           center: { lat: 0, lng: 0 }, // Default center
//       zoom: 8, // Default zoom level
//     };
//     const mapElement = document.getElementById("map");
//     const map = new google.maps.Map(mapElement, mapOptions);

//     const searchBox = new google.maps.places.SearchBox(
//       document.getElementById("search-input")
//     );
//     map.controls[google.maps.ControlPosition.TOP_LEFT].push(
//         document.getElementById("search-input-container")
//       );
  
//       map.addListener("bounds_changed", () => {
//           searchBox.setBounds(map.getBounds());
//     });

//     searchBox.addListener("places_changed", () => {
//       const places = searchBox.getPlaces();

//       if (places.length === 0) {
//         return;
//       }

//       const place = places[0];

//       if (!place.geometry) {
//         console.error("Returned place contains no geometry");
//         return;
//       }

//       const formattedAddress = place.formatted_address;
//       setSelectedLocation(formattedAddress);
//     });
//   }, []);

//   const handleConfirmLocation = () => {
//     onMapSelection(selectedLocation);
//   };

//   return (
//     <div className="modal1">
//       <div className="modal-content1">
//         <div id="search-input-container">
//           <input
//             id="search-input"
//             type="text"
//             placeholder="Search for location"
//             className="form-input"
//           />
//           <button className="btn btn-outline-dark " id="search-button">
//             Search
//           </button>
//         </div>
//         <div id="map" style={{ width: "100%", height: "300px" }}></div>
//         <button
//           className="btn btn-outline-dark"
//           onClick={handleConfirmLocation}
//         >
//           Confirm Location
//         </button>
//       </div>
//     </div>
//   );
// };
