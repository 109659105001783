import axios from "axios";

const ApiBaseUrl = 'https://cp.brobo.in/api/';

const token = sessionStorage.getItem('authtoken')
const Api = axios.create({
    baseURL: ApiBaseUrl,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        Authorization: `Bearer ${token}`
    },
});

const ApiService = {
    getSettingData: (key) => Api.get('get-setting-data',{params:{key}}),
    getAllCoupons: () => Api.get('get-all-coupons-for-web'),
    getallBanners: ()=>  Api.get('get-all-banners-for-web'),
    signup: (userData)=> Api.post('auth/register',userData),
    login: (userData)=> Api.post('auth/login',userData),
    getallpopularservices:() => Api.get('get-popular-services-for-web'),
    getfastmovingitems:()=>Api.get('get-most-ordered-products-for-web'),
    getsubCategories: (categoryId)=>Api.get('get-all-subcategories-for-web', { params: { category_id: categoryId } }),
    getItems : (params) => Api.get('get-product-list-for-web', { params }),
    getItemDetail : (productId) =>Api.get('product-detail-for-web',{params:{item_id:productId}}),
    getCartDetails : ()=> Api.get('get-cart-items'),
    manageAddress : (userData) => Api.post('manage-delivery-address',userData),
    addtocart : (cartData) => Api.post('add-item-in-cart',cartData),
    updateprofile : (userData) => Api.put('/auth/update-profile',userData),
    getaddressdetails : () => Api.get('get-delivery-address'),
    getprofiledetails : () => Api.get('auth/view-profile'),
    addtowishlist : (wishdata)=> Api.post('manage-item-in-whishlist',wishdata),
    getWishlist : (params) => Api.get('get-item-in-whishlist',{params}),
    deletecartitem : (cartData) => Api.delete('delete-cart-item',{data: cartData}),
    forgetpassword : (userData) => Api.post('auth/forget-password',userData),
    VerifyOtp :  (otpData)  => Api.post('auth/verify-otp',otpData),
    ResetPassword : (userData) => Api.put('auth/reset-password',userData),
    LocationCheck : (locationData) => Api.post('match-location-data',locationData),
    getOrderSetting : () => Api.get('get-order-settings-for-web'),
    managecartitemquantity : (cartData)=> Api.put('manage-cart-item-quantity',cartData),
    coupondetails : (CouponCode) => Api.get('get-coupon-detail',{params:{code:CouponCode}}),
    BookItem : (Bookitem) => Api.post('book-items',Bookitem),
    GetBookings : (params) => Api.get('get-bookings',{params}),
    GetBookingdetail : (params) => Api.get('get-booking-detail',{params}),
    extendOrder : (extendOrder) => Api.put('extend-order',extendOrder),
    cancelOrder : (bookingId) => Api.put('cancel-order',bookingId),
    paymentgatewaykey : (params) => Api.get('get-payment-keys',{params}),
    payfordamage : (damagepay) => Api.put('pay-for-damage',damagepay),
    notification : (params) => Api.get('get-notifications',{params}),
    notificationcount : ()=> Api.get('get-unread-notification-count'),
    Logout : (userdata)=> Api.post('auth/logout',userdata),
    FAQs : () => Api.get('get-faq-data'),
    getBuissnessSetting : (key) => Api.get('get-business-settings',{params:{key}}),
    PayForDueAmount : (Data)  => Api.put('pay-for-due-amount',Data),
    Refferaldiscount : ()  => Api.get('get-referral-discount'),
    GetAllProducts : (params) => Api.get('get-all-product-list-for-web',{params}),
    RecommendedProductList : (params) => Api.get('get-product-recommendation-list-for-web',{params}),
    DeleteAccount : (Userid) => Api.delete(`/auth/delete-account/${Userid}`),
    VerifyPassword : (password) =>  Api.post('/auth/verify-password',password),
    UpdateNotificationSetting : (notification) => Api.put('update-notification-setting',notification),
    SendSignupOtp: (Otpsignup) => Api.post('/auth/send-signup-otp',Otpsignup),
    ResendOTP : (resendotp) => Api.post('/auth/resend-otp',resendotp),
    CartCount : () =>  Api.get('get-cart-items-count'),
    Ordertimeslots : (params) => Api.get('get-time-slots-for-web',{params}),
};

export default ApiService;
