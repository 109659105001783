import React, { useEffect, useState } from 'react'
import './MyProfile.css'; 
import ApiService from '../../../Api/ApiController';
import { useNavigate } from 'react-router-dom';
export default function MyProfile() {
const[profile,setprofile] = useState([]);
const navigate = useNavigate()
    useEffect(()=>{
       ApiService.getprofiledetails().then((response)=>{
        if(response.data.status === 'success'){
            setprofile(response.data.data)
            console.log(response.data.data)
        }else{
            console.log(response.data)
        }
       })
    },[])

    const Edit = ()=>{
        navigate('/personaldetails')
    }
  return (
    <div>
      <section>
      <div className="profile-container">
       <div className='profile-edit-button'>
        <button onClick={Edit}><i class='bx bx-edit-alt'></i></button>
       </div>
      <div className="profile-header">
        {/* <img className="profile-picture" src="profile-picture.jpg" alt="Profile Picture" /> */}
        <div className="profile-info">
          <div className="profile-name">{profile.name}</div>
          <div className="profile-bio">{profile.gender}</div>
        </div>
      </div>
      <div className="profile-details">
        <div className="profile-section">
          <h2>Contact Information</h2>
          <div className="info-item">
            <label>Email:</label> <span>{profile.email}</span>
          </div>
          <div className="info-item">
            <label>Mobile Number:</label> <span>{profile.mobile_number}</span>
            <label>Home city:</label> <span>{profile.home_city}</span>
          </div>
        </div>
        {/* <div className="profile-section">
          <h2>Interests</h2>
          <div className="info-item">
            <span>{profile.interest}</span>
          </div>
        </div> */}
        {/* Add more sections as needed */}
      </div>
    </div>
      </section>
    </div>
  )
}

