import './App.css';

import {HashRouter} from 'react-router-dom';


import Main from './Main';
import ReactGA from "react-ga4";
ReactGA.initialize("G-WQ6WQVYZDZ");
ReactGA.send({

    page: window.location.hash + window.location.search
 });
function App() {
  document.addEventListener('contextmenu', function (e) {
    e.preventDefault();
    
});

  return (
    <div>
      <HashRouter>
            <Main/>
     </HashRouter>
    </div>
  );
}

export default App;

// import './App.css';
// import { useState } from 'react';
// import { BrowserRouter, HashRouter } from 'react-router-dom';
// import Main from './Main';
// import CustomTooltip from './CustomToolTip';

// function App() {
//   const [tooltipVisible, setTooltipVisible] = useState(false);
//   const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

//   const handleContextMenu = (e) => {
//     e.preventDefault();
//     setTooltipVisible(true);
//     setTooltipPosition({ x: e.clientX, y: e.clientY });
//   };

//   const handleHideTooltip = () => {
//     setTooltipVisible(false);
//   };

//   return (
//     <div onContextMenu={handleContextMenu}>
//       <HashRouter>
//         <Main />
//       </HashRouter>
//       <CustomTooltip
//         x={tooltipPosition.x}
//         y={tooltipPosition.y}
//         visible={tooltipVisible}
//         onHide={handleHideTooltip}
//       />
//     </div>
//   );
// }

// export default App;
