import React, { useEffect, useState } from "react";
import "./setting.css";
import { NavLink } from "react-router-dom";
import ApiService from "../../Api/ApiController";
import Swal from "sweetalert2";

export default function Setting() {
  const userid = localStorage.getItem('userId');
  const [notification, setNotification] = useState(false);

  const updateNotification = () => {
    ApiService.UpdateNotificationSetting({
      is_notification_setting_on: notification ? "no" : "yes"
    }).then((response) => {
      if (response.data.status === 'success') {
        Swal.fire({
          icon: 'success',
          text: response.data.message
        });
      } else {
        console.error(response.data);
      }
    }).catch((err) => {
      console.log(err);
    });
  };
useEffect(()=>{
 ApiService.getprofiledetails().then((response)=>{
                if(response.data.status === "success"){
       
                  setNotification(response.data.data.is_notification_setting_on)
                }
 }).catch((err)=>{
  console.log(err)
 })
},[])
  const handleLogout = () => {
    Swal.fire({
      icon: 'warning',
      title: 'Logout',
      html: 'Are you sure you want to logout?',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel',
      showLoaderOnConfirm: true,
      preConfirm: async () => {
        try {
          const response = await ApiService.Logout({ customer_id: userid });
          if (response.data.status === 'success') {
            Swal.fire({
              icon: 'success',
              text: "You logged Out Successfully"
            });
            sessionStorage.removeItem('authtoken');
            window.location.reload();
          }
        } catch (error) {
          console.log(error);
        }
      }
    });
  };

  return (
    <div>
      <section>
        <div className="notification-setting-main">
          <div className="notification-setting">
            <i className="bx bx-bell"></i>
            <span>Notifications</span>
          </div>
          <div>
            <label className="switch">
              <input
                type="checkbox"
                checked={notification}
                onChange={(e) => setNotification(e.target.checked)}
                onClick={updateNotification}
                className="input__check"
              />
              <span className="slider"></span>
            </label>
          </div>
        </div>
      </section>

      <section>
        <div className="deleteaccount-setting-main">
          <NavLink to='/deleteaccount' className="deleteaccount-setting">
            <i className='bx bx-trash'></i>
            <span>Delete Account?</span>
          </NavLink>
        </div>
      </section>

      <section>
        <div onClick={handleLogout} className="deleteaccount-setting-main">
          <div className="deleteaccount-setting">
            <i className='bx bx-log-out-circle'></i>
            <span>Logout</span>
          </div>
        </div>
      </section>

      <section>
        <div className="deleteaccount-setting-main">
          <NavLink to="/resetpassword" className="deleteaccount-setting">
            <i className='bx bx-lock-alt'></i>
            <span>Password</span>
          </NavLink>
        </div>
      </section>
    </div>
  );
}
