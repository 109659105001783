import React, { useEffect, useState } from "react";
import "./deliveryaddress.css";
import ApiService from "../../Api/ApiController";
import Swal from "sweetalert2";

export default function DeliveryAddress() {
  const [housename, setHouseName] = useState("");
  const [floornumber, setFloorNumber] = useState("");
  const [landmark, setLandmark] = useState("");
  const [zipcode, setZipCode] = useState("");
  const [areaname, setAreaName] = useState("");
  const [addresstype, setAddressType] = useState("");
  const [addresses, setAddresses] = useState([]);

  const [editMode, setEditMode] = useState(null);
  const [editedAddress, setEditedAddress] = useState({});


  const handleSaveClick = (e) => {
    e.preventDefault()

    ApiService.manageAddress({
      
      house_name: housename,
      floor_number: floornumber,
      landmark: landmark,
      zip_code: zipcode,
      area_name: areaname,
      address_type: addresstype,
      address_id: editedAddress.address_id
    }).then((response) => {
      if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Address Updated",
          text: response.data.message,
        })
        console.log(response)
        fetchAddresses();
      } else {
        Swal.fire({
          icon: 'error',
          title: "Update Failed",
          text: response.data.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500,
        
        });
        console.log(response)
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const handleCancelClick = () => {
    setEditMode(null);
    setEditedAddress({});
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (editMode !== null) {
      handleSaveClick();
      return;
    }

    ApiService.manageAddress({
      house_name: housename,
      floor_number: floornumber,
      landmark: landmark,
      zip_code: zipcode,
      area_name: areaname,
      address_type: addresstype
    }).then((response) => {
      if (response.data.status === "success") {
        Swal.fire({
          icon: "success",
          title: "Address Added",
          text: response.data.message,
        }).then(() => {
          // Refresh the address list after successful addition
          fetchAddresses();
        });
      } else {
        Swal.fire({
          icon: 'error',
          title: "Addition Failed",
          text: response.data.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500
        });
      }
    }).catch((error) => {
      console.log(error);
    });
  };

  const fetchAddresses = () => {
    ApiService.getaddressdetails()
      .then((response) => {
        if(response.data.status === 'success'){
          setAddresses(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    fetchAddresses();
  }, []);
  const handleEditClick = (address) => {
    setEditMode(address);
    setEditedAddress(address);
  

    setHouseName(address.house_name);
    setFloorNumber(address.floor_number);
    setLandmark(address.landmark);
    setZipCode(address.zip_code);
    setAreaName(address.area_name);
    setAddressType(address.address_type);
  //   const formElement = document.querySelector('.delivery-address-form');
  // formElement.scrollIntoView({ behavior: 'smooth' });
  window.scrollTo({
    top: 0,
    behavior: "auto"
  });
  };
  
  return (
    <div>
      <div className="delivery-address-container">
        <h1>Manage Address</h1>
        <form className="delivery-address-form" >
          <div className="delivery-address-dropdown">
            <select
              name="address-type"
              id="address-type"
              value={addresstype}
              onChange={(e) => setAddressType(e.target.value)}
            >
              <option value="" disabled>Select Address Type</option>
              <option value="Home">Home</option>
              <option value="Work">Work</option>
              <option value="Other">Others</option>
            </select>
          </div>

          <div className="delivery-address-field">
            <label htmlFor="houseApartment">House/Apartment</label>
            <input
              value={housename}
              onChange={(e) => setHouseName(e.target.value)}
              type="text"
              id="houseApartment"
            />
          </div>
          <div className="delivery-address-field">
            <label htmlFor="floor">Floor</label>
            <input
              value={floornumber}
              onChange={(e) => setFloorNumber(e.target.value)}
              type="number"
              id="floor"
            />
          </div>
          <div className="delivery-address-field">
            <label htmlFor="landmark">Landmark</label>
            <input
              value={landmark}
              onChange={(e) => setLandmark(e.target.value)}
              type="text"
              id="landmark"
            />
          </div>
          <div className="delivery-address-field">
            <label htmlFor="area">Area</label>
            <input
              value={areaname}
              onChange={(e) => setAreaName(e.target.value)}
              type="text"
              id="area"
            />
          </div>

          <div className="delivery-address-field">
            <label htmlFor="pinCode">Pin Code</label>
            <input
              value={zipcode}
              onChange={(e) => setZipCode(e.target.value)}
              type="number"
              id="pinCode"
              min="0"
            />
          </div>
          <div className="delivery-address-button">
            
              {editMode !== null ? <button onClick={(e)=>handleSaveClick(e)} type="submit">Update</button> :<button onClick={handleSubmit}  type="submit">Save</button>}
            
          </div>
        </form>
      </div>

      <section className="address-list">
        {addresses.map(address => (
          <div key={address.id} className="address-item">
         
             
              <div className="display-mode">
                <div><h6>House Name:</h6>{address.house_name}</div>
                <div><h6>Floor Number:</h6>{address.floor_number}</div>
                <div><h6>Landmark:</h6>{address.landmark}</div>
                <div><h6>Area Name</h6>{address.area_name}</div>
                <div><h6>Address Type</h6>{address.address_type}</div>
                <div><h6>Pin Code</h6>{address.zip_code}</div>
                <button onClick={() => handleEditClick(address)}><i class='bx bx-edit-alt'></i></button>
              </div>
            
          </div>
        ))}
      </section>
    </div>
  );
}
