import React, { useEffect, useState } from 'react'
import ApiService from '../../Api/ApiController';
import Loader from '../../loader/Loader';

export default function ShippingPolicy() {
const [replacementpolicy,setreplacementpolicy] = useState([]);
const apiKey = 'replacement_policy';

    useEffect(() => {
        ApiService.getSettingData(apiKey)
          .then((response) => {
            console.log("Setting data:", response.data.data);
            setreplacementpolicy(response.data.data);
          })
          .catch((error) => {
            console.error("Error fetching setting data:", error);
          });
      }, []);
      
      if (replacementpolicy.length === 0) { // Check if replacementpolicy is null
        return (
          <div style={{ backgroundColor: "white", width: '100vw', height: '100vh' }}>
            <Loader />
          </div>
        );
      }
  return (
    <div>
      <div>
        <section>
          <div className="terms-condition">
            <div className="terms-condition-heading">
              <h1>Replacement Policy</h1>
            </div>
            <div className="terms-condition-paragraph">
              <p dangerouslySetInnerHTML={{ __html: replacementpolicy.value }} />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
