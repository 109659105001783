import React, { useEffect, useState } from 'react'
import ApiService from '../../Api/ApiController';

export default function PrivacyPolicy() {
const [aboutus,setaboutus]= useState([])
    const apiKey = 'about_us';

    useEffect(()=>{

      ApiService.getSettingData(apiKey).then(response =>{
          
          setaboutus(response.data.data)
      }).catch(error =>{
          console.error(error);
      })
    },[])
  return (
    <div>
      <div>
      <section>
          <div className="terms-condition">
            <div className="terms-condition-heading">
              <h1>About Brobo</h1>
            </div>
            <div className="terms-condition-paragraph">
              <p dangerouslySetInnerHTML={{ __html: aboutus.value }} />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
