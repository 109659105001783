
import React, { useEffect, useState } from 'react';
import './productdetails.css';
import ApiService from '../../Api/ApiController';
import { json, useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
// import { MapInteractionCSS } from 'react-map-interaction';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import img from '../homepage/tent1.jpeg'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'
import ReactGA from "react-ga4";
import Loader from '../../loader/Loader';
export default function ProductDetails() {
    const [quantity, setQuantity] = useState(1);
    const [currentColorIndex, setCurrentColorIndex] = useState();
    const [currentImageIndex, setCurrentImageIndex] = useState();
    const [product, setProduct] = useState(null);
    const [Recommend,setRecommend] =  useState([])
    const productId = useParams();
    const [categoryId ,setcategoryId ] = useState(null);
    const token = sessionStorage.getItem('authtoken');
    const [Availability,setAvailabilty] = useState(false)
    const navigate =  useNavigate()
    
    useEffect(() => {
        ApiService.getItemDetail(productId)
            .then((response) => {
                setProduct(response.data.data);
                console.log(response.data.data)
            })
            .catch((error) => {
                console.error(error.data);
            });
    }, [productId]);
    
    useEffect(() => {
        if (product !== null) {
            const array = JSON.parse(product.category_ids);
            if (array !== null && array.length > 1) {
                setcategoryId(array[1].id);
                console.log(array[1].id)
            } else {
                console.log("Data or category_ids is null, or array length is less than 2.");
            }
        } else {
            console.log("Product is null.");
        }
    }, [product]);
    
    const toggleavailable = ()=>{
        setAvailabilty(!Availability)
 
    }
 const Recommendlist = async () =>{

    await ApiService.RecommendedProductList({
        category_id: categoryId,
      product_id : productId
    }).then((response)=>{
          if(response.data.status === 'success'){
            console.log(response.data.data)
            setRecommend(response.data.data)
          }else{
           console.error(response);
          }
    }).catch((err)=>{
        console.log(err)
    })
 }
 useEffect(()=>{
    if (categoryId !== null && productId !== null) {
        Recommendlist();
      }
 },[categoryId,productId])
    const handleIncrease = () => {
        if (quantity < product.total_stock) {
           
            setQuantity(quantity + 1);
          } else {
            Swal.fire({
                icon:'warning',
                text:"it's Out of Stock"
            })
          }
    };

    const handleDecrease = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const handleImageClick = (imageIndex) => {
        setCurrentImageIndex(imageIndex);
    };

    const handleColorClick = (colorIndex) => {
        setCurrentColorIndex(colorIndex);
        setCurrentImageIndex(0); // Reset current image index when color changes
    };


const AddtoCart = async (e, colorImageId, productId) => {
    e.preventDefault();
    ReactGA.event({
        hitType: 'event',
        category: 'Ecommerce',
        action: 'add_to_cart',
        label: product.name, // Use the product name or any other relevant label
        value: quantity // Use the quantity as the value if needed
    });
 console.log(quantity)
    if(quantity === 0){
        Swal.fire({
            icon: 'warning',
            text: "Cannot add 0 quantity"
        });
    }else{
    if (token) {

            if (product.total_stock <= "0") {
                Swal.fire({
                icon: 'warning',
                text: "Currently it’s out of stock"
            });
        } else {
            try {
                const response = await ApiService.addtocart({
                    item_id: productId,
                    item_color_image_id: colorImageId,
                    quantity: quantity
                });

                if (response.data.status === 'success') {
                 
                    Swal.fire({
                        icon: 'success',
                        title: 'Cart',
                        text: response.data.message,
                        timerProgressBar: true,
                        timer: 2000
                    });
                    window.location.reload();
                }
            } catch (error) {
                console.log(error);
            }
        }
    } else {
        // Generate a unique key for each item
        const itemKey = `cartitem_${productId}`;
        
        // Retrieve existing cart items from localStorage
        const existingItems = JSON.parse(localStorage.getItem('cartitems')) || {};
        
        // Add the new item to the existing items
        existingItems[itemKey] = {
            item_id: productId,
            item_color_image_id: colorImageId,
            quantity: quantity,
            product // Assuming product is defined elsewhere
        };
        
        // Store the updated cart items back into localStorage
        localStorage.setItem('cartitems', JSON.stringify(existingItems));
        Swal.fire({
            icon: 'success',
            title: 'Cart',
            text: 'Cart Item Added Successfully'
        });
    }
}
};

    if (!product) {
        return <div style={{backgroundColor:"white",width:'100vw',height:'100vh'}}><Loader/></div>;
    }
    const settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 5,
        slidesToScroll: 5, // Updated slidesToScroll value
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true
            }
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2
            }
          }
        ]
      };
      const handledetails = (productId) => {
        navigate(`/productdetail/${productId}`);
      };
      
    return (
        <div>

        <div className="product-details">
            <div className="product-details-container">
                <div className="image-container">
                    <Zoom>
                        <img
                            src={
                                currentColorIndex !== undefined
                                    ? product.colored_images[currentColorIndex]?.images[currentImageIndex] || product.image
                                    : product.images[currentImageIndex] || product.image
                            }
                            alt={product.name}
                            className="main-image"
                        />
                    </Zoom>
                    <div className="thumbnail-images">
    {currentColorIndex === undefined && (
        // Render main images only if no color is selected
        <>
            {product.images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`${product.name} - ${index + 1}`}
                    className={index === currentImageIndex ? 'thumbnail active' : 'thumbnail'}
                    onClick={() => handleImageClick(index)}
                />
            ))}
        </>
    )}
    {currentColorIndex !== undefined && (
        // Render color images if a color is selected
        <>
            {product.colored_images[currentColorIndex]?.images.map((image, index) => (
                <img
                    key={index}
                    src={image}
                    alt={`${product.name} - ${index + 1}`}
                    className={index === currentImageIndex ? 'thumbnail active' : 'thumbnail'}
                    onMouseOver={() => handleImageClick(index)}
                />
            ))}
        </>
    )}
</div>

                </div>
                <div className="product-info">
                    <h1>{product.name}</h1>
                    <div>
                       <h1>Product Description</h1>
                        <p dangerouslySetInnerHTML={{ __html: product.description }} />
                    </div>
                    <div className="productdetails-price">
                        {product.discount === 0 ? (
                            <p className="discounted-price">₹{product.price}</p>
                        ) : (
                            <div className="productprice">
                                 <p className="discounted-price">₹{product.discounted_price}</p>
              <strike className="price">₹{product.price}</strike>
                            </div>
                        )}
                    </div>
                    <div className=''>

                    </div>
                    <p className="stock">
                        
                        <span style={{ color: parseInt(product.total_stock) > 0 ? 'green' : 'red', marginLeft: '5px' }}>
                            {parseInt(product.total_stock) > 0 ? 'In Stock' : 'Out of Stock'}
                        </span>
                    </p>
                    <p className="subcategory">Sub Category: {product.sub_category_name}</p>
                    <div className="product-quantity">
                        <h6>Quantity:</h6>
                        <div className="product-quantity1">
                            <button className="product-quantity-btn-1" onClick={handleDecrease}>
                                -
                            </button>
                            <input value={quantity}    onChange={(e) => {
                                const value = parseInt(e.target.value)  || 0;
                                if (value <= product.total_stock) {
                                  setQuantity(value);
                             }else{
                                if(product.category_id === 1){
                                    Swal.fire({
                                        icon:'warning',
                                        text:`Currently you can rent only ${product.total_stock} Qty`
                                    })
                                }
                             } }} inputMode='numeric' />
                            <button className="product-quantity-btn-2" onClick={handleIncrease}>
                                +
                            </button>
                        </div>
                    </div>
                    <div>
    <div className='toggle-available' onClick={toggleavailable}>
        <h6 >
        Check Availability
        </h6>
        <span><i className={Availability ? 'bx bx-chevron-down' : 'bx bx-chevron-right'}></i></span>             
    </div>
    {product.category_id === 1 ?
    <div>
 {Availability === true && (
        <div  className='toggle-available-show'>
            {product.total_stock === "0" ?
                <p style={{color:'red'}}>Out of Stock</p>:
           <p> Currently you can rent only {product.total_stock} Qty</p>
 }
        </div>
    )}
    </div> 
    :
    <div>
 {Availability === true && (
        <div  className='toggle-available-show'>
            {product.total_stock === "0" ?
                <p style={{color:'red'}}>Out of Stock</p>:
 <p> Currently you can buy only {product.total_stock} Qty</p>
            }
        
        </div>
    )}
    </div> 
    }
   
</div>
                    <div className="color-thumbnails">
                        {product.colored_images.map((coloredImage, colorIndex) => (
                            <div key={coloredImage.id} className="color-thumbnail">
                                <div className="color_img">
                                    <img
                                        src={coloredImage.image}
                                        alt={coloredImage.color_name}
                                        className={
                                            colorIndex === currentColorIndex ? 'color-icon active' : 'color-icon'
                                        }
                                        onClick={() => handleColorClick(colorIndex)}
                                    />
                                    <small>{coloredImage.color_name}</small>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div className="Add-to-cart">
                        <button
                            className="Add-to-cart-btn"
                            onClick={(e) =>
                                AddtoCart(e, product.colored_images[currentColorIndex]?.id, product.id)
                            }>
                            Add to Cart
                        </button>
                    </div>
                </div>
            </div>
        </div>
            <section className='recommend-section'>
                <div>
                    <h4>Similar Product Recommendations</h4>
                <Slider {...settings}>
                    { Recommend.map((recom,index)=>(

                        <div className='recoomend-product'>
                          <img onClick={()=>handledetails(recom.id)} src={recom.image} alt="" />
                          <h4>{recom.name}</h4>
                        </div>
                     

                    ))

                    }
     
      
    </Slider>
                </div>
            </section>
    </div>
    );
}
