import React, { useEffect, useState } from 'react';
import './Contactus.css'; // Importing CSS file
import { FaPhone, FaMapMarkerAlt, FaEnvelope } from 'react-icons/fa'; // Importing icons
import ApiService from '../../Api/ApiController';


export default function ContactUs() {
  const [contactaddress,setcontactaddress] =  useState([]);
  const [phone,setphone]  = useState([])
  const [email,setemail]  = useState([])
  useEffect(()=>{
      ApiService.getSettingData({
        contact_address:"contact_address",
        
      }).then((response)=>{
                if(response.data.status === 'success'){
               
                  setcontactaddress(response.data.data.value)
                }
      }).catch((err)=>{
        console.log(err)
      })
  },[])
  useEffect(()=>{
      ApiService.getSettingData({
        phone:"phone"
      }).then((response)=>{
                if(response.data.status === 'success'){
               
                  setphone(response.data.data.value)
                }
      }).catch((err)=>{
        console.log(err)
      })
  },[])
  useEffect(()=>{
      ApiService.getSettingData({
        email_address:"email_address"
      }).then((response)=>{
                if(response.data.status === 'success'){
                  console.log(response.data.data)
                  setemail(response.data.data.value)
                }
      }).catch((err)=>{
        console.log(err)
      })
  },[])
  return (
    <div className="contact-us-container">
      <h1>Contact Us</h1>
      <div className="contact-info">
        <div className="contact-item">
          <FaPhone className="icon" />
          <span>+91 {phone}</span>
        </div>
        <div className="contact-item">
          <FaMapMarkerAlt  className="icon" />
          <span>{contactaddress}</span>
        </div>
        <div className="contact-item">
          <FaEnvelope className="icon" />
          <span>{email}</span>
        </div>
      </div>
    </div>
  );
}

