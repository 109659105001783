import React, { useEffect, useState } from "react";
import "./mybookings.css"; // Import your CSS file for MyBookings component
import { NavLink, useNavigate } from "react-router-dom";
import ApiService from "../../Api/ApiController";
export default function MyBookings() {
  const [selectedOption, setSelectedOption] = useState("ongoing");
  const [Bookings, setBookings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigate = useNavigate();
  const handleOptionChange = (option) => {
    console.log(option)
    setSelectedOption(option);
    GetBookings(currentPage, option);
  };

  const GetBookings = async (page, option) => {
    try {
      const response = await ApiService.GetBookings({
        status: option,
        page,
      });
      if (response.data.status === "success") {
        setBookings(response.data.data);
          console.log(response.data.data)
      } else if (response.data.status === "error") {
        console.log(response.data)
        setBookings([]);
        // setBookings(response.data)
      }
    } catch (error) {
      console.error("An error occurred while fetching bookings:", error);
    }
  };

  useEffect(() => {
    GetBookings(currentPage,selectedOption);
  }, [currentPage,selectedOption]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  const handledetails = (orderId) =>{
    navigate(`/orderdetails/${orderId}/${selectedOption}`)
  }

  return (
    <div className="my-bookings-container">
      <h2>My Bookings</h2>
      <div className="booking-options">
        <button
          className={
            selectedOption === "ongoing" ? "option-btn active" : "option-btn"
          }
          onClick={() => handleOptionChange("ongoing")}
        >
          Ongoing
        </button>
        <button
          className={
            selectedOption === "completed" ? "option-btn active" : "option-btn"
          }
          onClick={() => handleOptionChange("completed")}
        >
          Completed
        </button>
        <button
          className={
            selectedOption === "cancelled" ? "option-btn active" : "option-btn"
          }
          onClick={() => handleOptionChange("cancelled")}
        >
          Cancelled
        </button>
      </div>
      {/* Render bookings based on the selected option */}
      {selectedOption === "ongoing" && (
        <div className="booking-list">
       
          {Bookings.map((booking, index) => (
            <div key={index} className="ongoing-container" onClick={()=>handledetails(booking.order_id)}>
              <div className="ongoing-description">
                <div className="ongoing-description1">Description:</div>
                <div
                  className="ongoing-description2"
                  dangerouslySetInnerHTML={{ __html: booking.description }}
                ></div>
              </div>
              <div className="ongoing-order">
                <div className="ongoing-order1">Order ID:</div>
                <div className="ongoing-order2">{booking.order_id}</div>
              </div>
              <div className="ongoing-arriving">
                <div className="ongoing-arriving1">Arriving</div>
                <div className="ongoing-arriving2">{booking.arriving_date}</div>
              </div>
              <div className="ongoing-total">
                <div className="ongoing-total1">Total Price</div>
                <div className="ongoing-total2">
                  ₹{booking.final_item_price}
                </div>
              </div>
            </div>
          ))}
          </div>
       
      )}
      {selectedOption === "completed" && (
       <div className="booking-list">
       
       {Bookings.map((booking, index) => (
         <div key={index} className="ongoing-container" onClick={()=>handledetails(booking.order_id)}>
           <div className="ongoing-description">
             <div className="ongoing-description1">Description:</div>
             <div
               className="ongoing-description2"
               dangerouslySetInnerHTML={{ __html: booking.description }}
             ></div>
           </div>
           <div className="ongoing-order">
             <div className="ongoing-order1">Order ID:</div>
             <div className="ongoing-order2">{booking.order_id}</div>
           </div>
           <div className="ongoing-arriving">
             <div className="ongoing-arriving1">Arriving</div>
             <div className="ongoing-arriving2">{booking.arriving_date}</div>
           </div>
           <div className="ongoing-total">
             <div className="ongoing-total1">Total Price</div>
             <div className="ongoing-total2">
               ₹{booking.final_item_price}
             </div>
           </div>
         </div>
       ))}
           {/* : null } */}
        </div>
      )}
      {selectedOption === "cancelled" && (
        <div to="/orderdetails" className="booking-list">
          {Bookings.map((booking, index) => (
            <div key={index} className="ongoing-container" onClick={()=>handledetails(booking.order_id)}>
              <div className="ongoing-description">
                <div className="ongoing-description1">Description:</div>
                <div
                  className="ongoing-description2"
                  dangerouslySetInnerHTML={{ __html: booking.description }}
                ></div>
              </div>
              <div className="ongoing-order">
                <div className="ongoing-order1">Order ID:</div>
                <div className="ongoing-order2">{booking.order_id}</div>
              </div>
              <div className="ongoing-arriving">
                <div className="ongoing-arriving1">Arriving</div>
                <div className="ongoing-arriving2">{booking.arriving_date}</div>
              </div>
              <div className="ongoing-total">
                <div className="ongoing-total1">Total Price</div>
                <div className="ongoing-total2">
                  ₹{booking.final_item_price}
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          &#8249;
        </button>
        <span>
          {currentPage} 
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          // disabled={currentPage === totalPages}
        >
          &#8250;
        </button>
      </div>
    </div>
  );
}
