

import React, { useEffect, useState } from "react";
import "./productlist.css";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import ApiService from "../../Api/ApiController";
import Loader from "../../loader/Loader";

export default function ProductList() {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [sortBy, setSortBy] = useState(localStorage.getItem("sortBy") || "");
  const subcategoryId =  useParams()
  const [wishlistClickedMap, setWishlistClickedMap] = useState({});
  const [hideoutofstock,sethideoutofstock] = useState(0)
  const [search,setsearch] =  useState();
  const token = sessionStorage.getItem('authtoken')

  useEffect(() => {
    if (subcategoryId) {
      fetchProductList(currentPage);
    }
  }, [subcategoryId, currentPage, sortBy,search,hideoutofstock]);

  useEffect(() => {
    if (sortBy) {
      localStorage.setItem("sortBy", sortBy);
    }
  }, [sortBy]);

  const fetchProductList = async (page) => {
    console.log(hideoutofstock)
    setLoading(true);
    try {
      const response = await ApiService.getItems({
        sub_category_id: subcategoryId.subcategoryId,
        category_id: subcategoryId.categoryId,
        page,
        order_column: getOrderByColumn(sortBy),
        order_by: getOrderByDirection(sortBy),
        is_default_sort: getdefault(sortBy),
        is_hide_out_of_stock_items:outofstock(hideoutofstock),
        search_key : search
      });
      if (response.data.status === "success") {
        
        setProducts(response.data.data);
        setTotalPages(response.data.data.total_pages);
        setError(null);
      } else {
        setError(response.data.message);
      }
    } catch (error) {
      setError("Error fetching product list. Please try again later.");
    }
    setLoading(false);
  };

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handledetails = (productId) => {
    navigate(`/productdetail/${productId}`);
  };

  const handleWishlistClick = (productId) => {
  
    setWishlistClickedMap((prevMap) => {
      const updatedMap = {
        ...prevMap,
        [productId]: !prevMap[productId],
      };
      return updatedMap;
    });

    try {
      ApiService.addtowishlist({
        item_id: productId,
      }).then((response) => {
        if (response.data.status === "success") {
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
   if(token){

     ApiService.getWishlist()
     .then((response) => {
        if (response.data.status === "success") {
          const wishlistProducts = response.data.data;
          const updatedMap = {};
          wishlistProducts.forEach((product) => {
            updatedMap[product.id] = true;
          });
          setWishlistClickedMap(updatedMap);
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }, [token]);

   const outofstock = (hideoutofstock) =>{
    if(hideoutofstock === "1"){
      return 1 ;
    }else{
      return 0;
    }
   }
   const getdefault = (sortBy) =>{
    if(sortBy === "1"){
      return 1 ;
    }
    return 0;
   }
  const getOrderByColumn = (sortBy) => {
    switch (sortBy) {
      case "price_low_high":
        return "price"; // Replace "price" with the actual column name for sorting by price
      case "price_high_low":
        return "price"; // Replace "price" with the actual column name for sorting by price
      case "alpha_a_z":
        return "name"; // Replace "name" with the actual column name for sorting alphabetically
      case "alpha_z_a":
        return "name"; // Replace "name" with the actual column name for sorting alphabetically
      default:
        return ""; // Default case for no sorting
    }
  };

  const getOrderByDirection = (sortBy) => {
    switch (sortBy) {
      case "price_low_high":
        return "asc";
      case "price_high_low":
        return "desc";
      case "alpha_a_z":
        return "asc";
      case "alpha_z_a":
        return "desc";
      default:
        return "";
    }
  };

  return (
    <div>
    {loading ? <div style={{backgroundColor:"white",width:'100vw',height:'100vh'}}><Loader/></div> :
    <div>
     <section className="sorted-menus">
  <select
    className="Sorting-options"
    name=""
    id=""
    onChange={(e) => setSortBy(e.target.value)}
    value={sortBy}
  >
    <option value="1" selected>
      Featured
    </option>
    <option value="price_low_high">Price: low to high</option>
    <option value="price_high_low">Price: high to low</option>
    <option value="alpha_a_z">Alphabetically: A to Z</option>
    <option value="alpha_z_a">Alphabetically: Z to A</option>
  </select>
  <div className="search-filter">
    <input type="search" placeholder="Search here" value={search} onChange={(e) => setsearch(e.target.value)} />
  </div>
  <div className="hideoutofstock">
    <h6>Hide out of stock items:</h6>
    <div class="fx-block">
      <label class="cl-checkbox">
      <input 
  checked={hideoutofstock === "1"} 
  onChange={(e) => sethideoutofstock(e.target.checked ? "1" : "0")} 
  type="checkbox" 
/>

        <span></span>
      </label>
    </div>
  </div>
</section>

      <div>
        <section>
        
          <div className="card-productlist">
            {products.map((product, index) => (
              <div key={index} className="">
                <div className="card text-bg-dark">
                  <div className="card-productlist-img">
                    <img
                      src={product.image}
                      className="card-img"
                      alt={product.name}
                      onClick={() => handledetails(product.id)}
                    />
                    <div className="card-img-overlay1">
                      <h5 className="card-title">{product.name}</h5>
                      <div className="wishlist-icon">
                        {token ?

                          <i
                          className={
                            wishlistClickedMap[product.id]
                              ? "bx bxs-heart"
                              : "bx bx-heart"
                          }
                          style={{
                            color: wishlistClickedMap[product.id]
                              ? "#ff0303"
                              : "",
                            }}
                            onClick={() => handleWishlistClick(product.id)}
                            ></i> :
                            ""
                          }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <div className="pagination">
  <button
    onClick={() => handlePageChange(currentPage - 1)}
    disabled={currentPage === 1}
  >
    &#8249;
  </button>
  <span>
    {currentPage}
  </span>
  <button
    onClick={() => handlePageChange(currentPage + 1)}
    disabled={currentPage === totalPages}
  >
    &#8250;
  </button>
</div>

      </div>
      </div>
    }
    </div>
  );
}
