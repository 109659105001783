import React, { useEffect, useState } from 'react';
import './Notification.css'; // Import your CSS file for styling
import ApiService from '../../Api/ApiController';

export default function Notification() {
    const [Notifications,setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [perPage] = useState(10);

    const getnotification = async(page)=>{
       await ApiService.notification({
        page
        }).then((response)=>{
            if(response.data.status === 'success'){
              setNotifications(response.data.data)
              console.log(response.data)
              setTotalPages(response.data.total_pages);
            }else{
              if(currentPage > 1){
                setCurrentPage(currentPage -1)
               }else{
        
               }
            }
        }).catch((err)=>{
          console.log(err)
        })
    }
       useEffect(()=>{
        getnotification(currentPage)
       },[currentPage]);


       const handlePageChange = (newPage) => {
        setCurrentPage(newPage);
      };
  return (
    <div className="notification-container">
      <section className="notification-section">
        {Notifications.map((notification,index)=>(
            
            <div className="notification-list">
          {/* Individual notification items */}
          <div className="notification-item">
            <div className="notification-content">
              {/* Notification title and message */}
              <h3 className="notification-title">{notification.title}</h3>
              <p className="notification-message">{notification.description}</p>
            </div>
          </div>
          {/* Add more notification items as needed */}
        </div>
            ))}
      </section>
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
            &#8249;
        </button>
        <span>
          {currentPage} 
        </span>
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
                &#8250;
        </button>
      </div>
    </div>
  );
}
