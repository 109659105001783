import React, { useState } from 'react'
import './deleteaccount.css'
import { NavLink, useNavigate } from 'react-router-dom'
import ApiService from '../../Api/ApiController'
import Swal from 'sweetalert2'
export default function DeleteAccount() {
  const [currentpassword,setcurrentPassword] =  useState();
  const navigate =  useNavigate()
 const UserId =  localStorage.getItem('userId')
 console.log(UserId)
  const verifyPassword = async () =>{
  await    ApiService.VerifyPassword({
      customer_id:UserId,
      current_password:currentpassword
    }).then((response)=>{
    
                 if(response.data.status === "success"){
                  DeleteAccount();
                 }else{
                 
                  Swal.fire({
                    icon:"error",
                    text:response.data.message
                  })
                 }
    }).catch((err)=>{
      Swal.fire({
        icon:"error",
        text:err.response.data.message
      })
    })
  }

  const DeleteAccount = ()=>{
    ApiService.DeleteAccount(UserId).then((response)=>{
                 if(response.data.status === 'success'){
                                Swal.fire({
                                  icon:"success",
                                  text: response.data.message
                                })
                                navigate('/')
                                sessionStorage.removeItem('authtoken')
                 }else{
                  console.log(response)
                 }
    }).catch((err)=>{
      console.log(err)
    })
  } 
  return (
    <div>
      <div className='delete-account-div'>
        <section className='delete-account-main'>
            <div className='delete-account'>        
            <div className='delete-account-1' >
            <i class='bx bx-lock'></i>
            </div>
            <div className='delete-account-2'>
                <input type="text" value={currentpassword} onChange={(e)=> setcurrentPassword(e.target.value)}  placeholder='Enter Password'/>
            </div>
            <NavLink to='/resetpassword' className='delete-account-3'>
                Forget Password?
            </NavLink>
            <div className='delete-account-4'>
                <button onClick={verifyPassword}>Delete Account</button>
            </div>
            </div>
        </section>
      </div>
    </div>
  )
}
