import React, { useState, useEffect } from "react";
import "./cart.css"; // Import your cart page CSS file
import ApiService from "../../Api/ApiController";

import { useTimeSlot } from "./TimeSlotContext";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../loader/Loader";
export default function Cart() {
  const [cartItems, setCartItems] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedTimeSlot, setSelectedTimeSlotLocal] = useState("");
  const { setSelectedTimeSlot, setsdate, setedate,setdays } = useTimeSlot();
  const [day,setday] = useState(1);
  const [minimunOrderLimit,setminimumOrderLimit] = useState("");
  // const {setsdate} = useTimeSlot();
  const [totalDays, setTotalDays] = useState(1);
  const navigate = useNavigate();
  const [categoryIds, setCategoryIds] = useState([]);
  const [OrderSetting,setOrderSetting] = useState([]);
  const [ordertimeslot,setordertimeslot1] =  useState([]);
  const [loading,setLoading] = useState(false)
  // const[quantity,setQuantity] =  useState(1);
  const token = sessionStorage.getItem('authtoken')
 
  const continueshopping =  ()=>{
    navigate('/')
  }

useEffect(() => {
  const ordertimslots = async () => {
    try {
      const response = await ApiService.Ordertimeslots({ slot_date: startDate });
    console.log(response.data)

      if (response.data.status === 'success') {
        setordertimeslot1(response.data.data);
        console.log('After setordertimeslot:', response.data.data); // Log the updated state
      } else if(response.data.status === 'error') {
        setordertimeslot1([])
      }
    } catch (error) {
      console.error('Error fetching time slots:', error);
      // Handle the error case here, such as showing an error message to the user
      // setordertimeslot([]); // Optionally update state based on error
    }
  };

  ordertimslots();
}, [startDate]); // Include startDate in the dependency array


   useEffect(() => {
     fetchCartData(); 
    
  }, []);
 
  
  useEffect(()=>{
    const locationDataString =  sessionStorage.getItem('location');
        // if(locationDataString){

          const Locationcheck = async () => {
            try {
              const locationData = JSON.parse(locationDataString);
        const Response = await ApiService.LocationCheck({
          location_name: locationData.currentAddress,
          latitude: locationData.latitude,
          longitude: locationData.longitude
        });
  
        if (Response.data.status === 'success') {

          
         
        } else {
              navigate('/')
            }
          } catch (error) {
        console.error(error);
      }
    };
    Locationcheck()
      // }
  },[])
  const AddtoCart = async () => {
    // Retrieve cart items from local storage
    const storedCartItems = JSON.parse(localStorage.getItem('cartitems'));
    
    // Check if cart items exist in local storage
    if (storedCartItems) {
      try {
        // Iterate over each cart item
        for (const itemKey in storedCartItems) {
          const item = storedCartItems[itemKey];
          // Call the addtocart API for each item
          const response = await ApiService.addtocart({
            item_id: item.item_id,
            item_color_image_id: item.item_color_image_id,
            quantity: item.quantity
          });
  
       
          if (response.data.status === 'success') {
     
            window.location.reload();
          }
        }
      } catch (error) {
        
      }
    }
  }
  
  useEffect(()=>{
    if(token){
      AddtoCart()
      localStorage.removeItem('cartitems')
    }
  },[])
  // =====================================================

 
  // =======================================
  const fetchCartData = () => {
    if(token){
      setLoading(true)
      ApiService.getCartDetails()
      .then((response) => {
        if (response.data.status === "success") {
          console.log(response.data.data)
          setCartItems(response.data.data);
           setLoading(false)
          const categoryIds = response.data.data.map(
            (item) => item.category_id
          );
          // Assuming an array of category IDs is returned
          setCategoryIds(categoryIds);
        }else{
          setLoading(false)
        }
      })
      .catch((error) => {
        console.error(error);
      });
    }else {
      const storedCartItems = JSON.parse(localStorage.getItem('cartitems'));
      if (storedCartItems) {
        setCartItems(Object.values(storedCartItems));
        // Update categoryIds state using functional form of setCartItems
        setCategoryIds(prevCategoryIds => {
          const newCategoryIds = Object.values(storedCartItems).map(
            (item) => item.product.category_id
          );
        
          return newCategoryIds;
        });
      }
    }
  }
  useEffect(() => {

    const nexDay = new Date();
    nexDay.setDate(nexDay.getDate() + 1); // Add one day to the current date
    const formattedNextDay = formatDate(nexDay);
    setStartDate(formattedNextDay);
    // Set end date to one day after start date
    const nextDay = new Date(nexDay);
    nextDay.setDate(nextDay.getDate() + 1);
    setEndDate(formatDate(nextDay));
  }, []);

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);

    // Update end date to be one day after the new start date
    const nextDay = new Date(newStartDate);
    nextDay.setDate(nextDay.getDate() + 1);
    let newEndDate = formatDate(nextDay);

    // Ensure end date is not less than start date
    if (newStartDate > endDate) {
      newEndDate = newStartDate;
    }

    setEndDate(newEndDate);
    setTotalDays(calculateTotalDays(newStartDate, newEndDate));
  };

  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    // Ensure end date is not less than start date
    if (newEndDate >= startDate) {
      setEndDate(newEndDate);
      setTotalDays(calculateTotalDays(startDate, newEndDate));
    }
  };
  const calculateTotalDays = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);

    setdays(differenceInDays)
    setday(differenceInDays)
    return Math.round(differenceInDays);
  };
  // Helper function to format date as "YYYY-MM-DD"
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const DeleteCartItem = async (cartId) => {
   if(token){

     Swal.fire({
       title: "Are you sure?",
      text: "Do you want to delete this item?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          // Send request to delete item
          const response = await ApiService.deletecartitem({ cart_id: cartId });
          if (response.data.status === "success") {
            // Show success message
            localStorage.removeItem('cartitems')
            Swal.fire({
              title: "Deleted!",
              text: "Your Product has been deleted.",
              icon: "success",
            });
            // Handle any further actions (e.g., updating UI)
            fetchCartData();
            window.location.reload()
          } else {
            // Show error message if deletion fails
            Swal.fire({
              title: "Error!",
              text: "Failed to delete the item.",
              icon: "error",
            });
          }
        } catch (error) {
          // Show error message if request fails
          console.error("Delete request failed:", error);
          Swal.fire({
            title: "Error!",
            text: "Failed to delete the item.",
            icon: "error",
          });
        }
      }
    });
  }else{
    Swal.fire({
      title: "Are you sure?",
     text: "Do you want to delete this item?",
     icon: "warning",
     showCancelButton: true,
     confirmButtonColor: "#3085d6",
     cancelButtonColor: "#d33",
     confirmButtonText: "Yes, delete it!",
   }).then(async (result) => {
     if (result.isConfirmed) {
       
  
           localStorage.removeItem('cartitems')
           Swal.fire({
             title: "Deleted!",
             text: "Your Product has been deleted.",
             icon: "success",
           });
           // Handle any further actions (e.g., updating UI)
         
         } 
        });
      
  }
  };
  const handleCheckout = () => {
    if(token){

      if (!selectedTimeSlot) {
        Swal.fire({
        icon: "warning",
        text: "Please select your slot for delivery",
        showCancelButton: true,
      });
    } else {
      // Check if the selected time slot is enabled
      const isEnabled = ordertimeslot.find(
        (slot) => slot.time_slot === selectedTimeSlot.time_slot
      ).is_time_slot_enabled === "yes";
  
      if (isEnabled) {
        // Proceed to checkout if the time slot is enabled
        navigate("/proceedtocheckout");
      } else {
        // Show a warning if the time slot is not enabled
        Swal.fire({
          icon: "warning",
          text: "This time slot is not available for now. Please choose other slot",
          showCancelButton: true,
        });
      }
    }
  }else{
    navigate('/login')
  }
  };
  useEffect(()=>{
    setSelectedTimeSlot(selectedTimeSlot);
    setsdate(startDate);
    setedate(endDate);

  },[selectedTimeSlot,startDate,endDate,setSelectedTimeSlot,setsdate,setedate])

  const FetchTimeslot =  async ()=>{
    ApiService.getOrderSetting().then((response)=>{
      if(response.data.status === 'success'){
        // class(response.data.data.mininum_order_amount);
        setOrderSetting(response.data.data);
        setminimumOrderLimit(response.data.data.mininum_order_amount)
       
      }else{

      }
  }).catch((err)=>{
    
  })
  }

  useEffect(()=>{
    FetchTimeslot()
  },[]);
  // const handleDecrease = (itemId) => {
  //   const updatedItems = cartItems.map((cartItem) =>
  //     cartItem.id === itemId ? { ...cartItem, quantity: cartItem.quantity - 1 } : cartItem
  //   );
  //   setCartItems(updatedItems);
  //   UpdateCartQuantity(itemId, updatedItems.find(item => item.id === itemId).quantity); // Pass itemId and updated quantity
  // };
  const handleDecrease = (itemId) => {
    const updatedItems = cartItems.map((cartItem) => {
      if (cartItem.id === itemId) {
        const newQuantity = cartItem.quantity - 1;
        return {
          ...cartItem,
          quantity: newQuantity >= 0 ? newQuantity : 0,
        };
      }
      return cartItem;
    });
    setCartItems(updatedItems);
    UpdateCartQuantity(itemId, updatedItems.find(item => item.id === itemId).quantity); // Pass itemId and updated quantity
  };
  
  
  const handleIncrease = (itemId) => {
    const updatedItems = cartItems.map((cartItem) =>
      cartItem.id === itemId ? { ...cartItem, quantity: cartItem.quantity + 1 } : cartItem
    );
    setCartItems(updatedItems);
    UpdateCartQuantity(itemId, updatedItems.find(item => item.id === itemId).quantity); // Pass itemId and updated quantity
  };
  
  const UpdateCartQuantity = async (itemId, quantity) => {
    try {
      const response = await ApiService.managecartitemquantity({
        cart_id: itemId, 
        quantity: quantity 
      });
      if (response.data.status === 'success') {
 
        fetchCartData()
      } else {
        
      }
    } catch (error) {
     
    }
  };
  // const totalPrice = cartItems.reduce(
  //   (acc, item) => acc + item.item_price * item.quantity,
  //   0
  // );
  let totalPrice = 0
  if(token){

    totalPrice = cartItems.reduce((acc, item) => {
      if (item.category_id === 1) {
        // For products with category ID 1, multiply the price by the total days
        return acc + item.item_price * totalDays ;
      } else {
        // For other products, just sum the price as usual
        return acc + item.item_price ;
      }
    }, 0);
  }else{
    totalPrice = cartItems.reduce((acc, item) => {
      if (item.product.category_id === 1) {
        // For products with category ID 1, multiply the price by the total days
        return acc + item.product.discounted_price * totalDays * item.quantity;
      } else {
        // For other products, just sum the price as usual
        return acc + item.product.discounted_price * item.quantity ;
      }
    }, 0);
  }
  
 
  return (
    <>
      {loading ? <div style={{backgroundColor:"white",width:'100vw',height:'100vh'}}><Loader/></div> :
      <div>
        


    <div className="cart-container">
    
      {cartItems.length === 0 ? (
        <div className="empty-cart-container">
      
          <h1>Your Cart is empty</h1>
          <button onClick={continueshopping}>Continue Shopping</button>
       
        </div>
        ) : (
          <div>
          {token ? <div>
            {cartItems.map((item,index) => (
            <div className="cart-item" key={index}>
              <img
                src={item.item_image}
                alt={item.item_name}
                className="cart-item-image"
              />
              <div className="cart-item-details">
                <h3 className="cart-item-name">{item.item_name}</h3>
                {item.category_id === 1 ?

                  <p className="cart-item-quantity">For {totalDays} Days</p>:
                <div className="product-quantity">
                        <h6>Quantity:</h6>
                        <div className="product-quantity1">
                            <button className="product-quantity-btn-1" onClick={()=>handleDecrease(item.id)}>-</button>
                            <span>{item.quantity}</span>
                            <button className="product-quantity-btn-2" onClick={()=>handleIncrease(item.id)}>+</button>
                        </div>
                    </div>
                }
                {item.category_id === 1 ?
 <p className="cart-item-price">Price: ₹{item.item_price * totalDays}</p>:
         <p className="cart-item-price">Price: ₹{item.item_price}</p>
                }
                <button
                  className="delete-btn"
                  onClick={() => DeleteCartItem(item.id)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))}
            </div>
       :
       <div>
           {cartItems.length > 0 ? (
                <div>
                    {cartItems.map((item, index) => (
                          <div className="cart-item">
                          <img
                            src={item.product.image}
                            alt={item.item_name}
                            className="cart-item-image"
                          />
                          <div className="cart-item-details">
                            <h3 className="cart-item-name">{item.product.name}</h3>
                            {item.product.category_id === 1 ?
            
                              <p className="cart-item-quantity">For {totalDays} Days</p>:
                            <div className="product-quantity">
                                    <h6>Quantity:</h6>
                                    <div className="product-quantity1">
                                        <button className="product-quantity-btn-1" onClick={()=>handleDecrease(item.id)}>-</button>
                                        <span>{item.quantity}</span>
                                        <button className="product-quantity-btn-2" onClick={()=>handleIncrease(item.id)}>+</button>
                                    </div>
                                </div>
                            }
                            {item.product.category_id === 1 ?
             <p className="cart-item-price">Price: ₹{item.product.discounted_price * totalDays *item.quantity}</p>:
                     <p className="cart-item-price">Price: ₹{item.product.discounted_price * item.quantity}</p>
                            }
                            <button
                              className="delete-btn"
                              onClick={() => DeleteCartItem(item.id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                    ))}
                </div>
            ) : (
                <p>No items in the cart.</p>
            )}
       </div>
            }
         

          {cartItems.length > 0 &&
            (categoryIds.includes(1) ||
              (categoryIds.includes(1) && categoryIds.includes(2))) && (
              <div className="cart-dates">
                <div>
                  <label htmlFor="startdate">Start Date:</label>
                  <input
                    type="date"
                    id="startdate"
                    value={startDate}
                    // min={startDate}
                    onChange={handleStartDateChange}
                  />
                </div>
                <div>
                  <label htmlFor="enddate">End Date:</label>
                  <input
                    type="date"
                    id="enddate"
                    value={endDate}
                    onChange={handleEndDateChange}
                  />
                </div>
              </div>
            )}

{cartItems.length > 0 && categoryIds.includes(2) && !categoryIds.includes(1) && (
  <div className="deliveron-dates">
    <div className="date-picker">
      <label htmlFor="startdate">Deliver On Date:</label>
      <input
        type="date"
        id="startdate"
        value={startDate}
        onChange={handleStartDateChange}
        className="date-input"
      />
    </div>
  </div>
)}


          <div className="cart-time-slots">
            <h6>Time Slot:</h6>
            { ordertimeslot && ordertimeslot.map((timeSlot, index) => (
    <div key={index}>
      <label className={timeSlot.is_time_slot_enabled === 'no' ? 'disabled' : ''}>
        <input
          type="radio"
          name="timeslot"
          value={timeSlot.time_slot}
          checked={selectedTimeSlot === timeSlot}
          onChange={() => setSelectedTimeSlotLocal(timeSlot)}
          disabled={timeSlot.is_time_slot_enabled  === 'no'} 
        />
        <span className="radio-custom"></span>
        {timeSlot.time_slot}
      </label>
    </div>
  ))}
            <div className="infoabout-slot">
              <div className="av-slot">   
         <div>Available Slot's </div>
         <div className="bg-1"></div>
              </div>
              <div className="av-slot">
         <div>Not Available Slot's</div>
         <div className="bg-2"></div>
              </div>
            </div>
          </div>

          <div className="cart-total">
            <h3 className="cart-total-text">Total:</h3>
            <p className="cart-total-price">
              ₹
              {totalPrice}
            </p>
          </div>
          <div className="checkout-btn-div">
          <button
  className="checkout-btn"
  onClick={handleCheckout}
  disabled={totalPrice < minimunOrderLimit}
>
  Proceed to Checkout
</button>
{totalPrice < minimunOrderLimit && (
            <p className="minimum-order-message text-danger ">
              Minimum order amount is ₹{minimunOrderLimit}.
            </p>
          )}
          </div>
        </div>
      )}
    </div>
    </div>}
    </>
  );
}
