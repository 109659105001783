import './App.css';
import Footer from './component/footer/Footer';
// import Header from './component/header/Header';
import Header from './component/newpage/newheader/Header';
import SignUp from './component/pages/auth/Signup/SignUp';
import Login from './component/pages/auth/login/Login';
import HomePage from './component/pages/homepage/HomePage';
import PrivacyPolicy from './component/pages/privacypolicy/PrivacyPolicy';
import ProductDetails from './component/pages/productdetails/ProductDetails';
import ProductList from './component/pages/productlist/ProductList';
import TermsAndCondition from './component/pages/termsandcondition/TermsAndCondition';
import AboutUs from './component/pages/aboutus/AboutUs'
import { BrowserRouter,Route,Routes, useLocation} from 'react-router-dom';
import Cart from './component/pages/addtocart/Addtocart';
import DeliveryAddress from './component/pages/deliveryaddress/DeliveryAddress';
import PersonalDetails from './component/pages/auth/personaldetails/PersonalDetails';
import MyBookings from './component/pages/bookings/MyBookings';
import OrderDetails from './component/pages/bookings/OrderDetails';
import DamageDetails from './component/pages/payfordamage/DamageDetails';
import PayForDamage from './component/pages/payfordamage/PayForDamage';
import Damagepay from './component/pages/payfordamage/Damagepay';
import ShippingPolicy from './component/pages/shippingpolicy/ShippingPolicy';
import Cancel from './component/pages/cancellationpolicy/CancellationPolicy';
import Replacement from './component/pages/replacementpolicy/ReplacementPolicy'
import Return from './component/pages/returnpolicy/ReturnPolicy'
import ProceedToCheckOut from './component/pages/addtocart/ProceedToCheckOut';
import { TimeSlotProvider } from './component/pages/addtocart/TimeSlotContext';
import Wishlist from './component/pages/wishlist/Wishlist';
import Setting from './component/pages/settings/Setting';
import DeleteAccount from './component/pages/settings/DeleteAccount';
import FirstStep from './component/pages/auth/forgetpassword/FirstStep';
import SecondStep from './component/pages/auth/forgetpassword/SecondStep';
import Resetpassword from './component/pages/auth/forgetpassword/Resetpassword';
import { AuthProvider } from './component/Authentication/AuthContext';
import PrivateRoute from './component/pages/auth/privateroute/PrivateRoute';
import LocationCheck from './component/location/LocationCheck';
import NewHome from './component/newpage/newhomepage/NewHome';
import Loader from './component/loader/Loader';
import { useEffect, useState } from 'react';
import ExtendOrder from './component/pages/bookings/ExtendOrder';
import Notification from './component/pages/notification/Notification';
import FAQS from './component/pages/FAQS/FAQS';
import ReferralCode from './component/pages/referralcode/RefferalCode';
import ScrollToTop from './component/ScrollToTop';
import Anonymous from './component/pages/auth/privateroute/Anonymous';
import MyProfile from './component/pages/auth/Myprofile/MyProfile';
import LocationROute from './component/location/LocationROute';
import ContactUs from './component/pages/contactus/ContactUs';
import EditAddress from './component/pages/deliveryaddress/EditAddress'
function Main() {
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  useEffect(() => {
    
    const timeout = setTimeout(() => {
        setLoading(false);
    }, 2000);

    return () => clearTimeout(timeout);
}, [location]);
  return (
    <div>
 
  {loading ? (
                <Loader />
            ) : (
      <TimeSlotProvider>
      <Header/>
        <AuthProvider>
        <ScrollToTop />
      <Routes>
        <Route element={<Anonymous/>}>
        <Route path='/login' element={<Login/>}/>
      <Route path='/signup' element={<SignUp/>}/>
        </Route>
        <Route path='/' element={<NewHome/>}/>
        <Route path='/contactus' element={<ContactUs/>}/>
     <Route path='/terms-condition' element={<TermsAndCondition/>}/>
     <Route path='/privacy-policy' element={<PrivacyPolicy/>}/>
      <Route path='/productlist/:categoryId/:subcategoryId' element={<ProductList/>}/>
      <Route path='/productdetail/:productId' element={<ProductDetails/>}/>
      <Route path='/aboutus' element={<AboutUs/>}/>
      <Route path='/myprofile' element={<PrivateRoute children={<MyProfile/>}/>}/>
      <Route path='/cart' element={<Cart/>}/>
      {/* <Route path='/cart' element={<LocationROute children={<Cart/>}/>}/> */}
      <Route path="/deliveryaddress" element={<PrivateRoute children={<DeliveryAddress/>}/>}/>
      <Route path='/personaldetails' element={<PrivateRoute children={<PersonalDetails/>} />}/>
      <Route path='/mybookings' element={<PrivateRoute children={<MyBookings/>}/>}/>
      <Route path='/orderdetails/:orderId/:status' element={<PrivateRoute children={<OrderDetails/>} />} />
      <Route path='/payfordamage' element={<PrivateRoute children={<PayForDamage/>}/>}/>
      <Route path='/damagedetails/:orderId' element={<PrivateRoute children={<DamageDetails/>}/>}/>
      <Route path='/damagepay/:orderId' element={<PrivateRoute children={<Damagepay/>}/>}/>
      <Route path='/shippingpolicy' element={<ShippingPolicy/>}/>
      <Route path='/cancellationpolicy' element={<Cancel/>}/>
      <Route path='/replacementpolicy' element={<Replacement/>}/>
      <Route path='/returnpolicy' element={<Return/>} />
      <Route path='/proceedtocheckout' element={<PrivateRoute children={<ProceedToCheckOut/>}/>} />
      <Route path='/wishlist' element={<PrivateRoute children={<Wishlist/>}/>}/>
      <Route path='/settings' element={<PrivateRoute children={<Setting/>}/>}/>
      <Route path='/deleteaccount' element={<PrivateRoute children={<DeleteAccount/>}/>}/>
      <Route path='/firststep' element={<FirstStep/>}/>
      <Route path='/secondstep' element={<SecondStep/>}/>
      <Route path='/resetpassword'element={<Resetpassword/>}/>
      <Route path='/locationcheck' element={<LocationCheck/>}/>
      <Route path='/extendorder/:orderId' element={<PrivateRoute children={<ExtendOrder/>}/>}/>
      <Route path="/notification" element={<PrivateRoute children={<Notification/>}/>}/>
      <Route path='/FAQS' element={<PrivateRoute children={<FAQS/>}/>}/>
      {/* <Route path='/referearn' element={<PrivateRoute children={<ReferralCode/>}/>}/> */}
      <Route path='/referearn' element={<ReferralCode/>}/>
      <Route path='/editaddress/:addressid' element={<PrivateRoute children={<EditAddress/>}/>}/>

      </Routes>
      
      </AuthProvider>
      <Footer/>
      </TimeSlotProvider>
     
     )}
     
    </div>
  );
}

export default Main;
