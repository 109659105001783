// import React, { useEffect, useState } from 'react'
// import ApiService from '../../Api/ApiController';

// export default function Wishlist() {
//     const [wishlistClickedMap, setWishlistClickedMap] = useState({});
//     const [currentPage, setCurrentPage] = useState(1);
//   const [totalPages, setTotalPages] = useState(1);
//   const [products, setProducts] = useState([]);


//   const handlePageChange = (newPage) => {
//     setCurrentPage(newPage);
//   };
//   const handleWishlistClick = (productId) => {


//     try{
//         ApiService.addtowishlist({
//           item_id: productId,
//         }).then((response)=>{
//              if(response.data.status === 'success'){
//                 console.log(response.data)
//                 window.location.reload();
//              }
//         }).catch((error)=>{
//           console.log(error)
//         })
//     }catch(error){
//       console.log(error)
//     }
//   };
//   useEffect(() => {
//     ApiService.getWishlist()
//       .then((response) => {
//         if (response.data.status === 'success') {
//             console.log(response.data)
//           const wishlistProducts = response.data.data;
//           const updatedMap = {};
//           wishlistProducts.forEach(product => {
//             updatedMap[product.id] = true;
//           });
//           setWishlistClickedMap(updatedMap);
//           setProducts(response.data.data);
         
//         }
//       })
//       .catch((error) => {
//         console.error(error);
//       });
//   }, []);

//   return (
//     <div>
//     <div>
//       <section>
//         <div className="card-productlist">
//           {products.map((product, index) => (
//             <div key={index} className="">
//               <div className="card text-bg-dark">
//                 <div className="card-productlist-img">
//                   <img
//                     src={product.image}
//                     className="card-img"
//                     alt={product.name}
//                     // onClick={()=>handledetails(product.id)}
//                   />
//                   <div className="card-img-overlay1">
//                     <h5 className="card-title">{product.name}</h5>
//                     <div className="wishlist-icon">
//                       <i
//                         className={
//                           wishlistClickedMap[product.id]
//                             ? "bx bxs-heart"
//                             : "bx bx-heart"
//                         }
//                         style={{
//                           color: wishlistClickedMap[product.id]
//                             ? "#ff0303"
//                             : "",
//                         }}
//                         onClick={() => handleWishlistClick(product.id)}
//                       ></i>
//                     </div>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           ))}
//         </div>
//       </section>
//       <div className="pagination">
//         <button
//           onClick={() => handlePageChange(currentPage - 1)}
//           disabled={currentPage === 1}
//         >
//           Previous
//         </button>
//         <span>
//           {currentPage} of {totalPages}
//         </span>
//         <button
//           onClick={() => handlePageChange(currentPage + 1)}
//           // disabled={currentPage === totalPages}
//         >
//           Next
//         </button>
//       </div>
//     </div>
//   </div>
//   )
// }


import React, { useEffect, useState } from 'react';
import ApiService from '../../Api/ApiController';

export default function Wishlist() {
  const [wishlistClickedMap, setWishlistClickedMap] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [products, setProducts] = useState([]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleWishlistClick = (productId) => {
    try {
      ApiService.addtowishlist({
        item_id: productId,
      }).then((response) => {
        if (response.data.status === 'success') {
          console.log(response.data);
          // window.location.reload();
          fetchWishlist()
        }
      }).catch((error) => {
        console.log(error);
      });
    } catch (error) {
      console.log(error);
    }
  };

  const fetchWishlist = async () => {
    try {
      const response = await ApiService.getWishlist({
        page:currentPage
      });
      if (response.data.status === 'success') {
    
        const wishlistProducts = response.data.data;
        const updatedMap = {};
        wishlistProducts.forEach(product => {
          updatedMap[product.id] = true;
        });
        setWishlistClickedMap(updatedMap);
        setProducts(wishlistProducts);

        // Calculate total pages based on the number of products and items per page
        // const itemsPerPage = 10; // Example: 10 items per page
        // const totalProducts = wishlistProducts.length;
        // const totalPages = Math.ceil(totalProducts / itemsPerPage);
        //  console.log(totalPages)
        // setTotalPages(totalPages);
      }else {
       if(currentPage > 1){
        setCurrentPage(currentPage -1)
       }else{

       }
       
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchWishlist(currentPage)

  }, [currentPage]); // Add currentPage to the dependency array

  return (
    <div>
      <div>
        <section>
          <div className="card-productlist">
            {products.map((product, index) => (
              <div key={index} className="">
                <div className="card text-bg-dark">
                  <div className="card-productlist-img">
                    <img
                      src={product.image}
                      className="card-img"
                      alt={product.name}
                      // onClick={()=>handledetails(product.id)}
                    />
                    <div className="card-img-overlay1">
                      <h5 className="card-title">{product.name}</h5>
                      <div className="wishlist-icon">
                        <i
                          className={
                            wishlistClickedMap[product.id]
                              ? "bx bxs-heart"
                              : "bx bx-heart"
                          }
                          style={{
                            color: wishlistClickedMap[product.id]
                              ? "#ff0303"
                              : "",
                          }}
                          onClick={() => handleWishlistClick(product.id)}
                        ></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
        <div className="pagination">
          <button
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
             &#8249;
          </button>
          <span>
            {currentPage} 
          </span>
          <button
            onClick={() => handlePageChange(currentPage + 1)}
            // disabled={currentPage === totalPages}
          >
           &#8250;
          </button>
        </div>
      </div>
    </div>
  );
}
