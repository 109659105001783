import React, { useEffect, useState } from 'react';
import './damagepay.css'; // Import your CSS file for Damagepay component
import useRazorpay from "react-razorpay";
import brobo from '../../header/brobo.png'
import ApiService from '../../Api/ApiController';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
export default function Damagepay() {
  const [amount, setAmount] = useState('');
  const [Razorpay] = useRazorpay();
  const [razorpaykey, setrazorpaykey] = useState([]);
  const orderId = useParams()
  const navigate =  useNavigate()
  useEffect(()=>{
    ApiService.paymentgatewaykey({
      payment_gateway_name: "razor_pay",
    })
      .then((response) => {
        if (response.data.status === "success") {
          setrazorpaykey(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);


  const PayForDamage = (paymentid)=>{
   
    ApiService.payfordamage({
      booking_id:orderId,
      damage_order_transaction_id:paymentid,
      damage_amount:amount
    }).then((response)=>{
      if(response.data.status === "success") {
      Swal.fire({
        icon: "success",
        title:"payment",
        text:response.data.message,
      })
      navigate('/')
      }else{
        console.error(response);
      }
    }).catch((err)=>{
      console.log(err)
    })
  }
  const handleAdd = () => {
    const options = {
        key: razorpaykey.key, 
        amount: amount * 100, 
        currency: "INR",
        name: "Desi Gro",
        description: "Test Transaction",
        image: {brobo},
        
        handler: async function (response) {

         await  PayForDamage(response.razorpay_payment_id);
         setAmount('');
        },
        prefill: {
          name: "Piyush Garg",
          email: "youremail@example.com",
          contact: "9999999999",
        },
        notes: {
          address: "Razorpay Corporate Office",
        },
        theme: {
          color: "#3399cc",
        },
      };
    
      const rzp1 = new Razorpay(options);
    
      rzp1.on("payment.failed", function (response) {
        console.log(response.error.code);
        alert(response.error.description);
        alert(response.error.source);
        alert(response.error.step);
        alert(response.error.reason);
        alert(response.error.metadata.order_id);
        alert(response.error.metadata.payment_id);
      });
    
      rzp1.open();
  };

  const handleCancel = () => {
    // Add functionality to handle canceling the operation
    setAmount(''); // Clear input field on cancel
  };

  return (
    <div className="damage-pay-container">
      <section>
        <div className="input-group">
            <h3>₹</h3>
        <input
  type="text"
  value={amount}
  onChange={(e) => {
    const input = e.target.value;
    // Allow only numbers (0-9) and prevent entering any other characters
    const onlyNumbers = input.replace(/[^0-9]/g, '');
    setAmount(onlyNumbers);
  }}
  placeholder="Enter amount in Rupees"
  className="amount-input"
  pattern="[0-9]*"
  inputMode="numeric"
/>

          <button type="button" onClick={handleCancel} className="btn btn-close"></button>
        </div>
        <hr />
        <div className='add-btn-div'>
        <button type="button" onClick={handleAdd} className="add-btn"><i class='bx bx-right-arrow-alt' ></i></button>
        </div>
      </section>
    </div>
  );
}
