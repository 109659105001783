import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import "./OrderDetails.css";
import ApiService from "../../Api/ApiController";
import useRazorpay from "react-razorpay";
import brobo from "../../header/brobo.png";
import Swal from "sweetalert2";
export default function OrderDetails() {
  const [orderdetail, setorderdetails] = useState("");
  const [razorpaykey,setrazorpaykey] = useState([])
  const orderId = useParams();
  const option =  useParams();
  const [Razorpay] = useRazorpay();
  const navigate = useNavigate();
  const [categoryIds,setCategoryIds] = useState()
  //  console.log(option.status)
  useEffect(() => {
    ApiService.GetBookingdetail({
      booking_id: orderId,
    })
      .then((response) => {
        if (response.data.status === "success") {
         
          setorderdetails(response.data.data);
          const categoryIds = response.data.data.item_details.map(
            (item) => item.category_id
          );
          // Assuming an array of category IDs is returned
          setCategoryIds(categoryIds);
        
        }
        
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "2-digit",
    };
    const date = new Date(dateString);
    return date.toLocaleDateString("en-GB", options);
  };

  const handledetails = (orderId) => {
    navigate(`/extendorder/${orderId}`);
  };
  useEffect(()=>{
    ApiService.paymentgatewaykey({
      payment_gateway_name: "razor_pay",
    }).then((response)=>{
       if(response.data.status === 'success'){
  
        setrazorpaykey(response.data.data);
       
       }else{
        console.error(response.data.data);
       }
    }).catch((err)=>{
   
    })
  },[])
  const CancelOrder = (orderid) => {
    Swal.fire({
        title: "Are you sure?",
        text: "Do you want to Cancel this Order?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        cancelButtonText: "No",
        confirmButtonText: "Yes",
    }).then(async (result) => {
        if (result.isConfirmed) {
            try {
                const response = await ApiService.cancelOrder({
                    booking_id: orderid,
                });
                if (response.data.status === "success") {
                    Swal.fire({
                        icon: "success",
                        title: 'Cancel',
                        text: response.data.message
                    });
                    navigate('/mybookings')
                } else {
                    Swal.fire({
                        title: "Error!",
                        text: response.data.message,
                        icon: "error",
                    });
                }
            } catch (error) {
                console.error(error);
                Swal.fire({
                    title: "Error!",
                    text: "An error occurred while canceling the order. Please try again later.",
                    icon: "error",
                });
            }
        }
    });
};


  const PayAmount = ()=>{
     const dueamount = parseFloat(orderdetail.pending_amount).toFixed(2)
    
    const options = {
      key: razorpaykey.key,
      amount:  (parseFloat(dueamount) * 100).toFixed(0) ,
      currency: "INR",
      name: "Brobo",
      description: "Test Transaction",
      image: { brobo },

      handler: async function (response) {
        PayforDueAmount(response.razorpay_payment_id)
      },
      prefill: {
        name: "Piyush Garg",
        email: "youremail@example.com",
        contact: "9999999999",
      },
      notes: {
        address: "Razorpay Corporate Office",
      },
      theme: {
        color: "#1AA79E",
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      console.log(response.error.code);
      alert(response.error.description);
      alert(response.error.source);
      alert(response.error.step);
      alert(response.error.reason);
      alert(response.error.metadata.order_id);
      alert(response.error.metadata.payment_id);
    });

    rzp1.open();

  }

  const PayforDueAmount =(transactionId)=>{
     ApiService.PayForDueAmount({
      booking_id:orderId,
      due_amount_transaction_id:transactionId,
      due_amount:orderdetail.pending_amount
     }).then((response)=>{
        if(response.data.status === 'success'){
          Swal.fire({
            icon: 'success',
            text: response.data.message
          })
        }
     })
  }

  return (
    <div>
      <div>
        <section className="order-details">
          <div className="order-details-heading">
            <h4>Order Details</h4>
            {option.status === 'cancelled' || option.status === 'completed' ? ' ':

              <button
              onClick={() => CancelOrder(orderdetail.order_id)}
              className="order-details-head-btn"
              >
              Cancel Order
            </button>
            }
          </div>

          {/* ================================================= */}

          <section className="order-detail-main">
            <div>
              <div className="booking-list">
                <div className="ongoing-container">
                  <div className="ongoing-description">
                    <div className="ongoing-description1">Description:</div>
                    <div
                      className="ongoing-description2"
                      dangerouslySetInnerHTML={{
                        __html: orderdetail.description,
                      }}
                    ></div>
                  </div>
                  <div className="ongoing-order">
                    <div className="ongoing-order1">Order ID:</div>
                    <div className="ongoing-order2">{orderdetail.order_id}</div>
                  </div>
                  <div className="ongoing-arriving">
                    <div className="ongoing-arriving1">Arriving</div>
                    <div className="ongoing-arriving2">
                      {orderdetail.arriving_date}
                    </div>
                  </div>
                  <div className="ongoing-total">
                    <div className="ongoing-total1">Total Price</div>
                    <div className="ongoing-total2">
                      {orderdetail.final_item_price + parseInt(orderdetail.delivery_charge)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* ================================================= */}
            <section>
              <div className="booking-list">
                <div className="ongoing-container">
                  <div className="ongoing-shippingaddress">
                    <div className="ongoing-shippingaddress1">
                      Shipping Address:
                    </div>
                    <div
                      className="ongoing-shippingaddress2"
                      dangerouslySetInnerHTML={{
                        __html: orderdetail.shipping_address,
                      }}
                    ></div>
                  </div>
                  <div className="ongoing-estimateddelivery">
                    <div className="ongoing-estimateddelivery1">
                      Estimated Delivery:
                    </div>
                    <div className="ongoing-estimateddelivery2">
                      {formatDate(orderdetail.start_date)}
                    </div>
                  </div>
                  <div className="ongoing-enddate">
                    <div className="ongoing-enddate1">End Date:</div>
                    <div className="ongoing-enddate2">
                      {formatDate(orderdetail.end_date)}
                    </div>
                  </div>
                  <div className="ongoing-timeslot">
                    <div className="ongoing-timeslot1">Time Slot</div>
                    <div className="ongoing-timeslot2">
                      {orderdetail.time_duration}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ================================================= */}
            <section>
              <div className="booking-list">
                <div className="ongoing-container">
                  <div className="ongoing-items">
                    <div className="ongoing-items1">Items:</div>
                    <div className="ongoing-items2">
                      {parseFloat(orderdetail.final_item_price)}
                    </div>
                  </div>
                  <div className="ongoing-order">
                    <div className="ongoing-order1">Delivery Charge:</div>
                    <div className="ongoing-order2">
                      {orderdetail.delivery_charge}
                    </div>
                  </div>
                  {orderdetail.gst_number !== "N/A" && (

<div className="ongoing-order">
<div className="ongoing-order1">GST Number:</div>
<div className="ongoing-order2">
  {orderdetail.gst_number
}
</div>
</div>
)}
                  {orderdetail.gst_amount > 0 ?
<div className="ongoing-order">
                    <div className="ongoing-order1">GST Amount:</div>
                    <div className="ongoing-order2">
                      {orderdetail.gst_amount}
                    </div>
                  </div>
                 :"" }
                  {orderdetail.coupon_discount > 0 ?
<div className="ongoing-order">
                    <div className="ongoing-order1">Coupon Discount:</div>
                    <div className="ongoing-order2">
                      {orderdetail.coupon_discount}
                    </div>
                  </div>
                 :"" }
                  {orderdetail.referral_discount > 0 ?

<div className="ongoing-order">
                    <div className="ongoing-order1">Referral Discount:</div>
                    <div className="ongoing-order2">
                      {orderdetail.referral_discount}
                    </div>
                  </div>
                  :''}
                  <div className="ongoing-arriving">
                    <div className="ongoing-arriving1">Remaining Amount:</div>
                    <div className="ongoing-arriving2">
                      {orderdetail.pending_amount}
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* ================================================= */}
          </section>
          <section>
            <div className="order-details-extend">
          
              
            {/* {option.status === 'cancelled' ? ' ':
              <button
                onClick={() => handledetails(orderdetail.order_id)}
                className="extend-order-btn"
              >
                Extent Order
              </button>} */}
       {(option.status !== 'cancelled' && option.status !== 'completed')  && orderdetail.item_details &&  (categoryIds.includes(1) ||
              (categoryIds.includes(1) && categoryIds.includes(2))) && (
  <button
    onClick={() => handledetails(orderdetail.order_id)}
    className="extend-order-btn"
  >
    Extend Order
  </button>
)}


              {/* {orderdetail.pending_amount ?

                <button
                onClick={PayAmount}
                className="extend-order-btn"
                >
                Pay For Due Amount
              </button> :
              ''
              } */}
              {orderdetail.pending_amount > 0 && (option.status !== 'cancelled' && option.status !== 'completed') && (
                 <button
                 onClick={PayAmount}
                 className="extend-order-btn"
                 >
                 Pay For Due Amount
               </button>
              )

              }
            </div>
          </section>
        </section>
      </div>
    </div>
  );
}
