import React, { useEffect, useState } from 'react'
import ApiService from '../../Api/ApiController';
import Loader from '../../loader/Loader';

export default function ShippingPolicy() {
const [shippingpolicy,setshippingpolicy] = useState([]);
const apiKey = 'shipping_policy';

    useEffect(() => {
        ApiService.getSettingData(apiKey)
          .then((response) => {
            console.log("Setting data:", response.data.data);
            setshippingpolicy(response.data.data);
          })
          .catch((error) => {
            console.error("Error fetching setting data:", error);
          });
      }, []);
      if (shippingpolicy.length === 0) {
        return <div style={{backgroundColor:"white",width:'100vw',height:'100vh'}}><Loader/></div>;
    }
  return (
    <div>
      <div>
        <section>
          <div className="terms-condition">
            <div className="terms-condition-heading">
              <h1>Shipping Policy</h1>
            </div>
            <div className="terms-condition-paragraph">
              <p dangerouslySetInnerHTML={{ __html: shippingpolicy.value }} />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
