import React, { useEffect, useState } from 'react'
import ApiService from '../../Api/ApiController';
import Loader from '../../loader/Loader';

export default function PrivacyPolicy() {
const [privacypolicy,setprivacypolicy]= useState([])
    const apiKey = 'privacy_policy';

    useEffect(()=>{

      ApiService.getSettingData(apiKey).then(response =>{
          
          setprivacypolicy(response.data.data)
      }).catch(error =>{
          console.error(error);
      })
    },[]);
    if (privacypolicy.length === 0) { // Check if replacementpolicy is null
      return (
        <div style={{ backgroundColor: "white", width: '100vw', height: '100vh' }}>
          <Loader />
        </div>
      );
    }
  return (
    <div>
      <div>
      <section>
          <div className="terms-condition">
            <div className="terms-condition-heading">
              <h1>Privacy Policy</h1>
            </div>
            <div className="terms-condition-paragraph">
              <p dangerouslySetInnerHTML={{ __html: privacypolicy.value }} />
            </div>
          </div>
        </section>
      </div>
    </div>
  )
}
