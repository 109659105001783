import React, { useEffect, useState } from "react";
import "./ProceedToCheckOut.css"; // Import your CSS file for styling
import img from "../homepage/flower1.jpeg";
import { useTimeSlot } from "./TimeSlotContext";
import ApiService from "../../Api/ApiController";
import useRazorpay from "react-razorpay";
import brobo from "../../header/brobo.png";
import Swal from "sweetalert2";
import Noaddress from './noaddress.jpeg'
import { NavLink, useNavigate } from "react-router-dom";
export default function ProceedToCheckout() {
  const [Razorpay] = useRazorpay();
  const { selectedTimeSlot, sdate, edate ,pinlocation} = useTimeSlot();
  const [days, setDays] = useState("");
  const [couponDiscount, setCouponDiscount] = useState();
  const [discountedamount, setdiscountedamount] = useState(0);
   const [couponGstamount,setCouponGstamount] =  useState();
   const [coupondetails, setCoupondetails] = useState("");
   const [cartitem, setCartItems] = useState([]);
   const [addresses, setAddresses] = useState([]);
   const [ordersetting, setOrderSetting] = useState([]);
   const [couponcode, setcouponcode] = useState([]);
   const [selectedCouponIndex, setSelectedCouponIndex] = useState(null);
   const [selectedCouponCode, setSelectedCouponCode] = useState("");
   const [CouponId,setCouponId] =  useState();
   const [razorpaykey, setrazorpaykey] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState();
  const [refer,setrefer] = useState([])
  const [Profile,setprofile] =  useState([]);
  const [gstnumber,setgstnumber] =  useState()
  const navigate =  useNavigate();
 
  useEffect(()=>{

    if(!selectedTimeSlot){
      navigate('/cart')
    }
  },[selectedTimeSlot,navigate])
  const handleSelect = (address) => {
    setSelectedAddress(address); 
  
  };
  useEffect(() => {
    // Calculate the difference in days between sdate and edate
    const startDate = new Date(sdate);
    const endDate = new Date(edate);
    const differenceInTime = endDate.getTime() - startDate.getTime();
    const differenceInDays = differenceInTime / (1000 * 3600 * 24);
    setDays(differenceInDays);
  }, [sdate, edate]);
  const [hasLift, setHasLift] = useState(null);

  const handleYesClick = () => {
    setHasLift(true);
  };

  const handleNoClick = () => {
    setHasLift(false);
  };
  useEffect(()=>{
        ApiService.getprofiledetails().then((response)=>{
          if(response.data.status === 'success'){
            setprofile(response.data.data)
          }else{
            
          }
        })
  },[])
  useEffect(()=>{
       ApiService.Refferaldiscount().then((response)=>{
                 if(response.data.status === 'success'){
                   
                    setrefer(response.data.data)
                   
                 }else{
                  
                  setrefer([])
                  
                 }
       }).catch((error)=>{
       
       })
  },[])

  const Address = async () => {
    try {
      ApiService.getaddressdetails()
        .then((response) => {
       
          if (response.data.status === "success") {
           
            setAddresses(response.data.data);
          
          }else{
            
          }
        })
        .catch((error) => {
        
        });
    } catch (error) {

    }
  };

 
  const fetchCartData = () => {
    ApiService.getCartDetails()
      .then((response) => {
        if (response.data.status === "success") {
         
          setCartItems(response.data.data);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };


  const OrderSetting = async () => {
    await ApiService.getOrderSetting()
      .then((response) => {
        if (response.data.status === "success") {
         
          setOrderSetting(response.data.data);
        }
      })
      .catch((err) => {
      
      });
  };
  // const totalOrderAmount = cartitem.reduce((acc, item) => acc + item.item_price, 0);
  const totalOrderAmount = cartitem.reduce((acc, item) => {
    if (item.category_id === 1) {
      // For products with category ID 1, multiply the price by the total days
      return acc + item.item_price * days ;
    } else {
      // For other products, just sum the price as usual
      return acc + item.item_price;
    }
  }, 0);

  const deliveryChargeSlabs = Object.values(
    ordersetting.delivery_charge_slabs || {}
  );
  
  let discountedTotalOrderAmount = totalOrderAmount;
  
  // Adjust the total order amount if a coupon is applied
  if (coupondetails) {
    discountedTotalOrderAmount -= discountedamount;
  }
  
  // Calculate delivery charge slab based on the total order amount
  const deliveryChargeSlab = deliveryChargeSlabs.find(
    (slab) =>
      (!slab.max_amount || discountedTotalOrderAmount <= parseInt(slab.max_amount)) &&
      discountedTotalOrderAmount >= parseInt(slab.min_amount)
  );
  
  // Calculate the delivery charge based on the applicable slab
  const deliveryCharge = deliveryChargeSlab
    ? deliveryChargeSlab.delivery_charge
    : 0;
  
  const itemsWithCategoryId1 = cartitem.filter(
    (item) => item.category_id === 1
  );

  // Calculate total price of items with category_id equal to 1
  const totalPriceCategoryId1 = itemsWithCategoryId1.reduce(
    (acc, item) => acc + item.item_price * days,
    0
  );
  // const referralDiscount = (totalOrderAmount * refer.referred_discount) / 100;
  // const referralDiscount = refer ? (totalOrderAmount * refer.referred_discount) / 100 : 0;
  let referralDiscount = 0;

if (refer && refer.referred_discount) {
  // Check if referral data exists and calculate referral discount accordingly
  referralDiscount = (totalOrderAmount * refer.referred_discount) / 100;
}

  const subgrandTotal = totalOrderAmount - referralDiscount;
  
  // Calculate GST for items with category_id equal to 1
  const gstPercent = parseFloat(ordersetting.gst_percent || 0);
  let gstAmount;
  
  if (refer && refer.referred_discount) {
    let amountofcat1withrefer =(totalPriceCategoryId1 * refer.referred_discount)/100
    gstAmount = ((totalPriceCategoryId1 - amountofcat1withrefer) * gstPercent) / 100; // Corrected division
  } else {
    gstAmount = (totalPriceCategoryId1 * gstPercent) / 100;

  }
  let grandTotal
  if(refer && refer.referred_discount){
    grandTotal = parseInt(deliveryCharge) + gstAmount + subgrandTotal;
  }else{
    grandTotal = parseInt(deliveryCharge) + gstAmount + totalOrderAmount;
  }
  
  const AllCouponcodes = async () => {
    await ApiService.getAllCoupons()
      .then((response) => {
        setcouponcode(response.data.data);
      })
      .catch((error) => {
        console.error("Error fetching coupons:", error);
        // Handle the error
      });
  };
  useEffect(() => {
    Address();
    fetchCartData();
    OrderSetting();
    AllCouponcodes();
  }, []);




  const [showBottomSheet, setShowBottomSheet] = useState(false);

  const toggleBottomSheet = () => {
    setShowBottomSheet(!showBottomSheet);
  };
  const handleOverlayClick = () => {
    setShowBottomSheet(false);
  };

  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");
  const [totalOrderAmountAfterDiscount, setTotalOrderAmountAfterDiscount] = useState(0);
  // (selectedOption);
  const toggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionChange = (option) => {
    setSelectedOption(option);
    // You can perform any additional actions here based on the selected option
  };

  const handleCouponSelection = (index, code,couponId) => {
    if (selectedCouponIndex === index) {
      setSelectedCouponIndex(null);
      setSelectedCouponCode(null);
    } else {
      setSelectedCouponIndex(index);
      setSelectedCouponCode(code);
      setShowBottomSheet(false);
      setCouponId(couponId)
     
    }
    // You can add any other logic you need here, such as applying the selected coupon
  };

  useEffect(() => {
    
  }, [CouponId]);

  const CouponDetail = async (e, couponCode) => {
    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    console.log('Coupon Code:', couponCode);
  console.log('Total Order Amount:', totalOrderAmount);
  e.preventDefault();
  try {
      const response = await ApiService.coupondetails(couponCode);
      if (response.data.status === "success") {
        
        const couponData = response.data.data;
        const currentDate = new Date();
        // const startDate = new Date(couponData.start_date);
        // const endDate = new Date(couponData.expire_date);
        const minimumPurchase = couponData.min_purchase
        console.log(currentDate,'current')
        // console.log(startDate,'start')
        // console.log(endDate,'end')
        console.log('Coupon Data:', couponData)
        // Check if the coupon is not expired
        if(minimumPurchase < totalOrderAmount){
          console.log("minimum")
          if (formatDate(currentDate) >= couponData.start_date && formatDate(currentDate) <= couponData.expire_date  ) {
            console.log("date")
            setCoupondetails(couponData);
            console.log('Coupon Details:', coupondetails);
            CouponDiscount(); 
            Swal.fire({
              icon: 'success',
              text: 'Coupon is Applied'
            });
          } else 
          if( response.data.status === "error") {
            Swal.fire({
              icon: 'error',
              text: 'Coupon is Expired'
            });
          }
        }else{
          Swal.fire({
            icon:'error',
            text: `This is only Applicable for minimun Purchase of ${couponData.min_purchase}`
          })
        }
      }
    } catch (error) {
      Swal.fire({
        icon: 'error',
        text: 'Coupon is Expired'
      });
    }
  };
  
  
  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  const CouponDiscount = () => {
    console.log("ccoupon discount")
    const currentDate = new Date();
    const startDate = new Date(coupondetails.start_date);
    const endDate = new Date(coupondetails.expire_date);
  
       if(coupondetails.min_purchase < totalOrderAmount){

        //  if (currentDate >= startDate && currentDate <= endDate) {
           let discountPrice = 0;
           
           if (coupondetails.discount_type === "percent") {
             discountPrice = (totalOrderAmount * coupondetails.discount) / 100;
             console.log(discountPrice,"rtrtyery")
        setdiscountedamount(discountPrice);
      } else {
        discountPrice = Math.min(totalOrderAmount, coupondetails.discount);
        setdiscountedamount(discountPrice);
      }
      
      const rentedItems = cartitem.filter((item) => item.category_id === 1);
      const buyOnceItems = cartitem.filter((item) => item.category_id === 2);
      
      let discountForRented = 0;
      let discountForBuyOnce = 0;
      
      // Calculate discount based on the presence of items in each category
      if (rentedItems.length === 0 || buyOnceItems.length === 0) {
        // If only one type of item is present, apply the full discount to that type
        discountForRented = discountPrice;
        discountForBuyOnce = discountPrice;
      } else {
        // If both types of items are present, apply half of the discount to each type
        discountForRented = discountPrice/2 ;
        discountForBuyOnce = discountPrice /2;
      }
  
      // Calculate the total price for rented items after discount
      const totalPriceForRented = rentedItems.reduce((acc, item) => {
        return (
          acc +
          ((item.item_price * days) - discountForRented / rentedItems.length)
        );
      }, 0);
  
      // Calculate the total price for buy once items after discount
      const totalPriceForBuyOnce = buyOnceItems.reduce((acc, item) => {
        return (
          acc +
          (item.item_price - discountForBuyOnce / buyOnceItems.length)
        );
      }, 0);
  
      // Calculate GST for rented items after applying coupon discount
      const gstPercent = parseFloat(ordersetting.gst_percent || 0);
      const gstAmount = (totalPriceForRented * gstPercent) / 100;
      setCouponGstamount(gstAmount);
      
      // Set the discounted total order amount including GST
      setCouponDiscount(totalPriceForBuyOnce + (totalPriceForRented + gstAmount));
    // } 
  }else{
    Swal.fire({
      icon:'error',
      text: `This is only Applicable for minimun Purchase of ${coupondetails.min_purchase}`
    })
  }
  };
  
  useEffect(() => {
    if (coupondetails) {
      console.log("useeffect coupon")
      CouponDiscount(); // Call CouponDiscount function here
    }
  }, [coupondetails]);
  
  // Calculate GST for items with category_id equal to 1


  // ======================================================================================================================

  const PlaceOrder = async (paymentId) => {
    let discountedTotal;
    if (coupondetails) {
      discountedTotal = parseFloat((couponDiscount + parseInt(deliveryCharge)).toFixed(2))
    
   }else if(refer){
    discountedTotal = (parseInt(deliveryCharge) + gstAmount + (totalOrderAmount - referralDiscount))
    
  } else {
      discountedTotal =  Math.round((parseInt(deliveryCharge) + gstAmount + totalOrderAmount) )
   } 
//    if (selectedOption){
//     let advancepayment = (discountedTotal * selectedOption) / 100;
//     discountedTotal = advancepayment.toFixed(2)
   
//  }

    await ApiService.BookItem({
      start_date: sdate,
      end_date: edate,
      time_duration: selectedTimeSlot.time_slot,
      cart_items: cartitem,
      address_id: selectedAddress.address_id,
      transaction_id: paymentId,
      is_building_have_lift: hasLift ? "Yes" : "No",
      final_item_price:discountedTotal,
      coupon_id:CouponId,
      order_installment_percent:selectedOption,
      delivery_charge:deliveryCharge,
      referral_discount : referralDiscount ,
      coupon_discount:discountedamount,
      pin_location:pinlocation,
      gst_amount: gstAmount,
      gst_number:gstnumber
    })
      .then((response) => {
        if (response.data.status === "success") {
          Swal.fire({
            icon: "success",
            title: "Order",
            text: response.data.message,
          });
          navigate('/')
          window.location.reload()
        } else {
        
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  useEffect(()=>{
    ApiService.paymentgatewaykey({
      payment_gateway_name: "razor_pay",
    }).then((response)=>{
       if(response.data.status === 'success'){
  
        setrazorpaykey(response.data.data);
       
       }else{
        console.error(response.data.data);
       }
    }).catch((err)=>{
   
    })
  },[])
 
  const PayNow = () => {
 
    if (!selectedAddress) {
      Swal.fire({
        icon: 'warning',
        title: 'Please Select Address before proceeding to Checkout'
      });
    } else {
      if(hasLift === null){
                Swal.fire({
                  icon:'warning',
                  text:"please choose whether your building has lift(elevator) or not!"
                })
      }else{

        // Show confirmation dialogue
        Swal.fire({
        icon: 'info',
        title: 'Additional Terms',
        html: 'Please note: Additional fees apply for any damages or loss of rented items. By proceeding, you confirm your understanding of these terms.',
        showCancelButton: false,
        confirmButtonText: 'I Agree',
        cancelButtonText: 'Cancel',
        showLoaderOnConfirm: true,
        preConfirm: async () => {
          // Calculate discounted total
          let discountedTotal;
          if (coupondetails) {
            discountedTotal = parseFloat((couponDiscount + parseInt(deliveryCharge)).toFixed(2)) ;
          } else if (refer) {
            discountedTotal = ((parseInt(deliveryCharge) + gstAmount + (totalOrderAmount - referralDiscount)));
            console.log(discountedTotal)
          } else {
            discountedTotal = Math.round((parseInt(deliveryCharge) + gstAmount + totalOrderAmount) );
            
          }
          if (selectedOption) {
           
            
            let advancepayment =  ((discountedTotal * selectedOption)/ 100)
              discountedTotal = advancepayment.toFixed(2)
              console.log(discountedTotal)
          }
  
          const options = {
            key: razorpaykey.key,
            amount: discountedTotal * 100,
            currency: 'INR',
            name: 'Brobo',
            description: 'Test Transaction',
            image: { brobo },
  
            handler: async function (response) {
              await PlaceOrder(response.razorpay_payment_id);
            },
            prefill: {
              name: 'Piyush Garg',
              email: 'youremail@example.com',
              contact: '9999999999',
            },
            notes: {
              address: 'Razorpay Corporate Office',
            },
            theme: {
              color: '#3399cc',
            },
          };
  
          const rzp1 = new Razorpay(options);
          
          rzp1.on('payment.failed', function (response) {
            
            // alert(response.error.description);
            // alert(response.error.source);
            // alert(response.error.step);
            // alert(response.error.reason);
            // alert(response.error.metadata.order_id);
            // alert(response.error.metadata.payment_id);
          });
  
          rzp1.open();
        }
      });
    }
    }
  };
  
  // ======================================================================================================================
  const Edit = (addressID)=>{
    navigate(`/editaddress/${addressID}`)
}
  return (
    <div>
      <h2>Proceed To Checkout</h2>
      <div className="proceed-to-checkout-container">
        <h1>Select address</h1>
        <div className="address-selection-container">
        {addresses.length > 0 ? <div className="address-contain">
          {addresses.map((address,index) => (
          // {
            <div
            key={index}
            className={`address-card ${
              selectedAddress && selectedAddress.address_id === address.address_id
                ? "selected"
                : ""
            }`}
            onClick={() => handleSelect(address)}
          >
        
              {/* <h3>Saved Address</h3> */}
              {/* <p><strong>Address Type:</strong> {address.address_type}</p>
              <p><strong>House Name:</strong> {address.house_name}</p>
              <p><strong>Floor Number:</strong> {address.floor_number}</p>
              <p><strong>Landmark:</strong> {address.landmark}</p>
              <p><strong>Area Name:</strong> {address.area_name}</p>
              <p><strong>Zip Code:</strong> {address.zip_code}</p> */}
               <div className="address-details">
               <button onClick={()=>Edit(address.address_id)}>
                Edit 
                <i class='bx bx-edit-alt'></i>
               </button>
        <p><strong>Address Type:</strong> {address.address_type}</p>
        <p><strong>House Name:</strong> {address.house_name}</p>
        <p><strong>Floor Number:</strong> {address.floor_number}</p>
        <p><strong>Landmark:</strong> {address.landmark}</p>
        <p><strong>Area Name:</strong> {address.area_name}</p>
        <p><strong>Zip Code:</strong> {address.zip_code}</p>
      </div>
            </div>
          // }
           ))} 
        </div> :
        <div>
          <div className="noaddress">
            <img src={Noaddress} alt="" />
            <NavLink to="/deliveryaddress">Add Address</NavLink>
          </div>
        </div>

        }
        </div>
        <section>
          {cartitem.length === 0 ? (
            <div>Go Add Product in Cart</div>
          ) : (
            <div className="product-item1">
              {cartitem.map((cart, index) => (
                <div className="product-item" key={index}>
                  <div className="product-item-img">
                    <img src={cart.item_image} alt="" />
                  </div>
                  <div className="product-item-name">
                    <h6>{cart.item_name}</h6>
                  </div>
                  <div className="product-item-section">
                    <div className="product-item-rs">Rs.{cart.item_price}</div>
                    <div className="product-item-qty">
                    {cart.category_id === 1 ? `For ${days} Days` : `Quantity :${cart.quantity}`}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </section>
        <section className="section-container">
          <p>
            Does your building have lift(elevator)
            <div className="section-container1">
              <span
                onClick={handleYesClick}
                className={hasLift === true ? "selected" : ""}
              >
                Yes
              </span>
              <span
                onClick={handleNoClick}
                className={hasLift === false ? "selected" : ""}
              >
                No
              </span>
            </div>
          </p>
          {hasLift === false && (
            <div className="labour-charges">
               It help us plan the delivery better and avoid extra charges in delivery address 
            </div>
          )}
        </section>

        <section className="shipping-to-section">
          <h4>Shipping to Mr.{Profile.name}</h4>
          <div className="shipping-section">
         {
          selectedAddress ?    <div className="shipping-section-address">
          Shipping address: <strong>{selectedAddress.address_type}</strong>  {selectedAddress.house_name},{selectedAddress.area_name},
          {selectedAddress.floor_number},{selectedAddress.zip_code}
          </div> : <div className="shipping-section-address">
          Shipping address: 
          </div>
         }
            <div className="shipping-section-details">
              <div className="shipping-section-items">
                <div className="shipping-section-items-1">
                  Product item(s) :
                </div>
                
                <div className="shipping-section-items-2">
                  ₹{totalOrderAmount}
                </div>
              </div>
                {discountedamount > 0 ?
              <div className="shipping-section-deliverycharges">
                  <div className="shipping-section-deliverycharges-1">
                  Coupon Discount :
                </div>
                <div className="shipping-section-deliverycharges-2">
                  ₹{discountedamount}
                </div>
              </div>
              :""
                }
                {referralDiscount > 0 ?
                  <div className="shipping-section-gst">
                <div className="shipping-section-gst-1">
                  Referral Discount :
                </div>
                <div className="shipping-section-gst-2">₹{referralDiscount}</div>
              </div>:""
              }
            </div>
          </div>
        </section>


        <section>
          {refer && refer.referred_discount ?  " " :

            <section className="section-container-bottom">
            <div className="best-available-offer" onClick={toggleBottomSheet}>
              View best available offer
              <i class="bx bx-chevron-right" style={{ color: "#1d8679" }}></i>
            </div>
          </section> 
      }

          {showBottomSheet && (
            <>
              <div className="overlay" onClick={handleOverlayClick}></div>
              <div className="bottom-sheet">
                <div>
                  {couponcode.length > 0 ? (
                    <div>
                      {couponcode.map((couponcode, index) => (
                        <div className={`coupon-item`} key={index}>
                          <div
                            className="coupon-details"
                            onClick={() =>
                              handleCouponSelection(index, couponcode.code , couponcode.id)
                            }
                          >
                            <h6>{couponcode.code}</h6>
                            <p>
                              Apply this coupon to get
                              <span>
                                {couponcode.discount_type === "amount" ? (
                                  <span> Rs.{couponcode.discount} OFF</span>
                                ) : (
                                  <span>{couponcode.discount}% OFF</span>
                                )}
                              </span>
                            </p>
                          </div>
                          <input
                            type="radio"
                            id={`coupon-radio-${index}`}
                            className="coupon-radio"
                            checked={selectedCouponIndex === index}
                            onChange={() => {}}
                          />
                          <label  htmlFor={`coupon-radio-${index}`}></label>
                        </div>
                      ))}
                    </div>
                  ) : (
                    <div>You do not have any Coupon</div>
                  )}
                </div>
              </div>
            </>
          )}
        </section>
        {/* =================================== */}
        {refer && refer.referred_discount ?  " " :
          <section>
          <div>
            <form action="">
              <div className="coupon-code-section">
                <input
                  type="text"
                  className="coupon-code-input"
                  value={selectedCouponCode}
                  onChange={(e) => setSelectedCouponCode(e.target.value)}
                  placeholder="Enter Coupon Code"
                />
                <button
                  onClick={(e) => CouponDetail(e, selectedCouponCode)}
                  type="submit"
                >
                  Apply
                </button>
              </div>
            </form>
          </div>
        </section>
}
        {/* =========================================================== */}
        <section>
          {/* <h4>Shipping to Mr.Hasan</h4> */}
          <div className="shipping-section">
          {
          selectedAddress ?    <div className="shipping-section-address">
          Shipping address: <strong>{selectedAddress.address_type}</strong>  {selectedAddress.house_name},{selectedAddress.area_name},
          {selectedAddress.floor_number},{selectedAddress.zip_code}
          </div> : <div className="shipping-section-address">
          Shipping address: 
          </div>
         }
            <div className="shipping-section-details">
              <div className="shipping-section-items">
                <div className="shipping-section-items-1">Items</div>
                {refer && refer.referred_discount ?

                  <div className="shipping-section-items-2">
                  ₹{totalOrderAmount - referralDiscount}
                </div>
                :
                <div className="shipping-section-items-2">
                ₹{totalOrderAmount - discountedamount}
              </div>
                }
              </div>
              <div className="shipping-section-deliverycharges">
                <div className="shipping-section-deliverycharges-1">
                  Delivery Charges
                </div>
                <div className="shipping-section-deliverycharges-2">
                  ₹{deliveryCharge}
                </div>
              </div>
              <div className="shipping-section-gst">
                <div className="shipping-section-gst-1">GST</div>
                <div className="shipping-section-gst-2">
                  {/* ₹{gstAmount} */}
                
                     {coupondetails ?  <div>
                    ₹{couponGstamount}
                  </div> : 
                    <div>₹{gstAmount}</div>
                  }
                  </div>
              </div>
              <div className="shipping-section-totalorder">
                <div className="shipping-section-totalorder-1">Total Order</div>
                <div className="shipping-section-totalorder-2">
                  {/* ₹{parseInt(deliveryCharge) + gstAmount + totalOrderAmount} */}
                  {coupondetails ?  <div>
                    ₹{couponDiscount + parseInt(deliveryCharge)}
                  </div> : 
                    <div>₹{grandTotal}</div>
                  }
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* ====================================== */}
        
        <section>
          <div className="advance-payment-section" onClick={toggleOptions}>
            <div>Advance payment</div>
            <div className="advance-payment-arrow">
              <i
                className="bx bxs-right-arrow-circle"
                style={{ color: "#1AA79E" }}
              ></i>
            </div>
          </div>
          {showOptions && (
            <div className="options">
              {/* Additional options go here */}
              {ordersetting.order_installment_percents.map(
                (installment, index) => (
                  <label key={index}>
                    Advance Payment of {installment}%
                    <input
                      type="radio"
                      value={installment}
                      checked={selectedOption === installment}
                      onChange={() => handleOptionChange(installment)}
                    />
                  </label>
                )
              )}
            </div>
          )}
        </section>
        <section>
          <div className="final-price-section">
            <div>Final Price After Discount</div>
            <div>
          { selectedOption ?  <div>
            {coupondetails ?  <div>
                    ₹{((couponDiscount + parseInt(deliveryCharge)) * selectedOption) / 100}
                  </div> : 
                    <div>₹{(grandTotal * selectedOption) / 100}</div>
                  }
          </div> : <div> {coupondetails ?  <div>
                    ₹{couponDiscount + parseInt(deliveryCharge)}
                  </div> : 
                    <div>₹{grandTotal}</div>
                  }
                  </div>
                  }
            </div>
          </div>
        </section>
        <section>
              <div className="Gst-optional-input">
              <input type="text" value={gstnumber} onChange={(e)=>setgstnumber(e.target.value)} placeholder="GST(Optional)" />
              </div>
             </section>
        {/* ========================================== */}
        <section>
          <div className="paynow-btn">
            <button onClick={PayNow}>Pay Now</button>
          </div>
        </section>
      </div>
    </div>
  );
}
