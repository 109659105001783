// import React, { useEffect, useRef, useState } from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';
// import './secondstep.css'; // Import CSS file for styling
// import ApiService from '../../../Api/ApiController';
// import { useTimeSlot } from '../../addtocart/TimeSlotContext';
// import Swal from 'sweetalert2';

// export default function OTPPage() {
//   const [otp, setOTP] = useState(['', '', '', '']); // An array to hold OTP digits
//   const navigate = useNavigate();
//   const location = useLocation();
//   const customerId = location.state?.customerId || '';
//   const [countDown,setcountDown] =  useState(30);
//   const [Isresending,setIsResending] =  useState(false)
//   // const [customerId,setcustomerId] = useState('');
//   console.log(customerId);
//   const inputRefs = useRef([]);
//   // const {userId} =  useTimeSlot();
//   // useEffect(() => {
//   //   if (userId) {
//   //     localStorage.setItem('otp', userId);
//   //   }
//   // }, [userId]);
//   // Function to handle OTP input
//   useEffect(()=>{

//   },[])
//   const handleOTPInput = (index, value) => {
//     const newOTP = [...otp];
//     newOTP[index] = value;

//     // Update OTP array
//     setOTP(newOTP);

//     // Clear the input field if the entered value is empty
//     if (value === '') {
//       // Move focus to the previous input field
//       if (index > 0) {
//         inputRefs.current[index - 1].focus();
//       }
//     } else {
//       // Move focus to the next input field
//       if (index < otp.length - 1) {
//         inputRefs.current[index + 1].focus();
//       }
//     }
//   };

//   // Function to handle input field reference
//   const setInputRef = (element, index) => {
//     inputRefs.current[index] = element;
//   };



//   // Function to handle OTP submission
//   const handleVerify = async (e) => {
//     e.preventDefault();
//     try {
//       const verification_code = otp.join(''); // Convert array of OTP digits to a string
//       console.log('Verification Code:', verification_code); // Log the verification code for debugging
//       // Call API to verify OTP using the customer ID
//       const response = await ApiService.VerifyOtp({
//         customer_id: customerId, // Use customerId instead of mobileNumber
//         verification_code: verification_code,
//       });
//         if(response.data.status === 'success'){
//           navigate('/resetpassword');
//         }
//     } catch (error) {
//       console.error('API Error:', error); 
//     }
//   };
  

  
//   const handleBack = () => {
   
//     navigate(-1);
//   };

//   const ResendOtp=()=>{
//     ApiService.ResendOTP({
//       customer_id:customerId
//     }).then((response)=>{
//                 if(response.data.status === 'success'){
//                   Swal.fire({
//                     icon: 'success',
                 
//                     text: response.data.message,
//                     toast: true,
//                     position: 'top-end',
//                     showConfirmButton: false,
//                     timer: 2000
//                   });
                  
//                   setIsResending(true)
//                 }else{
//                   Swal.fire({
//                     icon: 'error',
                  
//                     text: response.data.message,
//                     toast: true,
//                     position: 'top-end',
//                     showConfirmButton: false,
//                     timer: 1500
//                   });
//                 }
//     }).catch((err)=>{
//     console.log(err)
//     })
//   }
//   useEffect(() => {
//     let interval;
//     if (Isresending && countDown > 0) {
//       interval = setInterval(() => {
//         setcountDown((prevCount) => prevCount - 1);
//       }, 1000);
//     }
    
//     // Cleanup function
//     return () => clearInterval(interval);
//   }, [Isresending, countDown]);
  
//   // useEffect to handle countdown completion and resetting flags
//   useEffect(() => {
//     if (countDown === 0) {
      
//       setIsResending(false);
//       setcountDown(30); 
//     }
//   }, [countDown]);
  
//   return (
//     <div>
//       <section className="otp-section">
//         <form className="form-otp">
//           <p className="heading-otp">Verify</p>
//           <div className="box">
//             {/* Render four input fields for OTP digits */}
//             {otp.map((digit, index) => (
//               <input
//                 key={index}
//                 className="input-otp"
//                 type="text"
//                 maxLength="1"
//                 value={digit}
//                 onChange={(e) => handleOTPInput(index, e.target.value)}
//                 ref={(input) => setInputRef(input, index)}
//               />
//             ))}
//           </div>
//           {countDown < 30 && (
//   <p>Resend OTP in {countDown}</p>
// )}

// {countDown === 30 && !Isresending && (
//   <p onClick={ResendOtp}>Resend OTP</p>
// )}
//           {/* Buttons for OTP submission and navigating back */}
//           <button className="btn1-otp" onClick={handleVerify}>
//             Submit
//           </button>
//           {/* <button className="btn2-otp" onClick={handleBack}>
//             Back
//           </button> */}
//         </form>
//       </section>
//     </div>
//   );
// }



import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './secondstep.css'; // Import CSS file for styling
import ApiService from '../../../Api/ApiController';
import { useTimeSlot } from '../../addtocart/TimeSlotContext';
import Swal from 'sweetalert2';

export default function OTPPage() {
  const [otp, setOTP] = useState(['', '', '', '']); // An array to hold OTP digits
  const navigate = useNavigate();
  const location = useLocation();
  const customerId = location.state?.customerId || '';
  const [countDown, setCountDown] = useState(30);
  const [isResending, setIsResending] = useState(false);
  const inputRefs = useRef([]);
useEffect(()=>{
  setIsResending(true)
},[])
  useEffect(() => {
    let interval;
    if (isResending && countDown > 0) {
      interval = setInterval(() => {
        setCountDown((prevCount) => prevCount - 1);
      }, 1000);
    }
    
    // Cleanup function
    return () => clearInterval(interval);
  }, [isResending, countDown]);

  useEffect(() => {
    if (countDown === 0) {
      setIsResending(false);
      setCountDown(30); 
    }
  }, [countDown]);

  const handleOTPInput = (index, value) => {
    const newOTP = [...otp];
    newOTP[index] = value;

    // Update OTP array
    setOTP(newOTP);

    // Clear the input field if the entered value is empty
    if (value === '') {
      // Move focus to the previous input field
      if (index > 0) {
        inputRefs.current[index - 1].focus();
      }
    } else {
      // Move focus to the next input field
      if (index < otp.length - 1) {
        inputRefs.current[index + 1].focus();
      }
    }
  };

  const setInputRef = (element, index) => {
    inputRefs.current[index] = element;
  };

  const handleVerify = async (e) => {
    e.preventDefault();
    try {
      const verification_code = otp.join(''); // Convert array of OTP digits to a string
      console.log('Verification Code:', verification_code); // Log the verification code for debugging
      const response = await ApiService.VerifyOtp({
        customer_id: customerId,
        verification_code: verification_code,
      });
      if (response.data.status === 'success') {
        navigate('/resetpassword');
      }
    } catch (error) {
      console.error('API Error:', error); 
    }
  };

  const resendOtp = () => {
    ApiService.ResendOTP({
      customer_id: customerId
    }).then((response) => {
      if (response.data.status === 'success') {
        Swal.fire({
          icon: 'success',
          text: response.data.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2000
        });
        setIsResending(true);
      } else {
        Swal.fire({
          icon: 'error',
          text: response.data.message,
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 1500
        });
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  return (
    <div>
      <section className="otp-section">
        <form className="form-otp">
          <p className="heading-otp">Verify</p>
          <div className="box">
            {/* Render four input fields for OTP digits */}
            {otp.map((digit, index) => (
              <input
                key={index}
                className="input-otp"
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleOTPInput(index, e.target.value)}
                ref={(input) => setInputRef(input, index)}
              />
            ))}
          </div>
          {countDown < 30 && (
            <p>Resend OTP in {countDown}</p>
          )}
          {countDown === 30 && !isResending && (
            <p className='resend-otp-1' onClick={resendOtp}>Resend OTP</p>
          )}
          {/* Buttons for OTP submission and navigating back */}
          <button className="btn1-otp" onClick={handleVerify}>
            Submit
          </button>
          {/* <button className="btn2-otp" onClick={handleBack}>
            Back
          </button> */}
        </form>
      </section>
    </div>
  );
}
